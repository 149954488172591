.pool_detail_assets_body_layer_1 {
  display: flex;
  justify-content: space-between;
  gap: 50px;
}

.pool_detail_assets_body_layer_1_cont1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  background: #fff;
  padding: 1.5em;
  border: solid 1px #ebebeb;
  border-radius: 10px;
}

.asset_list_mobile_view {
  display: none;
}

.asset_list_desktop_view2 {
  overflow-x: scroll;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background: none !important;
  transition: width 0.6s ease;
}

.pool_detail_assets_body_layer_1_cont2 {
  width: 100%;
}

.pool_detail_assets_body_layer_1_cont1_heading {
  display: flex;
  justify-content: space-between;
}

.pool_detail_assets_body_layer_1_cont1_heading_1 {
  font-weight: 600;
}

.pool_detail_assets_body_layer_1_cont1_sub_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pool_detail_assets_body_layer_1_cont1_sub_heading_1 {
  font-size: 14px;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.pool_detail_assets_body_layer_1_cont1_sub_heading_1b {
  font-size: 12px;
  color: #8f8f8f;
}

.recharts-wrapper {
  width: 100% !important;
}

svg.recharts-surface {
  width: 100% !important;
  padding: 0.5em;
}

.asset_list_body_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  color: black;
  background: #fff;
  padding: 1em;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border: solid 1px #ededed;
  position: sticky;
  top: 55px;
  background: white;
  font-weight: 600;
  z-index: 1;
  gap: 20px;
  overflow: scroll;
}

.status_btn_ongoing {
  padding: 5px 20px;
  border-radius: 8px;
  border: solid 1px #22ad62;
  background: #edfff2;
  color: #22ad62;
}

.status_btn_closed {
  padding: 5px 20px;
  border-radius: 8px;
  border: solid 1px #d99129;
  background: #fff8ed;
  color: #d99129;
}

.asset_list_body_head_tab1 {
  display: flex;
  width: 160%;
  justify-content: flex-start;
  white-space: nowrap;
}

.asset_list_body_head_tab2 {
  display: flex;
  width: 100%;
  justify-content: center;
  white-space: nowrap;
}

.asset_list_body_head_tab3 {
  display: flex;
  width: 100%;
  justify-content: center;
  white-space: nowrap;
}

.asset_list_body_head_tab4 {
  display: flex;
  width: 100%;
  justify-content: center;
  white-space: nowrap;
}

.asset_list_body_head_tab5 {
  display: flex;
  width: 100%;
  justify-content: center;
  white-space: nowrap;
}

.asset_list_body_head_tab6 {
  display: flex;
  width: 100%;
  justify-content: center;
  white-space: nowrap;
}

.asset_list_body_head_tab7 {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  white-space: nowrap;
}

.asset_list_body_body_cont {
  display: flex;
  flex-direction: column;
  gap: 10px;
  animation: fade 0.5s ease-in-out;
  position: relative;
}

@keyframes fade {
  0% {
    /* transform: scale(1); */
    opacity: 0;
  }

  100% {
    /* transform: scale(1.1); */

    opacity: 1;
  }
}

.assets_chart_area2 {
  display: none;
}

.dark .assets_chart_area2 {
  display: block;
}

.dark .assets_chart_area1 {
  display: none;
}

.assets_chart_area1a {
  display: block;
}

.dark .assets_chart_area1a {
  display: none;
}

.asset_list_body_body_cont_1 {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  background: #fff;
  border: solid 1px #f0f0f0;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0px 0px 4px #dfdfdf;
  align-items: center;
  animation: fade 0.5s ease-in-out;
  transition: 0.3s ease-in-out;
  gap: 20px;
  overflow: scroll;
}

.asset_list_body_body_cont_1:hover {
  box-shadow: rgb(0 0 0 / 15%) 0px 0px 10px;
  transform: scaleX(0.99);
  transition: 0.3s ease-in-out;
}

.asset_list_body_body_cont_1a {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  align-items: center;
  /* overflow: hidden; */
  white-space: nowrap;
}

.asset_list_body_body_cont_1b {
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  white-space: nowrap;
}

.asset_list_body_body_cont_1c {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  white-space: nowrap;
}

.asset_list_body_body_cont_1d {
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  white-space: nowrap;
}

.asset_list_body_body_cont_1e {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  white-space: nowrap;
}

.asset_list_body_body_cont_1f {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  white-space: nowrap;
}

.asset_list_body_body_cont_1g {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  white-space: nowrap;
}

.asset_list_div {
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: solid 1px #e9e9e9;
  border-radius: 10px;
  padding: 1em;
}

.asset_list_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.arrow_right_arrow {
  color: #8898a3 !important;
}

.asset_detail_modal_div {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000000;
  width: 100svw;
  height: 100svh;
  display: flex;
  align-items: jus;
  justify-content: center;
  background: #00000099;
  backdrop-filter: blur(5px);
  padding: 4em 1em;
  overflow: scroll;
  animation: fade 0.2s ease-in-out;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.asset_detail_modal_div_conts {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 900px;
  background: #f7f7f7;
  padding: 1em;
  border-radius: 10px;
  box-shadow: 0px 0px 16px #0000003d;
  overflow: scroll;
  animation: scale 0.2s ease-in-out;
}

@keyframes scale {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.asset_status_details_div1_head {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
}

.staus_btn_div {
  font-size: 12px;
  font-weight: 700 !important;
}

.asset_status_details_div1 {
  display: flex;
  flex-direction: column;
  gap: 35px;
  border: solid 1px #d5d5d5;
  padding: 1em;
  border-radius: 6px;
  background: #fff;
}

.asset_detail_heading {
  display: flex;
  justify-content: space-between;
  margin-top: -2em;
  position: sticky;
  top: 0;
  background: #fff;
  border: solid 1px #e1e1e1;
  border-radius: 6px;
  padding: 1em;
  z-index: 1;
  width: 100%;
}

.asset_status_details_div1_body {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}

.asset_status_details_div1_body1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.asset_status_details_div1_body1_cont1 {
  font-size: 14px;
  font-weight: 300;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.asset_details_spec_div {
  display: flex;
  flex-direction: column;
  grid-gap: 35px;
  grid-gap: 6px;
  gap: 20px;
}

.asset_spec_body {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.wrapper {
  width: 600px;
  margin: 0 auto;
}

.accordion-wrapper + * {
  margin-top: 0.5em;
}

.accordion-item {
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;
  background-color: #fff;
  border: 1px solid #c2c2c221 !important;
}

.accordion-item.collapsed {
  max-height: 0;
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}

.accordion-item:last-of-type {
  border-bottom-right-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}

.accordion-title {
  font-weight: 600;
  cursor: pointer;
  font-size: 16px;
  /* color: #858585; */
  padding: 0.5em 1.5em;
  border: solid 1px #f1f1f1;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: none; */
  background: #fbfbfb;
  box-shadow: 0px 4px 4px #ededed;
}

.accordion-title::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid currentColor;
}

.accordion-title:hover,
.accordion-title.open {
  color: #239e54;
}

.accordion-title.open {
  color: #239e54;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 0;
}

.accordion-title.open::after {
  content: "";
  border-top: 0;
  border-bottom: 5px solid;
}

.accordion-content {
  padding: 1em;
}

.transactionData_body {
  display: flex;
  justify-content: space-between;
  padding: 2em;
  background: #fff;
  border: solid 1px #e7e7e7;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 300;
}

.transactionData_body_2 {
  flex-direction: row-reverse;
}

.close_asset_detail_modal {
  display: flex;
  align-items: center;
  gap: 2px;
  color: #fff;
  position: absolute;
  right: 21px;
  top: 13px;
  cursor: pointer;
  background: #ffffff38;
  padding: 5px 10px;
  border-radius: 6px;
}

.asset_detail_modal_div_conts::-webkit-scrollbar {
  display: none;
}

.transactionData_body1 {
  display: flex;
  align-items: center;
  gap: 5px;
}

.copy_all_tx_hash_icon {
  cursor: pointer;
  font-size: 22px !important;
}

.img_modal {
  position: absolute;
  background: #00000094;
  width: 100svw;
  top: 0;
  height: 100svh;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  /* backdrop-filter: blur(304px); */
  animation: fade 0.2s ease-in-out;
}

.img_modal_div {
  max-width: 320px;
  width: 100%;
  animation: scale 0.2s ease-in-out;
}

@keyframes fade {
  0% {
    /* transform: scale(1); */
    opacity: 0;
  }

  100% {
    /* transform: scale(1.1); */

    opacity: 1;
  }
}

@keyframes scale {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@media screen and (max-width: 1400px) {
  .pool_detail_assets_body_layer_1 {
    gap: 10px;
  }

  .pool_detail_assets_body_layer_1_cont1 {
    padding: 1em;
  }

  .filter_table_area_2 {
    width: 50% !important;
  }
}

@media screen and (max-width: 1142px) {
  .asset_list_body_body_cont_1a {
    text-align: inherit;
  }
}

@media screen and (max-width: 900px) {
  .asset_list_mobile_view {
    display: block;
  }

  .asset_list_desktop_view {
    display: none;
  }
}

/* @media screen and (max-width: 991px) {
  .asset_other2 {
    padding-top: 4em !important;
  }
} */

@media screen and (max-width: 767px) {
  .pool_detail_assets_body_layer_1 {
    grid-gap: 10px;
    gap: 10px;
    flex-direction: column;
  }

  .body_head_tab6 {
    display: none;
  }

  .filter_table_area_2 {
    width: 60% !important;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border: solid 1px #e4e4e4;
    border-radius: 46px;
    padding: 10px;
    background: #fff;
    box-shadow: 0px 2px 6px #eaeaea;
  }

  .pool_deatail_area {
    display: flex;
    flex-direction: column;
    grid-gap: 40px;
    gap: 15px;
    position: relative;
  }
}

@media screen and (max-width: 672px) {
  .asset_status_details_div1_body {
    display: flex;
    justify-content: space-between;
    grid-gap: 40px;
    gap: 40px;
    flex-direction: column;
  }

  .transactionData_body {
    padding: 1em;
  }

  .transactionData_body1 {
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    overflow: scroll;
    white-space: nowrap;
  }

  .asset_detail_modal_div_conts {
    gap: 10px;
  }

  .pool_detail_assets_body_layer_1_cont1_heading_1 {
    font-weight: 600;
    font-size: 14px;
  }

  .asset_list_body_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    color: black;
    background: #fff;
    padding: 0.5em;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    border: solid 1px #ededed;
    position: sticky;
    top: 55px;
    background: white;
    font-weight: 600;
    z-index: 1;
    overflow: scroll;
    gap: 10px;
  }

  .asset_list_body_body_cont_1 {
    display: flex;
    justify-content: space-between;
    padding: 0.5em;
    background: #fff;
    border: solid 1px #f0f0f0;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0px 0px 4px #dfdfdf;
    align-items: center;
    animation: fade 0.5s ease-in-out;
    transition: 0.3s ease-in-out;
    overflow: scroll;
    gap: 10px;
  }

  .asset_list_body_body_cont_1a {
    font-size: 12px;
  }

  .asset_list_body_body_cont_1b {
    font-size: 12px;
  }

  .asset_list_body_body_cont_1c {
    font-size: 12px;
  }

  .asset_list_body_body_cont_1d {
    font-size: 12px;
  }

  .asset_list_body_body_cont_1e {
    font-size: 12px;
  }

  .asset_list_body_body_cont_1f {
    font-size: 12px;
  }

  .asset_list_body_body_cont_1g {
    font-size: 12px;
  }

  .pool_detail_assets_body_layer_1_cont1_heading_1 {
    font-weight: 600;
    font-size: 13px;
  }

  .asset_list_div {
    padding: 0.5em;
  }
}

@media screen and (max-width: 500px) {
  .maturity_date {
    display: none;
    width: 100%;
    justify-content: center;
  }

  .filter_table_area_2 {
    width: 100% !important;
  }

  .asset_list_body_body_cont_1d {
    font-size: 12px;
    display: none;
  }

  .asset_list_head {
    display: none;
  }
}

/* ============Table styles -=============== */
/* ============Table styles -=============== */

.branch_asset_table {
  box-sizing: border-box;
  margin: 0px auto;
  min-width: 0px;
  width: 100%;
  /* max-width: 1232px; */
  display: table;
  padding: 0px;
  border-collapse: separate;
  border-spacing: 0px 9px;
  margin-top: 20px;
}

.branch_asset_titles {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: table-header-group;
}

.branch_asset_title_div {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  background: #d0d0d0;
}

.branch_asset_heading_titles {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  padding: 10px;
  padding-left: 16px;
  padding-right: 16px;
  color: #606060;
  font-size: 14px;
  text-align: right;
  font-weight: 400;
  white-space: nowrap;
}

.branch_asset_heading_titles_first {
  position: sticky;
  left: 0;
  /* z-index: 100; */
  background: inherit;
  text-align: left;
}

.branch_asset_body {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  width: 100%;
}

.branch_asset_body_row {
  box-sizing: border-box;
  /* margin: 0px 0px 16px; */
  min-width: 0px;
  display: table-row;
  flex-direction: column;
  box-shadow: rgb(227 227 227) 0px 0px 6px;
  background: white;
  border-radius: 8px;
  transition: transform 0.2s ease-in-out 0s, box-shadow 0.2s ease-in-out 0s;
  cursor: pointer;
}

.branch_asset_body_row_data {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  padding: 16px;
  display: table-cell;
  justify-content: space-between;
  white-space: nowrap;
}

.branch_asset_body_row_data_first {
  position: sticky;
  left: 0;
  /* z-index: 100; */
  background: inherit;
  /* text-align: left; */
}

.branch_asset_body_row:hover {
  box-shadow: rgb(0 0 0 / 15%) 0px 0px 10px;
  transform: scaleX(0.99);
}

.branch_asset_body_row_data_first:first-child {
  border-radius: 8px 0px 0px 8px;
}

.branch_asset_body_row_data_last {
  text-align: right;
}

.branch_asset_body_row_data_last:last-child {
  border-radius: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.branch_asset_heading_titles_first:first-child {
  border-radius: 7px 0px 0px 7px;
  /* border: solid; */
}

.branch_asset_heading_titles_last:last-child {
  border-radius: 0px 7px 7px 0px;
  /* border: solid; */
}

.dark .branch_asset_title_div {
  background: #232326;
  /* color: #fff; */
  /* border: solid 1px #fff; */
}

.dark .branch_asset_heading_titles {
  color: #ffffff;
}

.dark .branch_asset_body_row {
  box-shadow: rgb(0 0 0) 0px 0px 6px;
  background: #232326;
  color: #fff;
}

@media screen and (max-width: 767px) {
  .branch_asset_body_row_data {
    padding: 8px;
  }
}

/* ============Table styles -=============== */
/* ============Table styles -=============== */
