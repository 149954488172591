.governance_details_cont_area {
  display: flex;
  flex-direction: column;
  margin-top: 2em;
}
.back_prev {
  display: flex;
  align-items: center;
  margin-bottom: 2em;
}
.back_prev_icon_div {
  background: #fff;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  margin-right: 6px;
}
.back_prev_icon {
  width: -webkit-fill-available !important;
}
.back_prev_span1 {
  margin-right: 6px;
}
.back_prev_span2 {
  margin-left: 6px;
  color: #81818b;
}
.governance_details_area_1 {
  display: flex;
  flex-direction: column;
  margin-bottom: 3em;
}
.governance_details_area_1_cont1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.governance_details_area_1_title_area {
  display: flex;
  flex-direction: column;
}
.governance_details_area_1_title_area_title {
  font-size: 52px;
  font-weight: 600;
  color: #fff;
}
.governance_details_area_1_title_area_div {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 22px;
}
.governance_details_area_1_title_area_div_span {
  color: #8e8e8e;
  font-size: 16px;
  margin-right: 10px;
}
.governance_details_area_1_status_area {
  display: flex;
  flex-direction: column;
}
.governance_details_area_1_status_area_1 {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.governance_details_area_1_status_area_1_cont1 {
  color: #8e8e8e;
}
.governance_details_area_1_status_area_1_cont2 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}
.governance_details_area_1_status_area_1_cont2_btn {
  width: 100%;
  background: #239e54;
  border: 0;
  border-radius: 7px;
  border-radius: 7px;
  color: #fff;
  min-width: 32px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
}
.governance_details_area_1_status_area_2 {
  color: #fff;
  font-size: 14px;
  margin-top: 10px;
  text-align: end;
}
.governance_details_area_1_cont2 {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  border-top: solid 1px #272727;
  padding-top: 1em;
}
.governance_details_area_1_cont1_title {
  font-size: 32px;
  font-weight: 500;
  color: #fff;
}
.governance_details_area_1_cont1_body {
  font-size: 24px;
  color: #8e8e8e;
}
.governance_details_area_2 {
  display: flex;
}
.governance_details_area_2_cont1 {
  width: 100%;
  margin-right: 1em;
  color: #fff;
  display: flex;
  flex-direction: column;
}
.governance_details_area_2_cont1_div1 {
  background: #232326;
  margin-bottom: 1em;
  border-radius: 10px;
  padding: 2em;
  display: flex;
  flex-direction: column;
}
.governance_details_area_2_cont1_div1_cont1a {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
}
.governance_details_area_2_cont1_div1_cont1 {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  border-top: solid 1px #3c3c40;
  padding-top: 1em;
}
.governance_details_area_2_cont1_div1_title {
  font-size: 38px;
  font-weight: 600;
  margin-bottom: 10px;
}
.governance_details_area_2_cont1_div1_body1 {
  display: flex;
  flex-direction: column;
}
.governance_details_area_2_cont1_div1_body1_progress1 {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.governance_details_area_2_cont1_div1_body1_progrees1_cont1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #9a9aa2;
}
.governance_details_area_2_cont1_div1_cont1_title {
  font-size: 38px;
  font-weight: 600;
  margin-bottom: 10px;
}
.governance_details_area_2_cont1_div1_cont1_body {
  display: flex;
  flex-direction: column;
}
.governance_details_area_2_cont1_div1_cont1_body_1 {
  width: 100%;
  border: solid 2px #5a5a62;
  padding: 1em;
  cursor: pointer;
  border-radius: 10px;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
}
.governance_details_area_2_cont1_div1_cont1_body_1_active {
  width: 100%;
  border: solid 3px #239e54;
  padding: 1em;
  cursor: pointer;
  border-radius: 10px;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  background: #323236;
}
.governance_details_area_2_cont1_div1_cont1_body_1_cont1 {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.governance_details_area_2_cont1_div1_cont1_body_1_cont1_div1 {
  font-size: 20px;
  font-weight: 500;
}
.governance_details_area_2_cont1_div1_cont1_body_1_cont1_div2 {
  font-size: 13px;
  color: #7c7c84;
}
.governance_details_area_2_cont1_div1_cont1_body_1_cont2_radio {
  display: block !important;
}
.governance_details_area_2_cont1_div1_cont1_body_btn_div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.governance_details_area_2_cont1_div1_cont1_body_btn {
  width: 200px;
  padding: 10px;
  border-radius: 13px;
  border: 0;
  background: #239e54;
  color: #fff;
  font-weight: 500;
}
.governance_details_area_2_cont1_div1_cont1_body_1_cont2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.governance_details_area_2_cont2_body_2_nodata_div_txt {
  margin-top: 10px;
}
.governance_details_area_2_cont2_body_2_nodata_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5em;
  color: #fff;
}
.governance_details_area_2_cont1_div1_cont1_body_btn:disabled {
  background: #314c3b;
  cursor: not-allowed;
  color: #5f8a70;
}
.governance_details_area_2_cont1_div2 {
  background: #232326;
  border-radius: 10px;
  padding: 2em;
  display: flex;
  flex-direction: column;
}
.governance_details_area_2_cont1_div2_title {
  font-size: 38px;
  font-weight: 600;
  margin-bottom: 10px;
}
.governance_details_area_2_cont1_div2_body {
  display: flex;
  flex-direction: column;
}
.governance_details_area_2_cont1_div2_body_1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.governance_details_area_2_cont1_div2_body_1_title {
  color: #7b7b85;
}
.governance_details_area_2_cont2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2em;
  background: #232326;
  border-radius: 10px;
  color: #fff;
}
.governance_details_area_2_cont2_title {
  font-size: 38px;
  font-weight: 600;
  margin-bottom: 10px;
}
.governance_details_area_2_cont2_body {
  display: flex;
  flex-direction: column;
  height: 75%;
}
.governance_details_area_2_cont2_body_1 {
  margin-bottom: 10px;
  width: 100%;
  position: relative;
}
.governance_details_area_2_cont1_div2_body_1_content {
  display: flex;
  align-items: center;
}
.governance_details_area_2_cont2_body_1_Input {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: solid #6f6f77;
  background: none;
  color: #b1b1bd;
  padding-left: 40px;
}
.governance_details_area_2_cont2_body_1_icon {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 10px;
  color: #b1b1bd;
}
.governance_details_area_2_cont2_body_2 {
  display: flex;
  flex-direction: column;
  border: solid 1px #5a5a62;
  border-radius: 10px;
  overflow: auto;
  height: 250px;
  min-height: 100%;
}
.governance_details_area_2_cont2_body_2_cont1 {
  display: flex;
  border-bottom: solid 1px #6f6f77;
  /* margin-bottom: 10px; */
  padding: 1em;
  /* border-radius: 10px; */
  justify-content: space-between;
}
.governance_details_area_2_cont2_body_2_cont1_div1 {
  font-size: 18px;
  font-weight: 500;
}
.governance_details_area_2_cont2_body_2_cont1_div2 {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.governance_details_area_2_cont2_body_2_cont1_div2_btn {
  font-size: 14px;
  width: 35px;
  padding: 2px 5px;
  border-radius: 7px;
  border: 0;
  margin-right: 10px;
  background: #239e54;
  color: #fff;
}
.governance_details_area_3 {
  display: flex;
  flex-direction: column;
  margin-top: 2em;
  background: #232326;
  padding: 2em;
  border-radius: 10px;
  color: #fff;
}
.governance_details_area_3_title_div {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
}
.governance_details_area_3_title {
  font-size: 38px;
  font-weight: 600;
  /* margin-bottom: 10px; */
}
.governance_details_area_3_para {
  color: #95959d;
}
.governance_details_area_3_body {
  display: flex;
  flex-direction: column;
}
.governance_details_area_3_body_1 {
  border: solid 1px #5a5a62;
  padding: 1em;
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.governance_details_area_3_body_1_cont1 {
  display: flex;
  flex-direction: column;
}
.governance_details_area_3_body_1_cont1_div1 {
  display: flex;
  align-items: center;
  font-size: 18px;
}
.governance_details_area_3_body_1_cont1_div1_txt {
  color: #b0b0b8;
  margin-right: 5px;
}
.governance_details_area_3_body_1_cont1_div2 {
  font-size: 24px;
  font-weight: 500;
}
.governance_details_area_3_body_1_cont1_div3 {
  color: #8d8d94;
  text-decoration: underline !important;
}
.governance_details_area_3_body_1_cont2 {
  display: flex;
  align-items: center;
}
.governance_details_area_3_body_1_cont2_txt {
  margin-right: 10px;
  color: #8d8d94;
}
.governance_details_area_3_body_1_cont2_status {
  background: #d69d16;
  border-radius: 7px;
  color: #fff;
  min-width: 32px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px 10px;
}

@media screen and (max-width: 991px) {
  .governance_details_area_2 {
    display: flex;
    flex-direction: column;
  }
  .governance_details_area_2_cont1 {
    margin-right: 0em;
  }
  .governance_details_area_2_cont2 {
    margin-top: 1em;
  }
}
@media screen and (max-width: 767px) {
  .governance_details_area_2_cont1_div1 {
    padding: 1em;
  }
  .governance_details_area_2_cont1_div2 {
    padding: 1em;
  }
  .governance_details_area_2_cont2 {
    padding: 1em;
  }
  .governance_details_area_1_title_area_title {
    font-size: 42px;
    line-height: 1.2;
  }
  .governance_details_area_1_cont1 {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
  }
  .governance_details_area_1_title_area {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1em;
  }
  .governance_details_area_1_status_area {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }
  .governance_details_area_2_cont1_div1_cont1_title {
    font-size: 28px;
  }
  .governance_details_area_2_cont1_div1_title {
    font-size: 28px;
  }
  .governance_details_area_2_cont1_div2_title {
    font-size: 28px;
  }
  .governance_details_area_2_cont2_title {
    font-size: 28px;
  }
}
