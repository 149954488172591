.dashboard-home {
  flex: 4;
  padding: 4em 2em;
  width: 100%;
  background: #f4fff8;
  position: relative;
  padding-top: 8em;
  padding-left: 14em;
}

.homeprofile-area {
  display: flex;
  align-items: flex-end;
  padding-left: 20px;
  position: relative;
}

.dashboard-area1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.dashboard-area2 {
  position: relative;
  margin-top: 20px;
}
.dashboard-area1-cont1 {
  background: #fff;
  width: 60%;
  height: 600px;
  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
  border-radius: 8px;
  box-shadow: 0px 0px 25px #c1e3cf;
  position: relative;
  background-size: cover;
  padding: 2em;
}
.dashboard-area1-heading-div {
  padding: 2em 1em;
  background: #e7fff0;
  border-radius: 8px;
}
.dashboard-area1-heading-div-txt {
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  margin: 0;
}

.dashboard-area1-heading-coin-display {
  display: flex;
  align-items: center;
  padding: 2em;
  font-weight: 600;
  font-size: 18px;
  justify-content: space-between;
  border-bottom: solid;
  border-color: #d7d7d7;
  border-width: 1px;
  padding-bottom: 1em;
}
.coin-display-amount {
  font-weight: 600;
  color: #22ad62;
}
.eth-icon {
  width: 35px;
  margin-right: 10 px;
}

.dashboard-area1-heading-supply-withdraw-pages {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 1em 2em;
  position: relative;
}
.supply-btn {
  border: none;
  background: none;
  border-radius: 8px;
  color: #989898;
  font-weight: 600;
  position: relative;
  font-size: 18px;
}
.supply-btn-active {
  border: none;
  background: none;
  border-radius: 8px;
  color: #22ad62;
  font-weight: 600;
  position: relative;
}
.supply-btn-active::after {
  content: "";
  background: linear-gradient(to right, #84e4a3, #6cbb86, #5f7ad6);
  /* width: 50%; */
  height: 4px;
  position: absolute;
  bottom: -9px;
  left: 10%;
  right: 10%;
  border-radius: 100px;
}

.dashboard-area1-heading-supply-withdraw-input {
  padding: 1em 2em;
  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
}

.withdraw-input-section1 {
  display: flex;
  justify-content: space-between;
}

.withdraw-input-section2 {
  position: relative;
  width: 100%;
}
.max-btn {
  position: absolute;
  top: 11px;
  right: 11px;
  padding: 0.3em 1.5em;
  border: none;
  background: #e7fff0;
  border-radius: 6px;
  height: 65%;
}
input.withdraw-input-box {
  width: 100%;
  height: 60px;
  border-radius: 10px;
  border: solid;
  border-width: 1px;
  border-color: #cdcdcd;
  padding: 1em;
}
input.withdraw-input-box:focus {
  outline: none;
}
input.withdraw-input-box:hover {
  border-width: 1.5px;
  border-color: #22ad62;
}

.not-safe-to-withdraw {
  display: none;
}
.withdraw-input-section3 {
  display: flex;
  justify-content: end;
  width: 100%;
}
.percent-increase {
  padding: 0.5em 1em;
  font-size: 12px;
  margin-left: 10px;
  border-radius: 50px;
  border: solid;
  border-width: 1px;
  background: none;
  border-color: #b1b1b1;
  width: 15%;
}
.percent-increase-active {
  padding: 0.5em 1em;
  font-size: 12px;
  margin-left: 10px;
  border-radius: 50px;
  border: solid;
  border-width: 1px;
  background: none;
  border-color: #e4a788;
  background: #e4a788;
  color: #fff;
  width: 15%;
}

.dashboard-area1-heading-supply-withdraw-btn {
  width: 100%;
  padding: 0em 2em;
}
.withdraw-btn {
  width: 100%;
  padding: 1em 2em;
  background: linear-gradient(to right, #84e4a3, #6cbb86, #5f7ad6);
  border-radius: 10px;
  border: none;
  color: #fff;
  margin-top: 30px;
}

.not-withdraw-btn {
  display: none;
}
/* ============================= */
/* ============================= */
/* ============================= */
/* dashboard second container start */

.dashboard-area1-cont2 {
  width: 38%;
  height: 600px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 15px #c1e3cf;
  position: relative;
  display: flex;
  flex-direction: column;
  grid-row-gap: 30px;
  padding: 1em;
}

.dashboard-area1-cont2::-webkit-scrollbar {
  display: none;
}
.dash-area1-cont2-column {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
}

.column1 {
  color: #949494;
  font-weight: 500;
}

.chart_section {
  width: 100%;
  height: 100%;
}

.chart_section text.recharts-text.recharts-cartesian-axis-tick-value {
  font-size: 12px;
}
/* dashboard second container end */
/* ================ */
/* ================ */
/* ================ */
/* ================ */

.dashboard-area1-cont3 {
  width: 100%;
  background: #fff;
  /* padding: 2em; */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  grid-row-gap: 20px;
  height: 300px;
  overflow-y: scroll;
  box-shadow: 0px 8px 20px #c2e1ce;
}
.dashboard-area1-cont3::-webkit-scrollbar {
  display: none;
}
.dash-area1-cont2-titles {
  display: flex;
  padding: 7px 30px;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid;
  border-width: 1px;
  border-color: #e8e8e8;
  font-size: 14px;
  font-weight: 600;
  color: #878787;
  cursor: pointer;
}
.dash-area1-cont2-header2 {
  padding: 7px 30px;
  background: #daffe8;
}
.dash-area1-cont2-header1 {
  font-size: 20px;
  font-weight: 600;
  color: #22ad62;
}
.title1 {
  display: flex;
  width: 10%;
  justify-content: flex-start;
}
.title2 {
  display: flex;
  width: 33%;
  justify-content: center;
}
.title3 {
  display: flex;
  width: 33%;
  justify-content: flex-end;
  font-size: 12px;
  color: #1500c5;
  text-decoration: underline !important;
}
.user {
  font-weight: 600;
  font-size: 16px;
  color: #000;
  text-decoration: none !important;
}

@media screen and (max-width: 767px) {
  .column-display {
    display: flex;
    flex-direction: column;
    grid-row-gap: 20px;
    align-items: flex-start;
  }
  .title2 {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .title3 {
    justify-content: space-between;
  }

  .title1,
  .title3 {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .title1::before {
    font-family: Inter, "Helvetica Neue", sans-serif;
    font-weight: 600;
    line-height: 1.5;
    font-size: 14px;
    color: var(--theme-ui-colors-text-muted, #708390);
    content: "AMOUNT";
    display: block;
  }
  .title2::before {
    font-family: Inter, "Helvetica Neue", sans-serif;
    font-weight: 600;
    line-height: 1.5;
    font-size: 14px;
    color: var(--theme-ui-colors-text-muted, #708390);
    content: "TYPE";
    display: block;
  }
  /* 
  .title3::before {
    font-family: Inter, "Helvetica Neue", sans-serif;
    font-weight: 600;
    line-height: 1.5;
    font-size: 14px;
    color: var(--theme-ui-colors-text-muted, #708390);
    content: "ADDRESS";
    display: block;
  } */
}
