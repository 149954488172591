div#FooterId {
  position: relative;
}

.footerSection {
  padding: 2em 0em;
  position: relative;
  background-color: #f3f3f3;
  overflow: hidden;
}

.law_write_up {
  font-size: 12px;
  color: #8f8f8f;
}

.footerArea {
  display: flex;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.add_color {
  color: #22ad62;
  font-weight: 600;
}

.dark .MuiPaper-root {
  color: rgba(255, 255, 255, 0.87) !important;
  /* transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #fff; */
}

.dark .footer_hr_rule {
  color: rgba(255, 255, 255, 0.87) !important;
  /* transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #fff; */
}

.dark .MuiIconButton-root {
  color: rgb(255 255 255) !important;
}

.egr2-logo {
  width: 150px;
}

.dark .egr2-logo {
  display: none;
}

.egr2-logo2 {
  width: 150px;
  display: none;
}

.dark .egr2-logo2 {
  display: inline-block;
}

.footerPara {
  font-size: 12px;
  margin: 20px 0px;
}

.footerCard1 {
  padding: 2em 0em;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.twitter_icon {
  /* font-size: 14px; */
  background: #c0e6d2;
  border-radius: 50%;
  padding: 15px;
  font-size: 58px !important;
  /* width: 28px; */
  color: #22ad62;
}

.dark .twitter_icon {
  /* font-size: 14px; */
  background: #34453c;
  color: #ffffff;
}

.footerIcons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0px;
}

.footerCard2 {
  padding: 2em;
}

.footerCard3 {
  display: none;
}

.footerCardTitle {
  font-size: 16px;
  font-weight: 500;
}

.footerLinks {
  display: grid;
  margin-top: 10px;
}

.twitter {
  color: #55bc7e;
  margin-right: 1em;
}

.FooterPageLinks {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.FooterPageLinks_1 {
  margin-right: 11px;
  /* text-decoration: underline !important; */
}

.twitter:hover {
  color: #000000;
  border-color: #000000;
}

.c1link1 {
  margin-top: 10px;
  color: #525252;
  font-size: 13px;
  width: 100% !important;
  text-align: left;
}

.c1link1:hover {
  color: #55bc7e;
}

.c1linklast {
  font-size: 13px;
  margin-top: 10px;
  color: #55bc7e;
  text-decoration: #55bc7e underline;
}

.c1linklast:hover {
  color: #525252;
  text-decoration: #525252 underline;
}

.footerBottomPara {
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  color: #8a8a8a;
}

.MuiTypography-body1 {
  font-family: "Mulish" !important;
  font-weight: 500 !important;
}

.MuiAccordionSummary-content.Mui-expanded {
  margin: 0 !important;
}

.MuiAccordionDetails-root {
  justify-content: flex-start !important;
  padding: 8px 16px 16px;
  padding-top: 0 !important;
}

.MuiAccordion-root:before {
  background-color: rgb(164 164 164 / 12%) !important;
}

.MuiAccordion-root {
  background: none !important;
  /* outline: none; */
  /* border: none; */
  box-shadow: none !important;
}

/* ========================== */
/* ========================== */
/* ========================== */
/* ========================== */
/* ========================== */

.dark .footerSection {
  background-color: #121213;
}

.dark .footerCardTitle {
  color: #fff;
}

.dark .c1link1 {
  color: #b4cbbd;
}

.dark .footerBottomPara {
  color: #ffffff;
}

/* ========================== */
/* ========================== */
/* ========================== */
/* ========================== */
/* ========================== */
/* ========================== */
/* ========================== */
.footerDiv {
  padding: 4em 0em;
  background: #ffffff;
  position: relative;
  color: #000000;
}

.footerDivArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.footerDiv1_img {
  width: 95px;
}

.footer_hr {
  width: 100%;
  color: #ffffff !important;
  margin: 15px 0px;
  background: #ffffff !important;
  border: solid 1px #f2f2f2;
}

.footerDiv1 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.footer_lastDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dark .footer_lastDiv {
  color: #fff;
}
.footerDiv1_area1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footerDiv1_area1_cont1 {
  display: flex;
  align-items: center;
  font-size: 28px;
  font-weight: 600;
}

.verticalRule {
  height: 35px;
  width: 1px;
  background: #494949;
  margin: 0px 6px;
}

.footerDiv1_img2 {
  width: 50px;
}

.footerDiv1_area1_cont2 {
  margin-top: 15px;
}

.footerDiv1_area1_cont2_links {
  display: flex;
  align-items: center;
}

.footerDiv1_area1_cont2_links_link1 {
  background: #f7f7f7;
  margin-right: 10px;
  color: #545454;
  width: 14px !important;
  height: 14px !important;
  font-size: 12px !important;
  padding: 8px;
  border-radius: 50px;
}
.footerDiv1_area1_cont2_links_link_tel {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 5px;
  /* background: #ff562330; */
  padding: 7px;
  border-radius: 19px;
  /* border: solid 1px #ff5623; */
  width: 40px;
}
.footerDiv1_area1_cont2_links_link_tel .footerDiv1_area1_cont2_links_link1 {
  margin-right: 0;
}
.footerDiv1_area1_cont2_links_link_tel_span {
  font-size: 9px;
  margin-top: 5px;
  font-weight: 600;
  color: #ff5623;
}
.footerDiv1_area2 {
  display: flex;
}
.footerDiv1_area1_cont2_links_link_img {
  width: 14px;
  background: #f7f7f7;
  padding: 8px;
  border-radius: 50px;
  margin-bottom: 0px;
}
.footerDiv1_area2_cont1 {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-right: 10px;
}

.footerDiv1_area2_title {
  font-weight: 600;
}
.dark .footerDiv1_area2_title {
  color: #fff;
}

.footerDiv1_area2_title_subLinks_div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px;
  font-size: 14px;
  margin-top: 10px;
}

.footerDiv1_area2_title_subLinks_div_link1 {
  color: #5a5a5a;
  text-decoration: none;
  margin-bottom: 6px;
}

@media screen and (max-width: 767px) {
  .footerDiv1 {
    justify-content: space-between;
    flex-direction: column-reverse;
  }

  .footerDiv1_area1 {
    flex-direction: column-reverse;
    margin-bottom: 1em;
    width: 100%;
  }

  .footerDiv1_area1_cont2 {
    margin-top: 0px;
    margin-bottom: 15px;
  }

  .footerDiv1_area2_cont1 {
    flex-direction: column;

    margin-right: 0px;
    margin-bottom: 1em;
  }

  .footerDiv1_area2_title {
    font-weight: 600;
    font-size: 24px;
    width: 100%;
    text-align: left;
  }

  .footerDiv1_area2 {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .footerDiv1_area2_title {
    font-weight: 600;
    font-size: 16px;
    width: 100%;
    text-align: left;
  }

  .footerDiv1_area2_title_subLinks_div {
    font-size: 14px;
    width: 100%;
  }

  .footerDiv1_area2_title_subLinks_div_link1 {
    width: 100%;
    text-align: left;
  }
  .footerDiv1_img2 {
    width: 30px;
  }
  .footerDiv1_area1_cont1 {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 800;
  }

  .footer_lastDiv {
    width: 100%;
    text-align: left;
  }

  .footerDiv {
    padding: 2em 0em;
  }
  /* .footerDiv1_area1_cont2_links_link1 {
    width: 15px !important;
    height: 15px !important;
    font-size: 16px !important;
    padding: 6px;
  } */
}
