.newAddLiquidityDiv {
  color: #fff;
  padding: 1em;
}
.newAddLiquidityDiv_cont {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.newAddLiquidityDiv_cont_div1 {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.newAddLiquidityDiv_cont_div1_title {
  margin-bottom: 5px;
  font-size: 14px;
  color: #c2c2c2;
  padding: 0px 10px;
}
.newAddLiquidityDiv_cont_div1_div {
  padding: 15px;
  background: #121213;
  border-radius: 10px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  color: #fff;
}
.newAddLiquidityDiv_cont_div1_div_icon {
  font-size: 35px;
}
.newAddLiquidityDiv_cont_div2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1em;
}
.newAddLiquidityDiv_cont_div2_cont1 {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.newAddLiquidityDiv_cont_div2_cont1_spans {
  font-size: 14px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  color: #c2c2c2;
  padding: 0em 10px;
}
.newAddLiquidityDiv_cont_div2_cont1_span2_icon {
  font-size: 16px !important;
  margin-right: 5px;
}
.newAddLiquidityDiv_cont_div2_cont1_input {
  padding: 15px 15px;
  background: #121213;
  width: 100%;
  border: none;
  border-radius: 10px;
  color: #fff;
  font-size: 38px;
  font-weight: 600;
}

.newAddLiquidityDiv_cont_div2_btn {
  padding: 15px;
  border-radius: 13px;
  border: none;
}
.newAddLiquidityDiv_cont_icon {
  display: none !important;
}
.display_tokens_drop .tokenDrop_drop_div {
  width: 200px;
  top: 36px;
  left: -86%;
}

.tokenDrop_drop_div {
  position: absolute;
  top: 60px;
  background: #121213;
  width: 100%;
  left: 0;
  border-radius: 10px;
  overflow: auto;
  font-size: 14px;
  box-shadow: 0px 9px 14px #000;
  display: flex;
  flex-direction: column;
  z-index: 1;
}
.tokenDrop_drop_div_cont {
  padding: 20px 15px;
  border-bottom: solid 1px #282828;
  cursor: pointer;
  display: flex;
}
.newAddLiquidityDiv_cont_div2_select_pair_div {
  background: #121213;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  border-radius: 10px;
  padding: 1em;
  height: 200px;
  color: #979797;
}
.tokenDrop_drop_div_cont:hover {
  background: #212123;
}

.tokenDrop_drop_div_cont_img {
  width: 20px;
  height: 20px;
  object-fit: cover;
  position: relative;
}
.tokenDrop_drop_div_cont_img2 {
  width: 20px;
  height: 20px;
  object-fit: cover;
  margin-left: -12px;
}
.selectedToken_txt {
  display: flex;
  align-items: center;
}

.newAddLiquidityDiv_cont_div2_cont1_span1 {
  display: flex;
  align-items: center;
}
.newAddLiquidityDiv_cont_div2_cont1_span1_img {
  width: 15px;
  height: 15px;
  object-fit: cover;
  position: relative;
  margin-right: 3px;
}
.newAddLiquidityDiv_cont_div2_cont1_span2 {
  cursor: pointer;
}
.newAddLiquidityDiv_cont_div2_cont1_span2:hover {
  color: #70c28b;
}
.newAddLiquidityDiv_cont_div2 .updatedSwapSwapBtn {
  margin-bottom: 15px;
}
.liquidity_areab {
  width: 100%;
  display: flex;
  align-items: flex-start;
  position: relative;
  flex-direction: row-reverse;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10em;
}
.liquidity_area_tabs {
  width: 100%;
  max-width: 425px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
  background: #232326;
  border-radius: 15px;
  border: solid 1px #313135;
  padding: 6px;
  position: sticky;
  top: 0;
  z-index: 1;
}
.liquidity_area_tabs_1_active1 {
  width: 50%;
  background: #1b8b4f;
  padding: 10px 20px;
  border-radius: 12px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  position: absolute;
  left: 0;
  height: 100%;
  transform: translateX(0%);
  transition: all 0.2s linear;
}
.liquidity_area_tabs_1_active2 {
  width: 50%;
  background: #1b8b4f;
  padding: 10px 20px;
  border-radius: 12px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  position: absolute;
  left: 0;
  height: 100%;
  transform: translateX(100%);
  transition: all 0.2s linear;
}
.liquidity_area_tabs_1 {
  width: 100%;
  background: none;
  padding: 10px 20px;
  border-radius: 12px;
  color: #ababb0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  z-index: 1;
  transition: all 0.2s linear;
}
.liquidity_area_tabs_1_active {
  width: 100%;
  background: none;
  padding: 10px 20px;
  border-radius: 12px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  z-index: 1;
  transition: all 0.2s linear;
}
.RemoveLiquidity_div {
  background: #232326;
  box-shadow: 0px 10px 20px #000;
  border: solid 1px #313135;
  color: #fff;
  max-width: 425px;
  width: 100%;
  border-radius: 25px;
  padding: 1em;
}
.RemoveLiquidity_div_title {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  color: #cdcdd6;
  margin-bottom: 10px;
  border-bottom: solid 1px #39393d;
  padding-bottom: 10px;
}
.RemoveLiquidity_div_title_para {
  font-size: 14px;
  font-weight: 300;
}
.RemoveLiquidity_div_body {
  display: flex;
  flex-direction: column;
}
.RemoveLiquidity_div_body_title {
  background: #121213;
  padding: 10px 15px;
  margin: 10px 0em;
  border-radius: 10px;
  font-size: 14px;
}
.RemoveLiquidity_div_body_cont {
  display: flex;
  flex-direction: column;
}
.RemoveLiquidity_div_body_cont_1 {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
}
.RemoveLiquidity_div_body_cont_1_txt {
  color: #6aca98;
  font-size: 14px;
  margin-bottom: 5px;
}
.RemoveLiquidity_div_body_cont_1_amount_div {
  padding: 1em;
  border: solid 1px #404043;
  border-radius: 15px;
  background: #121213;
}
.RemoveLiquidity_div_body_cont_1_amount_percent {
  font-size: 62px;
  font-weight: 500;
  margin-bottom: 10px;
}
.RemoveLiquidity_div_body_cont_2 {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.RemoveLiquidity_div_body_cont_2b {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.RemoveLiquidity_div_body_cont_2_title {
  font-size: 14px;
  margin-bottom: 6px;
  color: #6aca97;
}
.RemoveLiquidity_div_body_cont_2_div {
  padding: 1em;
  border: solid 1px #404043;
  border-radius: 15px;
  background: #121213;
  display: flex;
  flex-direction: column;
}
.RemoveLiquidity_div_body_cont_1_amount_div_range {
  width: 100%;
}
.RemoveLiquidity_div_body_cont_2_div_cont1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2px 0px;
  font-size: 14px;
}
.RemoveLiquidity_div_body_cont_2_div_cont1_title {
  color: #a2a2a9;
  display: flex;
  align-items: center;
}
.RemoveLiquidity_div_body_cont_2_div_cont1_title img {
  margin-right: 4px;
}
.RemoveLiquidity_div_body_cont .updatedSwapSwapBtn {
  margin-top: 1.5em;
}
