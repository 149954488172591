.second-eusd-token-section {
  padding: 4em 0;
  position: relative;
  overflow: hidden;
}

/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */

.mySwiperDiv_Power {
  /* background: #000; */
  padding: 10px;
}

.nft_area2_stat_div_area_cont1_icon_light {
  display: block;
}

.nft_area2_stat_div_area_cont1_icon_dark {
  display: none;
}

.dark .nft_area2_stat_div_area_cont1_icon_light {
  display: none;
}

.dark .nft_area2_stat_div_area_cont1_icon_dark {
  display: block;
}

.mySwiperDiv_Power {
  display: flex;
  flex-direction: column;
  /* gap: 20em; */
  align-items: center;
  text-align: center;
  justify-content: space-between;
  height: 100svh !important;
}

.PowerComponent_body {
  display: flex;
  flex-direction: column;
}

.PowerSwiper {
  height: 100%;
  cursor: grab;
  padding-bottom: 8em;
  width: 100%;
}

.PowerComponent_body1_img {
  width: 100%;
}

.whatPossible_body_area {
  width: 100%;
  margin-top: 1em;
  /* height: 37em; */
  /* overflow: hidden; */
}

.whatPossible_body_area_1 {
  display: flex;
  align-items: center;
  color: #fff;
  margin-top: 3em;
}

.whatPossible_body_area_1_cont1 {
  width: 59em;
  position: absolute;
  left: -20%;
  background: #256c421c;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2em 6em;
  border-radius: 20em;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.whatPossible_body_area_1_cont1b {
  width: 59em;
  position: absolute;
  right: -20%;
  background: #256c421c;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2em 6em;
  border-radius: 20em;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.whatPossible_body_area_1_cont2 {
  display: flex;
  flex-direction: column;
  width: 48%;
  align-items: baseline;
  margin-left: 4em;
  text-align: left;
}

.whatPossible_body_area_1_cont2b {
  display: flex;
  flex-direction: column;
  width: 48%;
  align-items: baseline;
  margin-right: 4em;
  text-align: left;
}

.whatPossible_body_area_1_cont2a {
  display: flex;
  flex-direction: column;
  width: 64%;
  align-items: baseline;
  margin-left: 4em;
  text-align: left;
}

.whatPossible_body_area_1_cont2_title {
  font-size: 60px;
  font-weight: 600;
  margin-bottom: 9px;
  line-height: 1.2;
}

.whatPossible_body_area_1_cont2_title2 {
  font-size: 60px;
  font-weight: 600;
  margin-bottom: 9px;
  line-height: 1.2;
}

.whatPossible_body_area_1_cont2_para {
  color: #9bb3a5;
  font-size: 18px;
}

.whatPossible_body_area_1_cont1_img {
  width: 72%;
  margin-left: auto;
}

.whatPossible_body_area_1_cont1_img2 {
  width: 72%;
  /* margin-right: auto; */
}

.whatPossible_body_area_1_cont2__btn_div {
  width: 100%;
  margin-top: 2em;
}

.whatPossible_body_area_1_cont2__btn {
  padding: 14px 0px;
  width: 160px;
  border-radius: 10px;
  background: linear-gradient(to right, #84e4a3, #6cbb86, #5f7ad6);
  color: #fff;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.how_it_works_area_title_area {
  height: 100svh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.how_it_works_area_title_area1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.how_it_works_area_para {
  font-size: 18px;
  width: 70% !important;
  text-align: center;
  color: #9bb3a5;
}

.how_it_works_area_title_area2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 4em;
}

.how_it_works_area_title_area2_cont1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px #202721;
  margin-bottom: 1em;
  color: #fff;
  font-size: 54px;
  padding: 0em 1em;
  padding-bottom: 10px;
  cursor: pointer;
}

.section {
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.section.active {
  opacity: 1;
}

.how_it_works_possible {
  /* height: 100svh; */
  width: 100%;
  background: #000;
  padding: 12em 0em;
  position: relative;
  background-image: radial-gradient(#141d15, #0d140e, #000, #141d15);
}

.how_it_works_possibleb {
  /* height: 100svh; */
  width: 100%;
  background: #000;
  padding: 12em 0em;
  padding-bottom: 0em;
  position: relative;
  background-image: radial-gradient(#141d15, #0d140e, #000, #141d15);
}

.how_it_works_possible_area {
  align-items: center;
  display: flex;
  /* flex-direction: column; */
  height: 100%;
  /* justify-content: space-between; */
}

.how_it_works_possible_area_title_area {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.how_it_works_possible_area_title_area1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2em;
}

.how_it_works_possible_area_head {
  font-size: 72px;
  color: #fff;
  font-weight: 600;
}

.how_it_works_possible_area_para {
  color: grey;
  font-size: 22px;
  width: 55%;
  text-align: center;
}

.how_it_works_area_title_area2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 4em;
}

.whatPossible_body_area {
  width: 100%;
  /* margin-top: 1em; */
  height: 100%;
  /* overflow: hidden; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.how_it_works2_area {
  position: relative;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}
.how_it_works2_area_head {
  color: #fff;
  font-size: 62px;
  font-weight: 600;
  width: 100%;
  line-height: 1;
  width: 87%;
  margin-bottom: 2em;
}
.how_it_works2_area_body {
  display: flex;
  /* align-items: center; */
}
.how_it_works2_area_body_area1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}
.how_it_works2_area_body_area2 {
  width: 80%;
  margin: 0px 1em;
}
.how_it_works2_area_body_area2_img {
  width: 100%;
}
.how_it_works2_area_body_area3 {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}
.how_it_works2_area_body_area1_cont1 {
  color: #fff;
  margin-bottom: 4em;
  display: flex;
  flex-direction: column;
}
.how_it_works2_area_body_area1_cont1_title {
  margin-bottom: 1em;
}
.how_it_works2_area_body_area1_cont1_title_img {
  width: 50px;
}
.how_it_works2_area_body_area1_cont1_txts {
  display: flex;
  flex-direction: column;
}
.how_it_works2_area_body_area1_cont1_txts_head {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 10px;
  line-height: 1.2;
}
.how_it_works2_area_body_area1_cont1_txts_para {
  color: #9d9d9d;
}
.whatPossible_body_area_1 {
  display: flex;
  align-items: center;
  color: #fff;
  /* margin-top: 3em; */
  height: 100%;
  justify-content: flex-end;
  position: relative;
}

.whatPossible_body_area_1b {
  display: flex;
  align-items: center;
  color: #fff;
  /* margin-top: 3em; */
  height: 100%;
  justify-content: flex-start;
  position: relative;
}

.fade-in-from-bottom {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.fade-in-from-bottom--visible {
  opacity: 1;
  transform: none;
}

/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
.how_it_works_area_body {
  justify-content: space-between;
  margin-top: 4em;
  display: grid;
  gap: 45px;
  width: 100%;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  justify-content: flex-start;
}

.partners_body {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.how_it_works_area_body_area1 {
  background: #27332c5e;
  margin-right: 0em;
  padding: 40px;
  border: solid 1px #37483e;
  /* box-shadow: 0px 10px 25px black; */
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  cursor: pointer;
  height: 100%;
}

.how_it_works_area_body_area1_icon_div {
  width: 100%;
}

.how_it_works_area_body_area1_icon {
  width: 100px;
}

.how_it_works_area_body_area1_title {
  font-size: 42px;
  font-weight: 600;
  color: #ffff;
  margin-top: 10px;
  /* font-family: "Sofia Pro Bold", sans-serif; */
}

.how_it_works_area_body_area1_para {
  margin-top: 5px;
  color: #a7b4ad;
  font-size: 19px;
}

.how_it_works_area_body_area1_link_div {
  margin-top: 3em;
}

.how_it_works_area_body_area1_link {
  padding: 15px 0px;
  border: solid 1px white;
  border-radius: 26px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.how_it_works_area_body_area1_last {
  margin-right: 0;
}

/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
.what_we_offer_area {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.what_we_offer_area1 {
  display: flex;
  margin-top: 6em;
  align-items: center;
}

.what_we_offer_area1_cont1 {
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  margin-right: 6em;
}

.what_we_offer_area1_cont2 {
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  margin-left: 6em;
}

.what_we_offer_area1_cont1_title {
  font-size: 32px;
  font-weight: 600;
  color: #fff;
}

.what_we_offer_area1_cont1_para {
  font-size: 18px;
  color: #95a99e;
  margin: 1em 0;
}

.what_we_offer_area1_cont1_btn {
  padding: 15px;
  border-radius: 50px;
  border: none;
  width: 225px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 600;
  background: linear-gradient(to right, #84e4a3, #6cbb86, #5f7ad6);
  color: #fff;
}

.what_we_offer_body_display_save_demo_app_card_area1_range_input {
  width: 100%;
  margin: 1em 0;
}

.what_we_offer_body_display_save_demo_app_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: #27332c5e;
  border: solid 1px #384c41;
  border-radius: 20px;
  padding: 2em;
  text-align: left;
  width: 100%;
}

.what_we_offer_body_display_save_demo_app_title {
  position: absolute;
  top: -4%;
  background: #228e4d3b;
  padding: 0.5em;
  backdrop-filter: blur(10px);
  border-radius: 50px;
  color: #fff;
  width: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.what_we_offer_body_display_save_demo_app_card_area1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 4em;
}

.what_we_offer_body_display_save_demo_app_card_area1_cont1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  color: #fff;
}

.what_we_offer_body_display_save_demo_app_card_area2_cont1_head {
  color: #fff;
}

.what_we_offer_body_display_save_demo_app_card_area1_range {
  width: 100%;
}

.what_we_offer_body_display_save_demo_app_card_area1_max_min {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #90a799;
}

.what_we_offer_body_display_save_demo_app_card_area2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: solid 1px #2f3b34;
  padding: 1em;
}

.what_we_offer_body_display_save_demo_app_card_area2_cont1_para {
  color: #228e4d;
}

/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
.ecoSystem_section {
  background: #000;
  padding: 4em 0em;
  position: relative;
}

.ecoSystem_area {
  display: flex;
  align-items: center;
  padding: 4em;
  border: solid 1px #5a806a;
  border-radius: 15px;
  background: #88b89b;
  background-image: linear-gradient(31deg, #6a947a, #2a543b);
  height: 20em;
}

.ecoSystem_area1 {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.ecoSystem_area2 {
  width: 100%;
}

.ecoSystem_area1_title {
  font-size: 38px;
  color: #fff;
  line-height: 1.2;
  font-weight: 600;
}

.ecoSystem_area1_para {
  margin: 0.5em 0em;
  color: gainsboro;
}

.ecoSystem_area1_btn {
  width: 200px;
  padding: 12px;
  border-radius: 50px;
  border: none;
  background: #152f20;
  color: #fff;
  margin-top: 1em;
  box-shadow: 0px 20px 20px #3c6e50;
}

/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
.join_ecosystem_section {
  padding: 4em 0em;
  position: relative;
  background: #112117;
  border-bottom: solid 1px #293c31;
}

.join_ecosystem_area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.join_ecosystem_area1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.join_ecosystem_area1_title {
  font-size: 42px;
  color: #fff;
  font-weight: 600;
}

.join_ecosystem_area1_para {
  width: 42em;
  color: #90a79a;
  margin-top: 1em;
}

/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
.voting_on_egoras_dapp_sample_div {
  /* background: grey; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  background: #27332c5e;
  border: solid 1px #384c41;
  border-radius: 20px;
  padding: 2em;
  text-align: left;
  width: 100%;
  color: #fff;
}

.voting_on_egoras_dapp_sample_div_cont1 {
  display: flex;
  flex-direction: column;
}

.voting_on_egoras_dapp_sample_div_cont1_head {
  /* margin-bottom: 0.5em; */
  font-size: 14px;
  color: #adbbb3;
}

.voting_on_egoras_dapp_sample_div_cont1_para {
  font-size: 18px;
  font-weight: 600;
}

.voting_on_egoras_dapp_sample_div_cont1 {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  width: 100%;
}

.voting_on_egoras_dapp_sample_div_cont1_status {
  /* background: grey; */
  padding: 0.5em;
  width: 200px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin-top: 5px;
  background: #fece79;
  color: #44361f;
  font-weight: 600;
}

.voting_progress_bar {
  height: 25px;
  width: 100%;
}

.voting_on_egoras_dapp_sample_div_cont1_btn_vote_div {
  width: 100%;
}

.voting_on_egoras_dapp_sample_div_cont1_btn_vote_div {
  width: 100%;
}

.voting_on_egoras_dapp_sample_div_cont1_btn_vote {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px;
  border-radius: 10px;
  border: none;
  background: linear-gradient(to right, #84e4a3, #6cbb86, #5f7ad6);
  color: #fff;
}

.voting_on_egoras_dapp_sample_div_cont1_btn_vote_details {
  width: 100%;
  padding: 14px;
  border-radius: 10px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #495f52;
  color: #d6e6dd;
}

.voting_on_egoras_dapp_sample_div_cont1_btn_vote_details_div {
  width: 100%;
  margin-top: 0.5em;
}

/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
/* ========= */
.container {
  height: 100% !important;
}

.how_it_works_section {
  position: relative;
}

.how_it_works_area_para {
  font-size: 18px;
  width: 42%;
  text-align: center;
  color: #9bb3a5;
}

.dark .how_it_works_section {
  position: relative;
  background: #000;
}

.how_it_works_area_head {
  font-size: 75px;
  color: #fff;
  font-weight: 600;
  /* font-family: "Sofia Pro Bold", sans-serif; */
}

.how_it_works_area {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dark .hero_backdrop {
  position: absolute;
  bottom: -20%;
  width: 100%;
  z-index: 1;
}

.hero_backdrop {
  position: absolute;
  bottom: -20%;
  width: 100%;
}

.nft-txt-area2 {
  margin-top: 2em;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.spline_div {
  padding: 4em;
  height: 42em;
}

.hero_area2 {
  position: relative;
  height: 40em;
  width: 100%;
}

.hero_area2_img {
  /* width: 99%; */
  position: absolute;
  right: -313px;
  width: 129em;
  /* height: 100%; */
  top: -16%;
}

.token-eusd-earning-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.span4a-txts {
  font-size: 100px;
  width: 100%;
  font-weight: 600;
  line-height: 1;
  /* font-family: "Sofia Pro Bold", sans-serif; */
}

.real_life {
  background: linear-gradient(to right, #84e4a3, #6cbb86, #5f7ad6);
  -webkit-background-clip: text;
  color: transparent;
  background-clip: text;
}

.nft-img-area2 {
  width: 100% !important;
  position: relative;
}

.liquidity_hero_bg_1 {
  width: 167%;
  position: absolute;
  top: -21%;
  /* bottom: -113px; */
  left: -35%;
}

.liquidity_hero_bg_logos1 {
  width: 12%;
  top: 11%;
  position: absolute;
  left: 43%;
  filter: drop-shadow(2px 4px 6px #c0c0c0) grayscale(1);
  /* animation: scale 4s linear infinite; */
}

.liquidity_hero_bg_logos2 {
  width: 12%;
  top: 38%;
  position: absolute;
  left: 67%;
  filter: drop-shadow(2px 4px 6px #c0c0c0) grayscale(1);
  /* animation: scale 4s linear infinite; */
}

.vertical_rule2a {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  width: 0% !important;
  align-self: stretch;
  border-left: 1px solid rgb(238, 238, 238);
  /* height: 10px; */
}

.liquidity_hero_bg_logos4 {
  width: 12%;
  top: 38%;
  position: absolute;
  right: 71%;
  filter: drop-shadow(2px 4px 6px #c0c0c0) grayscale(1);
  /* animation: scale 4s linear infinite; */
}

.liquidity_hero_bg_logos3 {
  width: 12%;
  bottom: 20%;
  position: absolute;
  left: 42%;
  filter: drop-shadow(2px 4px 6px #c0c0c0) grayscale(1);
  /* animation: scale 4s linear infinite; */
}

.animate {
  animation: scale 4s linear infinite;
}

.liquidity_hero_bg_base {
  width: 100%;
}

.liquidity_hero_center_turning_bg {
  width: 23%;
  position: absolute;
  top: 0;
  bottom: 10%;
  margin: auto;
  left: 38%;
  animation: spin 4s linear infinite;
}

.liquidity_hero_center_asset_display_bg {
  width: 17%;
  position: absolute;
  top: 0;
  bottom: 10%;
  margin: auto;
  left: 41%;
  /* animation: spin 4s linear infinite; */
}

.shooting_light1 {
  position: absolute;
  top: 14%;
  left: 41%;
  transform: rotate(268deg);
  z-index: -1;
  width: 15%;
}

.shooting_light3 {
  position: absolute;
  bottom: 22%;
  left: 40%;
  transform: rotate(90deg);
  z-index: -1;
  width: 15%;
}

.shooting_light2 {
  position: absolute;
  bottom: 54%;
  left: 67%;
  z-index: -1;
  width: 15%;
}

.shooting_light4 {
  position: absolute;
  bottom: 54%;
  right: 72%;
  z-index: -1;
  width: 15%;
  transform: rotate(180deg);
}

.asset_display_icon1 {
  position: absolute;
  width: 13%;
  left: 43%;
  right: 45%;
  margin: auto;
  top: 38%;
  bottom: 48%;
  z-index: 1;
  opacity: 1;
  display: block;
  animation: fadeIn 4s linear;
}

.asset_display_icon1_none {
  display: none;
  opacity: 0;
}

/* .asset_display_icon1 {
  position: absolute;
  width: 60px;
  left: -10px;
  right: 0;
  margin: auto;
  top: -55px;
  bottom: 0;
  z-index: 1;
} */
.shoot {
  animation: shoot 4s linear infinite;
}

.shoot3 {
  animation: shoot3 4s linear infinite;
}

.shoot2 {
  animation: shoot2 4s linear infinite;
}

.shoot4 {
  animation: shoot4 4s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes scale {
  0% {
    transform: scale(1);
    filter: drop-shadow(2px 4px 6px #c0c0c0) grayscale(1);
  }

  50% {
    transform: scale(1.5);
    filter: drop-shadow(2px 4px 6px #c0c0c0) grayscale(0);
  }

  100% {
    transform: scale(1);
    filter: drop-shadow(2px 4px 6px #c0c0c0) grayscale(1);
  }
}

@keyframes shoot {
  0% {
    top: 14%;
  }

  100% {
    top: 40%;
  }
}

@keyframes shoot3 {
  0% {
    bottom: 22%;
  }

  100% {
    bottom: 50%;
  }
}

@keyframes shoot2 {
  0% {
    left: 67%;
  }

  /* 50% {
    bottom: 110px;
  } */
  100% {
    left: 45%;
  }
}

@keyframes shoot4 {
  0% {
    right: 72%;
  }

  /* 50% {
    bottom: 110px;
  } */
  100% {
    right: 47%;
  }
}

.floating_absolute_div {
  position: absolute;
  width: 100%;
  /* padding: 2em; */
  background: #fff;
  box-shadow: 0px 0px 25px #e4f3ea;
  z-index: 10;
  max-width: 1280px;
  margin: auto;
  left: 0;
  right: 0;
  border-radius: 10px;
  border: solid 1px #d6e2db;
  bottom: -85px;
}

.floating_div_cont_area {
  display: flex;
  justify-content: space-between;
}

.floating_div_cont_area1 {
  text-align: center;
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  grid-gap: 5px;
  gap: 5px;
  padding: 2em 0em;
  width: 100%;
  align-items: center;
}

.floating_div_cont_area1_cont1 {
  font-size: 26px;
  color: #7c7c7c;
}

.floating_div_cont_area1_cont2 {
  font-size: 54px;
  font-weight: 600;
}

.span4b-txts {
  width: 75%;
  /* margin-top: 20px; */
  box-sizing: border-box;
  /* margin: 20px 0px 0px; */
  min-width: 0px;
  font-size: 25px;
  color: #7a897f;
  font-weight: 400;
  margin: auto;
}

.floating_images_cont {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  /* background: grey; */
  width: 100%;
  z-index: 4;
}

.float_cube2 {
  position: absolute;
  /* width: 200px; */
  width: 110px;
  bottom: 68px;
  left: 68px;
  animation: topMove2 5s linear infinite;
}

.float_layer1 {
  position: absolute;
  /* width: 200px; */
  width: 160px;
  top: 54%;
  left: 7%;
}

.float_cube1 {
  position: absolute;
  width: 110px;
  bottom: 0;
  z-index: 2;
  animation: topMove 5s linear infinite;
}

@keyframes topMove {
  0% {
    bottom: 0px;
  }

  50% {
    bottom: 15px;
  }

  100% {
    bottom: 0px;
  }
}

@keyframes topMove2 {
  0% {
    bottom: 68px;
  }

  50% {
    bottom: 94px;
  }

  100% {
    bottom: 68px;
  }
}

.right_cut_circle {
  position: absolute;
  top: -97px;
  right: -1;
  right: -182px;
  width: 369px;
  filter: blur(14px);
}

.benefit_section_circles {
  bottom: 0;
  position: absolute;
  width: 271px;
  /* filter: blur(15px); */
  left: -37px;
}

.float_circle {
  position: absolute;
  width: 20px;
  bottom: 179px;
  left: 197px;
  animation: topCircle 5s linear infinite;
}

.float_circle2 {
  position: absolute;
  width: 12px;
  bottom: 134px;
  left: 192px;
  animation: topCircle2 5s linear infinite;
}

@keyframes topCircle {
  0% {
    bottom: 179px;
    left: 197px;
  }

  50% {
    bottom: 148px;
    left: 228px;
  }

  100% {
    bottom: 179px;
    left: 197px;
  }
}

@keyframes topCircle2 {
  0% {
    bottom: 134px;
    left: 192px;
  }

  50% {
    bottom: 134px;
    left: 230px;
  }

  100% {
    bottom: 134px;
    left: 192px;
  }
}

.float_shadow {
  width: 30%;
  position: absolute;
  bottom: -50%;
  left: -8%;
  z-index: -1;
  display: none;
}

.real_world_adoption {
  padding: 2em 0em;
  padding-top: 10em;
  background: #fafafa;
}

.real_world_adoption_area {
  display: flex;
  grid-gap: 10px;
  grid-gap: 10px;
  /* grid-gap: 73px; */
  gap: 40px;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.real_world_adoption_area1 {
  width: 100%;
}

.real_world_adoption_area2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.real_world_adoption_area2_title {
  font-size: 48px;
  font-weight: 600;
}

.real_world_adoption_area2_para {
  font-size: 24px;
  color: #647069;
  font-weight: 300;
  width: 80%;
}

.how_it_works_section {
  padding: 4em 0em;
  position: relative;
}

/* .how_it_works_title::after {
  content: "";
  width: 100%;
  height: 2px;
  background: #000;
  position: absolute;
  bottom: 0;
  left: 0;
} */
.how_it_works_title_cont {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.how_it_works_area_cont1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: 100%;
  padding: 1em;
  width: 100%;
  position: relative;
}

.cont1_number {
  position: absolute;
  right: 0.1em;
  font-size: 13em;
  font-family: Mulish;
  top: -66px;
  font-weight: 900;
  z-index: -1;
  color: #f5f5f5;
}

.how_it_works_area_cont1_title {
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  color: #434343;
}

.how_it_works_section_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4em;
}

img.how_it_works_area_cont1_img {
  width: 50%;
  margin: auto;
}

a.visit_app_link_2 {
  font-size: 12px;
  font-weight: 500;
  margin-top: 1.5em;
}

.how_it_works_area_cont1_para {
  font-size: 14px;
  font-weight: 300;
  width: 60%;
}

.how_it_works_title {
  font-size: 44px;
  font-weight: 600;
  position: relative;
  color: #464646;
}

.how_it_works_para {
  width: 80%;
  text-align: center;
  font-size: 16px;
  color: #7c7c7c;
  font-weight: 300;
}

.how_it_works_area1 {
  display: flex;
  align-items: center;
  gap: 50px;
}

.stake-hero-btn1 {
  padding: 0em 1em;
  height: 50px;
  background: #ffffff;
  color: #22ad62;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  font-size: 13px;
  transition: 0.3s ease-in-out;
  border: none;
  display: flex;
  align-items: center;
  width: 150px;
  justify-content: center;
  font-weight: 600;
}

.blurDrop-token2 {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  opacity: 1;
}

.blurDrop-token3 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  opacity: 1;
}

.stake-hero-btns {
  display: flex;
  margin-top: 40px;
}

.stake-hero-btn1:hover {
  border: solid;
  border-color: #dd8aa1;
  background-color: #dd8aa1;
  border-width: 0.5px;
  color: #fff;
  transform: translate3d(0, -5px, 0);
  transition: 0.3s ease-in-out;
  box-shadow: 0px 4px 10px #d5d5d5;
}

.stake-hero-btn2 {
  margin-right: 15px;
  padding: 0em 1em;
  height: 50px;
  background: linear-gradient(to right, #84e4a3, #6cbb86, #5f7ad6);
  color: #ffff;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  font-size: 13px;
  transition: 0.3s ease-in-out;
  border: none;
  display: flex;
  align-items: center;
  width: 150px;
  justify-content: center;
}

.stake-hero-btn2:hover {
  background: #dd8aa1;
  color: #fff;
  transform: translate3d(0, -5px, 0);
  transition: 0.3s ease-in-out;
  box-shadow: 0px 4px 10px #d5d5d5;
}

.whyEgorasSection {
  padding: 4em 0em;
  position: relative;
  background: #fafafa;
}

.why_egoras_area {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.whyEgoras_heading {
  font-size: 48px;
  font-weight: 600;
}

.whyEgoras_body {
  display: flex;
  justify-content: space-between;
  /* gap: 20px; */
}

.whyEgoras_body > * {
  margin: 20px;
  margin-left: 0;
}

.whyEgoras_body_cont1 {
  padding: 2em;
  background: #fff;
  border: solid 1px #e9e8e8;
  border-radius: 10px;
  box-shadow: 0px 10px 20px #f0f0f0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;
  position: relative;
  overflow: hidden;
}

.abstract_right_card_bg {
  position: absolute;
  bottom: 0;
  width: 200px;
  right: 0;
}

.whyEgoras_body_cont1_img_image {
  width: 100%;
}

.whyEgoras_body_cont1_img {
  width: 80%;
  margin: auto;
}

.whyEgoras_body_cont1_text {
  font-size: 18px;
  font-weight: 300;
  color: #647069;
  font-size: 20px;
  color: #000;
}

/* .span2-txts {
  font-size: 38px;
  font-weight: 600;
  color: #22ad62;
  margin: 0;
}
.span3-txts {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
} */
.earning-section {
  padding: 2em 0em;
  /* padding-bottom: 8em; */
  position: relative;
  background: #fafafa;
  overflow: hidden;
  height: 61em;
}

.reduce-padding {
  padding: 4em 0;
}

.span-txts {
  width: 100%;
}

.abstract_left_section_bg {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 602px;
  transform: rotate(91deg);
}

.tokenDots {
  position: absolute;
  right: 0;
  top: -100px;
  border-top-left-radius: 100%;
  border-bottom-left-radius: 100%;
}

.blurDrop-token {
  position: absolute;
  width: 32%;
  right: 0;
  top: 0;
}

.gteggShape2a {
  position: absolute;
  left: 0;
  top: -130px;
  /* z-index: -2; */
}

.align {
  align-items: flex-end;
}

.key-features-heading {
  font-weight: 600;
  text-align: center;
  position: relative;
}

.nft-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 5;
}

.nft-area3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 5;
}

.nft-area2 {
  /* grid-gap: 20px; */
  /* gap: 20px; */
  align-items: flex-start;
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.nft-area3 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 5;
}

.nft_area2_stat_div_area {
  display: flex;
  align-items: center;
  margin-bottom: 3em;
  width: 100%;
  position: relative;
  z-index: 2;
  border: solid 1px #9b98a5;
  background: #0000003b;
  backdrop-filter: blur(11px);
  border-radius: 10px;
  overflow: hidden;
}

.nft_area2_stat_div_area_cont1 {
  display: flex;
  padding: 1.5em 1.5em;
  color: #fff;
  width: 100%;
  align-items: center;
  border-right: 1px #9b98a5 solid;
  background: #00000069;
}

.nft_area2_stat_div_area_cont1_icon_cont_stat_numbers {
  margin-left: 1em;
  display: flex;
  flex-direction: column;
}

.nft_area2_stat_div_area_cont1_icon_cont_stat_numbers_head {
  color: #fff;
}

.nft_area2_stat_div_area_cont1_icon_cont_stat_numbers_para {
  font-size: 26px;
}

.key-features-cards-area {
  display: flex;
  flex-direction: column;
  grid-row-gap: 30px;
  width: 100%;
  margin-top: 50px;
}

.key-features-cards-area-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.key-features-cards-area1a {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 32%;
  height: 200px;
  grid-row-gap: 10px;
  padding: 1em;
  border-radius: 10px;
  box-shadow: 0px 11px 15px #d9ebe1;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  position: relative;
  color: #fff;
  overflow: hidden;
}

.btc-card-fees-figure {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.btc-card-txt-weight {
  font-weight: 600;
}

.fees-figure {
  font-size: 12px;
  margin: 0;
}

.key_features_image {
  width: 100%;
}

.key_features_img {
  width: 100%;
}

.egoras_benefit_section {
  padding: 4em 0em;
  position: relative;
  background: #fafafa;
  overflow: hidden;
}

.key-features-cards-area1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30%;
  min-height: 500px;
  grid-row-gap: 20px;
  padding: 1.5em;
  border-radius: 10px;
  box-shadow: 0px 11px 15px #d9ebe1;
  cursor: pointer;
  transition: 0.1s ease-in-out;
  position: relative;
  overflow: hidden;
  height: 100%;
  border: solid 1px #ececec;
}

.egoras_benefit_section {
  padding: 4em 0em;
  position: relative;
  background: #fafafa;
}

.benefit_section_area {
  display: flex;
  gap: 20px;
  align-items: center;
  position: relative;
  z-index: 2;
}

.benefit_section_area1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* gap: 40px; */
}

.benefit_section_area1 > * {
  margin: 20px 0px;
}

.benefit_section_are2 {
  width: 100%;
}

.benefit_section_area1_layer1 {
  padding: 1em;
  border: solid 1px #e7e7e7;
  border-radius: 7px;
  background: #fff;
  width: 85%;
  /* box-shadow: 0px 10px 20px #e4e4e4; */
  font-size: 20px;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  /* flex-direction: column; */
  font-weight: 300;
}

.benefit_section_area1_layer1_icon {
  width: 50px;
}

.bridge_icon {
  width: 35px;
}

.benefit_section_are2_img {
  width: 100%;
}

.btc-color {
  background-image: linear-gradient(45deg, #f19c11, #eb7d41);
}

.btc-3d-icon {
  position: absolute;
  width: 85px;
  right: 10px;
  top: 10%;
  transition: 0.8s ease-in-out;
}

.eth-3d-icon {
  position: absolute;
  width: 85px;
  right: 10px;
  top: 10%;
  transition: 0.8s ease-in-out;
}

.egr-3d-icon {
  position: absolute;
  width: 118px;
  right: 10px;
  top: 10%;
  transition: 0.8s ease-in-out;
}

.key-features-cards-area1a:hover .btc-3d-icon {
  transform: scale(1.3);
  transition: 0.8s ease-in-out;
}

.key-features-cards-area1a:hover .eth-3d-icon {
  transform: scale(1.3);
  transition: 0.8s ease-in-out;
}

.key-features-cards-area1a:hover .egr-3d-icon {
  transform: scale(1.3);
  transition: 0.8s ease-in-out;
}

.key-features-cards-area1a:hover {
  transform: scale(1.03);
  transition: 0.3s ease-in-out;
}

.eth-color {
  background-image: linear-gradient(45deg, #4c69db, #a062ed);
}

.egr-color {
  background-image: linear-gradient(45deg, #33dc76, #31c221);
}

.key-features-cards-area1:hover .home-icon {
  box-shadow: 0px 6px 10px #00602a;
}

.key-features-cards-area1:hover {
  background: linear-gradient(to right, #84e4a3, #6cbb86, #5f7ad6);
  color: #fff;
  box-shadow: 0px 11px 15px #9ec7af;
  transform: translate3d(0, -5px, 0);
  transition: 0.3s ease-in-out;
}

.key-features-cards-area1-header {
  /* display: flex; */
  /* text-align: center; */
  font-size: 24px;
  font-weight: 600;
  width: 100%;
  line-height: 29px;
}

.key_features_txts {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.home-icon {
  font-size: 49px !important;
  margin-right: 17px;
  border: solid;
  border-radius: 100%;
  padding: 3px;
  border-width: 1px;
  border-color: #249f55;
  box-shadow: 0px 6px 10px #9bc7ae;
}

.key-features-cards-area1-para {
  font-size: 14px;
  font-weight: 300;
}

/* tokens section  end */

.join-nft-list {
  display: flex;
  flex-direction: column;
  /* grid-row-gap: 30px; */
  width: 100%;
}

.join-nft-list1 {
  display: flex;
  color: rgb(255, 255, 255);
  margin-bottom: 20px;
  justify-content: space-between;
  height: 179px;
  width: 100%;
  background: rgb(255 255 255);
  border-radius: 10px;
  padding: 10px 0px 0px 50px;
  box-shadow: 0px 8px 15px #b5d9c5;
}

.align {
  align-items: flex-start;
}

.font-sz {
  font-size: 22px;
}

.hide-bg {
  background: none !important;
  box-shadow: none !important;
}

.hide-bg:hover {
  box-shadow: none !important;
  color: #22ad62;
}

/* collateral assets section start */

.collateral-assets-section {
  padding: 0em 0em;
  padding-bottom: 2em;
  position: relative;
  background: #fbfbfb;
  overflow: hidden;
}

.all-categories {
  display: none;
}

.assets-cont-head-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.assets-header1 {
  padding: 0.5em 2em;
  border: solid;
  border-radius: 50px;
  border-width: 1px;
  cursor: pointer;
  border-color: #d7d7d7;
  font-weight: 500;
  background: none;
}

.assets-header2 {
  padding: 0.5em 2em;
  cursor: pointer;
  color: #ababab;
  background: none;
  border: none;
}

.search-input {
  width: 20%;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
}

.assets-header3 {
  border: solid;
  border-width: 1px;
  border-radius: 50px;
  padding: 0.5em 1em;
  padding-left: 1em;
  width: 100%;
  border-color: #adadad;
  font-size: 14px;
}

.assets-header3:focus-visible {
  border-color: #22ad62;
  outline: none;
}

.search-icon {
  position: absolute;
  right: -1px;
  color: grey;
  top: 0;
  font-size: 39px !important;
  bottom: auto;
  background: linear-gradient(to right, #84e4a3, #6cbb86, #5f7ad6);
  color: #fff;
  width: 43px !important;
  padding: 10px;
}

.assets-table {
  display: table;
}

.assets-table {
  box-sizing: border-box;
  margin: 0px auto;
  min-width: 0px;
  width: 100%;
  /* max-width: 1232px; */
  /* display: flex; */
  padding: 0px 5px;
  border-collapse: separate;
  border-spacing: 0px 9px;
  margin-top: 20px;
}

.per-day {
  font-size: 12px;
  color: #dd8aa1;
}

.assets-category-titles {
  display: table-header-group;
}

.assets-category-titles {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
}

.assets {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
}

.assets-category-titles-heading1 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  padding-left: 16px;
  padding-right: 16px;
  color: var(--theme-ui-colors-text-muted, #708390);
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  white-space: nowrap;
}

.assets-table-body {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  width: 100%;
}

.assets-category-row {
  box-sizing: border-box;
  margin: 0px 0px 16px;
  min-width: 0px;
  display: table-row;
  flex-direction: column;
  box-shadow: rgb(227 227 227) 0px 0px 6px;
  background: white;
  border-radius: 8px;
  transition: transform 0.2s ease-in-out 0s, box-shadow 0.2s ease-in-out 0s;
  cursor: pointer;
}

.assets-category-row:hover {
  box-shadow: rgb(0 0 0 / 15%) 0px 0px 10px;
  transform: scaleX(0.99);
}

.assets-category-data {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  padding: 16px;
  display: table-cell;
  -webkit-box-pack: justify;
  justify-content: space-between;
  white-space: nowrap;
  position: sticky;
  left: 0;
  background: inherit;
  z-index: 1;
}

.BuyerSellerDiv_body_div2_body {
  overflow: scroll;
}

.assets-category-titles-heading1.left {
  position: sticky;
  left: 0;
  background: #f2f2f2;
}

.dark .assets-category-titles-heading1.left {
  background: #1c1b2c;
}

.assets-category-data1 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  padding: 16px;
  display: table-cell;
  -webkit-box-pack: justify;
  justify-content: space-between;
  white-space: nowrap;
}

.assets-category-data1b {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  padding: 16px;
  display: table-cell;
  -webkit-box-pack: justify;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  white-space: nowrap;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.assets-category-data-last {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  padding: 16px;
  display: table-cell;
  justify-content: flex-end;
  height: 100%;
  text-align: right;
  white-space: nowrap;
}

.assets-category-data-last:last-child {
  border-radius: 0px 8px 8px 0px;
}

.assets-category-data:first-child {
  border-radius: 8px 0px 0px 8px;
}

.assets-data {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  align-items: center;
}

.assets-list-icon {
  box-sizing: border-box;
  margin: 0px 8px 0px 0px;
  min-width: 0px;
  vertical-align: sub;
  width: 24px;
  height: 24px;
}

.assets-data-name {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  color: #373737;
  font-weight: 500;
}

.assets-data-name-last {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  -webkit-box-flex: 1;
  flex-grow: 1;
  text-align: right;
}

.assets-collateralize-button {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-family: Inter, "Helvetica Neue", sans-serif;
  font-weight: 500;
  line-height: 1.5;
  font-size: 14px;
  color: #fff;
  white-space: nowrap;
  cursor: pointer;
  background: linear-gradient(to right, #84e4a3, #6cbb86, #5f7ad6);
  border-radius: 5px;
  padding: 8px 32px;
  display: inline-block;
  /* width: 100%; */
  text-align: center;
  /* max-width: 100%; */
  transition: 0.3s linear;
}

.assets-collateralize-button:hover {
  background: #5f5f5f;
  color: #fff;
  transform: translate3d(0, -5px, 0);
  transition: 0.3s ease-in-out;
  box-shadow: 0px 4px 10px #d5d5d5;
}

.getStartedSection {
  padding: 2em 0em;
  position: relative;
  overflow: hidden;
  background: #fafafa;
}

.getStarted_area {
  width: 100%;
  /* max-width: 980px; */
  flex-direction: column;
  grid-gap: 30px;
  gap: 30px;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 5;
  margin: auto;
  display: flex;
  background: #effbf4;
  padding: 3em;
  border-radius: 8px;
  border: solid 1px #c5e3d1;
  align-items: center;
  overflow: hidden;
  box-shadow: 0px 10px 20px #e2e2e2;
  text-align: center;
}

.getStarted_title {
  font-size: 40px;
  font-weight: 600;
}

.get_started_button {
  padding: 1em 3.5em;
  border-radius: 6px;
  border: none;
  background: linear-gradient(to right, #84e4a3, #6cbb86, #5f7ad6);
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  position: relative;
  z-index: 2;
}

.key_features_section_title {
  font-size: 28px;
  font-weight: 600;
  color: black;
}

.how_it_works_update_new_section {
  padding: 6em 0em;
  position: relative;
  background: #000;
  overflow: hidden;
  /* background-image: radial-gradient(
      at 83% 67%,
      rgb(22 21 33) 0,
      transparent 58%
    ),
    radial-gradient(at 67% 20%, hsl(262.32deg 30.08% 13.49%) 0, transparent 59%),
    radial-gradient(at 88% 35%, hsl(0deg 0% 0%) 0, transparent 50%),
    radial-gradient(at 31% 91%, hsl(255deg 30% 7.84%) 0, #0b0b12 52%),
    radial-gradient(at 27% 71%, hsl(247.5deg 21.3% 6.19%) 0, transparent 49%),
    radial-gradient(at 74% 89%, hsl(249.47deg 23.01% 52.7%) 0, transparent 51%),
    radial-gradient(at 53% 75%, hsl(258.95deg 18.78% 4.72%) 0, transparent 45%); */
}

.how_it_works_update_new_area {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

.how_it_works_update_new_area_1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.5em;
}

.how_it_works_update_new_area_1_title {
  color: #fff;
  font-size: 62px;
  font-weight: 600;
  width: 100%;
}

.how_it_works_update_new_area_1_tab {
  width: 100%;
  border: solid #343249;
  padding: 5px;
  border-radius: 50px;
  max-width: 500px;
  display: flex;
  align-items: center;
}

.how_it_works_update_new_area_1_tab_1_active {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background: linear-gradient(to right, #84e4a3, #6cbb86, #5f7ad6);
  border-radius: 50px;
  width: 100%;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
}

.how_it_works_update_new_area_1_tab_1 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  /* background: linear-gradient(to right, #84e4a3, #6cbb86, #5f7ad6); */
  border-radius: 50px;
  width: 100%;
  color: #8a889e;
  font-weight: 500;
  cursor: pointer;
}

.how_it_works_update_new_area_2 {
  display: flex;
  justify-content: space-between;
  animation: revealTab 0.5s ease-in-out;
}

@keyframes revealTab {
  0% {
    transform: translateY(25%);
    opacity: 0;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.how_it_works_update_new_area_2_cont1_border {
  margin-right: 30px;
  position: relative;
  overflow: hidden;
  border-radius: 25px;
  padding: 2px;
  width: 400px;
}

.how_it_works_update_new_area_2_cont1_border::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 5px solid transparent;
  border-image: linear-gradient(128deg, #22ad62, #5374d4) 1;
  border-radius: 10px;
  background-image: linear-gradient(128deg, #22ad62, #5374d4);
}

.how_it_works_update_new_area_2_cont1_border_div {
  padding: 3em 2em;
  display: flex;
  flex-direction: column;
  background: #000;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  text-align: center;
  border-radius: 24px;
  /* background-image: radial-gradient(
      at 83% 67%,
      rgb(22 21 33) 0,
      transparent 58%
    ),
    radial-gradient(at 67% 20%, hsl(262.32deg 30.08% 13.49%) 0, transparent 59%),
    radial-gradient(at 88% 35%, hsl(0deg 0% 0%) 0, transparent 50%),
    radial-gradient(at 31% 91%, hsl(255deg 30% 7.84%) 0, #0b0b12 52%),
    radial-gradient(at 27% 71%, hsl(247.5deg 21.3% 6.19%) 0, transparent 49%),
    radial-gradient(at 74% 89%, hsl(249.47deg 23.01% 52.7%) 0, transparent 51%),
    radial-gradient(at 53% 75%, hsl(258.95deg 18.78% 4.72%) 0, transparent 45%); */
}

.how_it_works_update_new_area_2_cont1_icon {
  margin-bottom: 2.5em;
}

.how_it_works_update_new_area_2_cont1_icon_img {
  width: 120px;
}

.how_it_works_update_new_area_2_cont1_title {
  font-size: 32px;
  color: #fff;
}

.how_it_works_update_new_area_2_cont1_paragraph {
  color: #706d80;
}

.martgptfeauturesSection {
  padding: 4em 0em;
  position: relative;
  background: #000;
  overflow: hidden;
}

.martgptfeauturesSection_area {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

.martgptfeauturesSection_area1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3.5em;
}

.martgptfeauturesSection_area1_cont1 {
  color: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.martgptfeauturesSection_area1_cont2 {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.martgptfeauturesSection_area1_cont2_btn {
  width: 100%;
  max-width: 170px;
  padding: 13px;
  border-radius: 15px;
  border: none;
  background: linear-gradient(to right, #84e4a3, #6cbb86, #5f7ad6);
  color: #fff;
  font-weight: 500;
  font-size: 14px;
}

.martgptfeauturesSection_area1_cont1_title {
  color: #fff;
  font-size: 55px;
  font-weight: 600;
  width: 100%;
  line-height: 1;
  margin-bottom: 15px;
}

.martgptfeauturesSection_area1_cont1_para {
  width: 100%;
  max-width: 80%;
  color: #9390a7;
  font-size: 18px;
}

.martgptfeauturesSection_area2 {
  display: flex;
  flex-direction: column;
}

.martgptfeauturesSection_area2_cont1 {
  margin-bottom: 20px;
  display: flex;
}

.martgptfeauturesSection_area2_cont2 {
  margin-bottom: 2em;
  display: flex;
}

.martgptfeauturesSection_area2_cont1_div1 {
  position: relative;
  width: 100%;
  padding: 2em;
  background: #000;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  height: 100%;
  background-image: radial-gradient(
      at 83% 67%,
      rgb(22 21 33) 0,
      transparent 58%
    ),
    radial-gradient(at 67% 20%, hsl(262.32deg 30.08% 13.49%) 0, transparent 59%),
    radial-gradient(at 88% 35%, hsl(0deg 0% 0%) 0, transparent 50%),
    radial-gradient(at 31% 91%, hsl(255deg 30% 7.84%) 0, #0b0b12 52%),
    radial-gradient(at 27% 71%, hsl(247.5deg 21.3% 6.19%) 0, transparent 49%),
    radial-gradient(at 74% 89%, hsl(249.47deg 23.01% 52.7%) 0, transparent 51%),
    radial-gradient(at 53% 75%, hsl(258.95deg 18.78% 4.72%) 0, transparent 45%);
}

.martgptfeauturesSection_area2_cont1_div1_icon {
  width: 100%;
  margin-bottom: 20px;
  width: 63%;
}

.martgptfeauturesSection_area2_cont1_div1_title {
  font-size: 24px;
  color: #fff;
  margin-bottom: 10px;
}

.martgptfeauturesSection_area2_cont1_div1_para {
  color: #8d8aa1;
}

.martgptfeauturesSection_area2_cont1_div2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.martgptfeauturesSection_area2_cont1_div2b {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.martgptfeauturesSection_area2_cont1_div2_cont1 {
  position: relative;
  margin-bottom: 0;
  /* width: 40%; */
  padding: 2em;
  background: #000;
  border-radius: 20px;
  display: flex;
  align-items: center;
  z-index: 1;
  background-image: radial-gradient(
      at 83% 67%,
      rgb(22 21 33) 0,
      transparent 58%
    ),
    radial-gradient(at 67% 20%, hsl(262.32deg 30.08% 13.49%) 0, transparent 59%),
    radial-gradient(at 88% 35%, hsl(0deg 0% 0%) 0, transparent 50%),
    radial-gradient(at 31% 91%, hsl(255deg 30% 7.84%) 0, #0b0b12 52%),
    radial-gradient(at 27% 71%, hsl(247.5deg 21.3% 6.19%) 0, transparent 49%),
    radial-gradient(at 74% 89%, hsl(249.47deg 23.01% 52.7%) 0, transparent 51%),
    radial-gradient(at 53% 75%, hsl(258.95deg 18.78% 4.72%) 0, transparent 45%);
}

.martgptfeauturesSection_area2_cont1_div2_cont1_last {
  position: relative;
  padding: 2em;
  background: #000;
  border-radius: 20px;
  display: flex;
  align-items: center;
  z-index: 1;
  background-image: radial-gradient(
      at 83% 67%,
      rgb(22 21 33) 0,
      transparent 58%
    ),
    radial-gradient(at 67% 20%, hsl(262.32deg 30.08% 13.49%) 0, transparent 59%),
    radial-gradient(at 88% 35%, hsl(0deg 0% 0%) 0, transparent 50%),
    radial-gradient(at 31% 91%, hsl(255deg 30% 7.84%) 0, #0b0b12 52%),
    radial-gradient(at 27% 71%, hsl(247.5deg 21.3% 6.19%) 0, transparent 49%),
    radial-gradient(at 74% 89%, hsl(249.47deg 23.01% 52.7%) 0, transparent 51%),
    radial-gradient(at 53% 75%, hsl(258.95deg 18.78% 4.72%) 0, transparent 45%);
}

.martgptfeauturesSection_area2_cont1_div2_cont1_div1 {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.martgptfeauturesSection_area2_cont1_div2_cont1_icon {
  margin-left: 25px;
  width: 200px;
}

.martgptfeauturesSection_area2_cont1_div2_cont1_iconb {
  margin-right: 25px;
  width: 200px;
}

.martgptfeauturesSection_area2_cont1_div2_cont1_icon_img {
  width: 100%;
}

.martgptfeauturesSection_area2_cont1_div2_cont1_icon_img2 {
  width: 80%;
}

.martgptfeauturesSection_area2_cont1_div2_cont1_title {
  font-size: 24px;
  color: #fff;
  margin-bottom: 10px;
}

.martgptfeauturesSection_area2_cont1_div2_cont1_para {
  color: #8d8aa1;
}

.martgptfeauturesSection_area2_cont1_div2_cont1_border {
  position: relative;
  overflow: hidden;
  border-radius: 25px;
  padding: 3px;
  margin-bottom: 20px;
}

.martgptfeauturesSection_area2_cont1_div2_cont1_border::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 5px solid transparent;
  border-image: linear-gradient(128deg, #22ad62, #5374d4) 1;
  border-radius: 10px;
  background-image: linear-gradient(128deg, #22ad62, #5374d4);
}

.martgptfeauturesSection_area2_cont1_div2_cont1_border_last {
  position: relative;
  overflow: hidden;
  border-radius: 25px;
  padding: 3px;
}

.martgptfeauturesSection_area2_cont1_div2_cont1_border_last::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 5px solid transparent;
  border-image: linear-gradient(128deg, #22ad62, #5374d4) 1;
  border-radius: 10px;
  background-image: linear-gradient(128deg, #22ad62, #5374d4);
}

.martgptfeauturesSection_area2_cont1_div2_cont1_border_first {
  position: relative;
  overflow: hidden;
  border-radius: 25px;
  padding: 3px;
  width: 40%;
}

.martgptfeauturesSection_area2_cont1_div2_cont1_border_first::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 5px solid transparent;
  border-image: linear-gradient(128deg, #22ad62, #5374d4) 1;
  border-radius: 10px;
  background-image: linear-gradient(128deg, #22ad62, #5374d4);
}

.JoinMartGptSection {
  padding: 4em 0em;
  position: relative;
  background: #000;
  overflow: hidden;
}

.JoinMartGptArea {
  display: flex;
  padding: 0em;
  border-radius: 25px;
  border: solid 1px #322845;
  background-image: radial-gradient(
      at 83% 67%,
      rgb(39 25 47) 0,
      transparent 58%
    ),
    radial-gradient(at 67% 20%, hsl(262.32deg 46.67% 17.22%) 0, transparent 59%),
    radial-gradient(at 88% 35%, hsl(0deg 0% 0%) 0, transparent 50%),
    radial-gradient(at 31% 91%, hsl(270deg 26.52% 3.14%) 0, #1c1326d4 52%),
    radial-gradient(at 27% 71%, hsl(264.77deg 35.35% 23.62%) 0, transparent 49%),
    radial-gradient(at 74% 89%, hsl(250deg 100% 60.01%) 0, transparent 51%),
    radial-gradient(at 53% 75%, hsl(256.7deg 100% 68.59%) 0, transparent 45%);
  align-items: center;
}

.JoinMartGptArea_1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 0px;
  padding: 4em;
}

.JoinMartGptArea_1_title {
  font-size: 100px;
  font-weight: 600;
  color: #fff;
  line-height: 1;
}

.JoinMartGptArea_1_para {
  font-size: 24px;
  color: #66637a;
}

.JoinMartGptArea_1_btn_div {
  margin-top: 2em;
}

.JoinMartGptArea_1_btn {
  width: 100%;
  max-width: 200px;
  padding: 15px;
  border-radius: 15px;
  border: none;
  background: linear-gradient(to right, #84e4a3, #6cbb86, #5f7ad6);
  color: #fff;
  font-weight: 500;
  box-shadow: 0px 10px 20px #29233c;
}

.JoinMartGptArea_2 {
  width: 100%;
}

.JoinMartGptArea_2_img {
  width: 100%;
}

.martgptfeauturesSection_bg_img {
  width: 100%;
  position: absolute;
  bottom: 0;
  mix-blend-mode: screen;
}

.how_it_works_update_new_section_bg_left {
  position: absolute;
  top: 0;
  left: 0;
  width: 550px;
}

.community_section {
  padding: 4em 0em;
  position: relative;
  /* background: #151a17; */
  background: #000;
}
.community_section_area {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.community_section_area1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #fff;
  font-size: 74px;
  font-weight: 600;
}
.community_section_area1_para {
  font-size: 24px;
  font-weight: 400;
  color: #888e8b;
}
.community_section_area2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 2em;
}
/* collateral assets section end */
/* ============================================ */
/* ============================================ */
/* ============================================ */
/* ============================== */
/* ============================== */
/* ============================== */
/* ============================== */
/* ============================== */
/* ============================== */
/* ============================== */
/* ============================== */
/* ============================== */
/* ============================== */
/* ============================== */
/* ============================== */
/* ============================== */
/* ============================== */
/* ============================== */
/* ============================== */
/* ============================== */
/* ============================== */
/* ============================== */
/* ============================== */
/* ============================== */
@media screen and (max-width: 1399px) {
  /* .liquidity_hero_center_asset_display_bg {
    left: 39%;
  }
  .liquidity_hero_center_turning_bg {
    left: 36%;
  } */
}

@media screen and (max-width: 991px) {
  .stake-banner-blue {
    text-align: center;
    grid-row-gap: 20px;
  }

  .benefit_section_area1 > * {
    margin: 10px 0px;
  }

  .dark .hero_backdrop {
    position: absolute;
    bottom: 0%;
    width: 133%;
    z-index: 1;
    margin: auto;
    left: auto;
    right: -16%;
  }

  .hero_backdrop {
    position: absolute;
    bottom: 0%;
    width: 133%;
    z-index: 1;
    margin: auto;
    left: auto;
    right: -16%;
  }

  .nft_area2_stat_div_area {
    display: grid;
    align-items: center;
    margin-bottom: 0em;
    grid-gap: 10px;
    grid-gap: 10px;
    gap: 10px;
    width: 100%;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    justify-content: flex-start;
  }

  .how_it_works_update_new_area_2 {
    flex-direction: column;
  }

  .how_it_works_update_new_area_2_cont1_border {
    margin-right: 0px;
    width: 100%;
    margin-bottom: 30px;
  }

  .martgptfeauturesSection_area2_cont1 {
    flex-direction: column;
  }

  .martgptfeauturesSection_area2_cont1_div2_cont1_border_first {
    width: 100%;
  }

  .martgptfeauturesSection_area2_cont1_div1_icon {
    margin-bottom: 20px;
    width: 25%;
  }

  .martgptfeauturesSection_area2_cont1_div2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    margin-top: 20px;
  }

  .martgptfeauturesSection_area2_cont2 {
    margin-bottom: 2em;
    display: flex;
    flex-direction: column;
  }

  .JoinMartGptArea {
    align-items: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .JoinMartGptArea_1 {
    margin-right: 0px;
    padding: 3em;
  }

  .JoinMartGptArea_1_title {
    font-size: 72px;
  }

  .JoinMartGptArea_1_para {
    font-size: 20px;
  }

  .JoinMartGptArea_1_btn_div {
    margin-top: 1em;
  }

  .JoinMartGptArea_2 {
    width: 55%;
  }

  .how_it_works2_area_body {
    display: flex;
    flex-direction: column;
  }
  .how_it_works2_area_body_area2 {
    width: 100%;
    margin: 0px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .community_section_area1_title {
    font-size: 48px;
    line-height: 1.2;
    margin-bottom: 10px;
  }
  .community_section_area1_para {
    font-size: 20px;
  }
  .community_section {
    padding: 2em 0em;
  }
  .how_it_works2_area_head {
    font-size: 42px;
    width: 100%;
    margin-bottom: 1em;
  }
  .how_it_works2_area_body_area2_img {
    width: 60%;
  }
}

@media screen and (max-width: 900px) {
  .nft-img-area2 {
    display: none !important;
  }

  .floating_absolute_div {
    bottom: -142px;
  }

  /* collateral assets section start */
  .assets-cont-head-area {
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
    grid-row-gap: 20px;
  }

  .search-input {
    width: 100%;
  }

  .float_cube1 {
    position: absolute;
    width: 90px;
    bottom: 0;
    z-index: 2;
    animation: topMove 5s linear infinite;
  }

  .float_cube2 {
    position: absolute;
    /* width: 200px; */
    width: 90px;
    bottom: 68px;
    left: 45px;
    animation: topMove2 5s linear infinite;
  }

  .stable-content::before {
    font-family: Inter, "Helvetica Neue", sans-serif;
    font-weight: 600;
    line-height: 1.5;
    font-size: 14px;
    color: var(--theme-ui-colors-text-muted, #708390);
    content: "Stable Fee.";
    display: block;
  }

  .ratio-content::before {
    font-family: Inter, "Helvetica Neue", sans-serif;
    font-weight: 600;
    line-height: 1.5;
    font-size: 14px;
    color: var(--theme-ui-colors-text-muted, #708390);
    content: "Min Coll.Ratio.";
    display: block;
  }

  /* collateral assets section end */
  /* ====================== */
  /* ====================== */
  /* ====================== */
  /* ====================== */

  .how_it_works_area1 {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-direction: column;
  }

  .how_it_works_area_cont1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .cont1_number {
    display: none;
  }

  .how_it_works_section_div {
    gap: 2em;
  }
}

@media screen and (max-width: 768px) {
  .whyEgorasSection {
    padding: 2em 0em;
    position: relative;
    background: #fafafa;
  }
}

@media screen and (max-width: 767px) {
  .whyEgoras_body {
    display: flex;
    justify-content: space-between;
    grid-gap: 20px;
    gap: 20px;
    flex-direction: column;
  }

  .floating_div_cont_area {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .benefit_section_area {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    align-items: center;
    flex-direction: column;
  }

  .benefit_section_area1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 40px;
    gap: 10px;
  }

  .floating_div_cont_area1 {
    text-align: center;
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
    padding: 2em 3em;
    width: 100%;
    align-items: flex-start;
  }

  .floating_absolute_div {
    bottom: -190px;
  }

  .floating_div_cont_area1_cont1 {
    font-size: 14px;
    color: #7c7c7c;
  }

  .real_world_adoption {
    padding-top: 12em;
  }

  .floating_div_cont_area1_cont2 {
    font-size: 32px;
    font-weight: 600;
  }

  .vertical_rule2a {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    width: 0% !important;
    align-self: stretch;
    border-bottom: 1px solid rgb(238, 238, 238);
    width: 100% !important;
    /* height: 10px; */
  }

  .dark .vertical_rule2a {
    border-bottom: 1px solid rgb(93 121 104);
  }

  .benefit_section_area1_layer1 {
    padding: 1em;
    border: solid 1px #dadada;
    border-radius: 10px;
    background: #fff;
    width: 100%;
    box-shadow: 0px 10px 20px #e4e4e4;
  }

  .stats-widget {
    margin: 20px 0px 0px 0px;
  }

  .stake-banner-blue {
    text-align: center;
    grid-row-gap: 20px;
  }

  .stake-hero-btns {
    justify-content: flex-start;
    /* flex-direction: column; */
    gap: 12px;
  }

  .nft-area {
    flex-direction: column;
    grid-row-gap: 20px;
  }

  .flex_rev_me {
    flex-direction: column-reverse;
  }

  .nft-area2 {
    flex-direction: column;
    grid-row-gap: 20px;
    padding: 15px;
  }

  .earning-section {
    height: 55em;
  }

  .span4b-txts {
    width: 100%;
  }

  .nft_area2_stat_div_area {
    display: grid;
    align-items: center;
    margin-bottom: 0em;
    grid-gap: 10px;
    gap: 10px;
    width: 100%;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    justify-content: flex-start;
  }

  .nft_area2_stat_div_area_cont1 {
    padding: 1em 1em;
    width: 100%;
    margin-right: 0em;
  }

  .nft-area3 {
    flex-direction: column;
    grid-row-gap: 20px;
  }

  .nft-txt-area {
    text-align: center;
  }

  .span-txts {
    text-align: center;
  }

  .span2-txts {
    font-size: 33px;
  }

  .span4b-txts {
    width: 100%;
  }

  .span4a-txts {
    font-size: 64px;
  }

  .key-features-cards-area-flex {
    flex-direction: column;
    grid-row-gap: 30px;
  }

  .key-features-cards-area1a {
    width: 100%;
  }

  .key-features-cards-area1 {
    width: 100%;
  }

  .second-eusd-token-section {
    padding: 0em;
    padding-bottom: 2em;
  }

  .assets-category-data-last {
    padding: 5px;
  }

  .assets-category-data {
    padding: 5px;
  }

  .assets-category-data1b {
    padding: 5px;
  }

  .how_it_works_update_new_area_1 {
    align-items: center;
    margin-bottom: 2em;
    flex-direction: column;
    text-align: center;
  }

  .how_it_works_update_new_area_1_title {
    font-size: 36px;
  }

  .how_it_works_update_new_area_1_tab_1_active {
    padding: 10px;
  }

  .how_it_works_update_new_area_1_tab_1 {
    padding: 10px;
  }

  .how_it_works_update_new_section {
    padding: 3em 0em;
  }

  .how_it_works_update_new_area_2_cont1_border_div {
    padding: 2em 2em;
  }

  .how_it_works_update_new_area_2_cont1_icon {
    margin-bottom: 1.5em;
  }

  .how_it_works_update_new_area_2_cont1_icon_img {
    width: 100px;
  }

  .martgptfeauturesSection_area1 {
    align-items: flex-start;
    margin-bottom: 2.5em;
    flex-direction: column;
  }

  .martgptfeauturesSection_area1_cont1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    text-align: center;
  }

  .martgptfeauturesSection_area1_cont1_title {
    font-size: 36px;
  }

  .martgptfeauturesSection_area1_cont1_para {
    width: 100%;
    max-width: 100%;
  }

  .martgptfeauturesSection_area1_cont2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1em;
  }

  .martgptfeauturesSection_area1_cont2_btn {
    width: 100%;
    max-width: 200px;
  }

  .martgptfeauturesSection_area2_cont1_div1_icon {
    margin-bottom: 20px;
    width: 30%;
  }

  .martgptfeauturesSection_area2_cont1_div2_cont1_icon_img2 {
    width: 100%;
  }

  .martgptfeauturesSection_area2_cont1_div2_cont1 {
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
  }

  .martgptfeauturesSection_area2_cont1_div2_cont1_last {
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
  }

  .martgptfeauturesSection_area2_cont1_div2_cont1_icon {
    margin-left: 0px;
    width: 30%;
    margin-bottom: 25px;
  }

  .martgptfeauturesSection {
    padding: 3em 0em;
  }

  .martgptfeauturesSection_area2_cont1_div1 {
    align-items: center;
    text-align: center;
  }

  .JoinMartGptSection {
    padding: 3em 0em;
  }

  .JoinMartGptArea_1 {
    margin-right: 0px;
    padding: 2em;
  }

  .JoinMartGptArea_1_title {
    font-size: 42px;
  }

  .JoinMartGptArea_1_para {
    font-size: 18px;
  }

  .JoinMartGptArea_2 {
    width: 80%;
  }

  .nft-txt-area2 {
    margin-top: 0em;
  }

  .nft-area2 {
    padding: 0px;
  }

  .dark .hero_backdrop {
    width: 130%;
  }

  .hero_backdrop {
    width: 130%;
  }
}

@media screen and (max-width: 610px) {
  .span4a-txts {
    font-size: 48px;
    width: 100%;
    font-weight: 600;
    line-height: 44px;
  }

  .earning-section {
    height: 46em;
  }
}

@media screen and (max-width: 600px) {
  .assets-header2 {
    padding: 0.5em 0.5em;
    font-size: 12px;
  }

  .assets-header1 {
    padding: 0.5em 0.5em;
    font-size: 12px;
  }

  .getStartedSection {
    padding: 2em 0em;

    position: relative;
    overflow: hidden;
  }

  .getStarted_title {
    font-size: 32px;
    font-weight: 600;
  }

  .get_started_button {
    padding: 1em 3.5em;
    border-radius: 6px;
    border: none;
    background: linear-gradient(to right, #84e4a3, #6cbb86, #5f7ad6);
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    position: relative;
    z-index: 2;
  }
}

@media screen and (max-width: 500px) {
  .real_world_adoption_area2_title {
    font-size: 32px;
    font-weight: 600;
  }

  .real_world_adoption_area2_para {
    font-size: 16px;
    color: #647069;
    font-weight: 300;
    width: 80%;
  }

  .whyEgoras_heading {
    font-size: 32px;
    font-weight: 600;
  }

  .floating_absolute_div {
    position: absolute;
    width: 100%;
    /* padding: 2em; */
    background: #fff;
    box-shadow: 0px 0px 25px #e4f3ea;
    z-index: 10;
    max-width: 1280px;
    margin: auto;
    left: 0;
    right: 0;
    border-radius: 10px;
    border: solid 1px #d6e2db;
    bottom: -54px;
  }

  .real_world_adoption {
    padding: 2em 0em;
    padding-top: 6em;
    background: #fafafa;
  }

  .egoras_benefit_section {
    padding: 2em 0em;
    position: relative;
    background: #fafafa;
  }

  .floating_div_cont_area1 {
    text-align: center;
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    grid-gap: 5px;
    grid-gap: 5px;
    grid-gap: 5px;
    gap: 5px;
    padding: 2em 2em;
    width: 100%;
    align-items: flex-start;
  }

  .benefit_section_are2 {
    width: 80%;
  }

  .benefit_section_area1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 40px;
    gap: 16px;
  }

  .stake-hero-btn1 {
    padding: 2em 1em;
    font-size: 12px;
    text-align: center;
  }

  .stake-hero-btns {
    display: flex;
    margin-top: 27px;
  }

  .stake-hero-btn2 {
    padding: 2em 1em;
    font-size: 12px;
    margin: 0;
    text-align: center;
  }

  .floating_div_cont_area1_cont1 {
    font-size: 14px;
    color: #7c7c7c;
  }

  .floating_div_cont_area1_cont2 {
    font-size: 26px;
    font-weight: 600;
  }

  .span4a-txts {
    font-size: 42px;
    width: 100%;
    font-weight: 600;
    line-height: 44px;
  }

  .key-features-cards-area1 {
    min-height: 390px;
  }

  .key_features_img {
    width: 75%;
  }

  .how_it_works_section {
    padding: 2em 0em;
    position: relative;
  }

  .how_it_works_para {
    width: 100%;
  }

  .how_it_works_area_cont1_para {
    font-size: 14px;
    font-weight: 300;
    width: 90%;
  }

  .how_it_works_area1 {
    gap: 0px;
  }

  .span4b-txts {
    font-size: 15px;
  }

  .whyEgoras_body_cont1 {
    padding: 1em;
    background: #fff;
    border: solid 1px #e9e8e8;
    border-radius: 10px;
    box-shadow: 0px 10px 20px #f0f0f0;
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 50px;
    gap: 50px;
    position: relative;
    overflow: hidden;
  }

  .whyEgoras_body_cont1_text {
    font-size: 18px;
    font-weight: 300;
    color: #647069;
    font-size: 16px;
    color: #000;
  }

  .whyEgoras_body_cont1_img {
    width: 70%;
    margin: auto;
  }

  .benefit_section_area1_layer1 {
    padding: 1em;
    border: solid 1px #dadada;
    border-radius: 5px;
    background: #fff;
    width: 100%;
    box-shadow: 0px 10px 20px #e4e4e4;
    font-size: 16px;
  }

  .benefit_section_are2 {
    width: 70%;
  }

  /* .nft_area2_stat_div_area_cont1_icon {
    width: 30px;
  } */

  .nft_area2_stat_div_area_cont1_icon_light {
    width: 30px;
  }

  .nft_area2_stat_div_area_cont1_icon_dark {
    width: 30px;
  }

  .nft_area2_stat_div_area_cont1_icon_cont_stat_numbers_head {
    color: #fff;
    font-size: 12px;
  }

  .nft_area2_stat_div_area_cont1_icon_cont_stat_numbers_para {
    font-size: 18px;
  }

  .nft_area2_stat_div_area_cont1_icon_cont_stat_numbers {
    margin-left: 0.5em;
  }

  .nft_area2_stat_div_area_cont1 {
    padding: 0.5em 0.5em;
    width: 100%;
    margin-right: 0em;
  }

  .earning-section {
    height: 37em;
  }
}

@media screen and (max-width: 449px) {
  .tokenCardflex-txt {
    width: 100%;
    font-size: 24px;
    font-weight: 600;
  }
}

@media screen and (max-width: 390px) {
  .tokenCardflex-txt {
    font-size: 20px;
  }

  .span4a-txts {
    font-size: 42px;
    width: 100%;
    font-weight: 600;
    line-height: 44px;
  }
}

/* =================================== */
/* =================================== */
/* =================================== */
/* =================================== */

/* Dark Mode Styles */
/* =================================== */
/* =================================== */
/* =================================== */

.dark .earning-section {
  background: #000;
}

.dark .span4a-txts {
  color: #fff;
}

.dark .span4b-txts {
  color: #bcc7c0;
}

.dark .stake-hero-btn1 {
  border-color: #ffffff;
  color: #22ad62 !important;
  background: #fff;
}

.dark .floating_absolute_div {
  background: #232326;
  box-shadow: 0px 0px 25px #000000;

  border: solid 1px #000;
}

.dark .floating_div_cont_area1_cont1 {
  color: #ffffff;
}

.dark .whyEgorasSection {
  background: #000;
}

.dark .whyEgoras_body_cont1 {
  background: #232326;
  border: solid 1px #475d50;

  box-shadow: 0px 10px 20px #000000;
}

.dark .whyEgoras_heading {
  color: #fff;
}

.dark .whyEgoras_body_cont1_text {
  color: #ffffff;
}

.dark .floating_div_cont_area1_cont2 {
  color: #fff;
}

.dark .real_world_adoption {
  background: #000;
}

.dark .real_world_adoption_area2_title {
  color: #fff;
}

.dark .real_world_adoption_area2_para {
  color: #bbcec3;
}

.dark .getStartedSection {
  background: #000;
}

.dark .getStarted_area {
  background: #232326;
  border: solid 1px #38383d;
  box-shadow: 0px 10px 20px #000000;
}

.dark .getStarted_title {
  color: #fff;
}

.dark .egoras_benefit_section {
  background: #000;
}

.dark .benefit_section_area1_layer1 {
  border: solid 1px #38383d;

  background: #232326;

  color: #fff;
  box-shadow: 0px 10px 20px #000000;
}

.dark .sidebar {
  background-color: #232326;
}

.dark .DashBoardHeaderSection {
  background-color: #121213;
  /* -webkit-backdrop-filter: blur(6px); */
  /* backdrop-filter: blur(6px); */
  border-bottom: solid 1px #252529;
  box-shadow: 0px 5px 9px #000000;
}

.dark .sidebarListItem {
  color: #c0e8d0;
}

.dark hr.hrr {
  color: #fff;
}

.dark .list-item-active {
  color: #ffffff;
  background: #000;
}

.dark .lending_area1_cont1 {
  background: #232326;
  border: solid 1px #313135;
  box-shadow: 0px 10px 20px #000000;
  color: #fff;
}

.dark .lending_area1_cont1_user {
  background: #1c1b2c;
  border: solid 1px #313135;
  /* box-shadow: 0px 10px 20px #000000; */
  color: #fff;
}

.dark .lending_area1_cont1_heading {
  font-size: 13px;
  color: #85a393;
}

.dark .help_outline {
  color: #ffffff !important;
}

.dark .lending_area1_last_cont1_divs_cont1 {
  color: #e2e2e2;
}

.dark .lending_area1_last_cont1_divs_cont3 {
  color: #e6e6e6;
}

.dark .lending_area1_last_cont1_divs_cont_value {
  color: #fff;
}

.dark .table_body {
  border: solid 1px #38383d;
}

.dark .assets-data-name {
  color: #ffffff;
}

.dark .filter_table_area_1 .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  color: #ffffff !important;
  background: #232326 !important;
  box-shadow: 0px 2px 4px #000000 !important;
  border-color: #38383d !important;
}

.dark .filter_table_area_1 .css-1kty9di-MuiFormLabel-root-MuiInputLabel-root {
  color: #ffffff !important;
}

.dark .assets-category-titles-heading1 {
  color: #d4d4d4;
}

.dark .assets-category-row {
  box-shadow: rgb(0 0 0) 0px 0px 6px;
  background: #232326;
  color: #fff;
}

.dark .assets-category-row:hover {
  background: #2a283c;
}

.dark .assets-data-pool_name {
  color: #ffffff;
  width: 360px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dark .poolName_txt {
  color: #cecece;
}

.dark .investmentcapacity_box {
  /* border: solid 1px white; */
  color: #ffffff;
}

.dark .assets-data-name_pool {
  color: #ffffff;
}

.dark .asset_symbol {
  color: #d6d6d6;
}

.dark .assets-data-name_pool {
  color: #fff !important;
}

.dark .assets-btn {
  border: solid 1px #ffffff;
  color: #ffffff;
}

.dark .welcome_user_txt {
  color: #ffffff;
}

.dark .menu_icon_toggle {
  color: #fff;
}

.dark .filter_table_area_1 .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: rgb(255 255 255 / 60%);
}

.dark .small_list-item-active {
  color: #232326;
  background: #ffffff;

  box-shadow: 0px 0px 5px #39493f;
}

.dark .hold_On_div {
  background: #000;
}

.dark .pool_lending_pages_links {
  background: #232326;
  border: solid 1px #38383d;
}

.dark .pool_lend_details_link_active {
  background: #121213;
  border-bottom: solid 1px #232326;
  color: #fff;
}

.dark .pool_lend_details_link {
  color: #8b9b91;
}

.dark .pool_lend_details_link .asset_overview_link_icon {
  background: #d5e1e9;
  color: #232326;
}

.dark .pool_detail_heading {
  color: #fff;
}

.dark .pool_detail_sub_area1 {
  background: #232326;
  border: solid 1px #38383d;
  color: #fff;
}

.dark .pool_detail_sub_area1_area1_cont2 {
  color: #e1e1e1;
}

.dark .Asset_Originator_Details_cont_heading {
  color: #fff;
}

.dark .Asset_Originator_Details_cont_body {
  background: #232326;
  border: solid 1px #38383d;
  color: #fff;
}

.dark .Asset_Originator_Details_cont_body_issuer_cont_head {
  color: #e8e8e8;
}

.dark .pool_status_cont_heading {
  color: #fff;
}

.dark .pool_status_Details_cont_body1 {
  border: solid 1px #38383d;
  background: #232326;

  color: #fff;
}

.dark .pool_status_Details_cont_body1_sub_conts {
  color: #e7e7e7;
}

.dark .liquidity_cont {
  background: #232326;
  box-shadow: 0px 10px 20px #000;
  border: solid 1px #313135;
}

.dark .amnt_input {
  /* border: solid 1px #293d32; */
  background: #121213;
}

.dark .connect_btn:disabled {
  background: #85ab95;
  color: #4e6959;
}

.dark .input_txt {
  color: #fff;
}

.dark .plus_icon_layer {
  color: #ffffff;
}

.dark .amnt_input_field {
  color: #8fb5a0;
}

.dark .connect_btn {
  background: #ddf2e5;
  color: #26392d;
}

.dark .display_tokens_drop {
  background: #2e2e31;
  box-shadow: 0px 5px 15px #000;
  color: #fff;
}

.dark .pool_detail_assets_body_layer_1_cont1 {
  background: #232326;
  border: solid 1px #38383d;
  color: #fff;
}

.dark .pool_detail_assets_body_layer_1_cont1_sub_heading_1b {
  color: #d8d8d8;
}

.dark .asset_list_heading {
  color: #fff;
}

.dark .toggle_dark_mode_div {
  background: #000;
  border: solid 1px #213129;
}

/* .dark .connected_header_address {
  border: solid 1px #64796d;
  border-radius: 6px;
  color: #fff;
  background: #000;
} */
.dark .wallet_addr_cont_txt_header {
  background: #000;
  color: #ffffff;
  border: solid 1px #213129;
  border-left: 0;
}

.dark .wallet_addr_cont_txt_header_vip {
  background: #000;
  color: #ffffff;
  border: solid 1px #f0b400;
  border-left: 0;
}

.dark .wallet_settings_icon_cont {
  background: #000;
  color: #ffffff;
  border: solid 1px #213129;
}

.dark .thumbnail_img2 {
  display: block;
}

.thumbnail_img2 {
  display: none;
}

.dark .thumbnail_img {
  display: none;
}

.dark .vertical_rule {
  border-color: rgb(93 121 104);
}

.dark .vertical_rule2a {
  border-color: rgb(93 121 104);
}

.dark .metamask_prof_pic_icon {
  background: #000;
  border: solid 1px #213129;
  border-right: 0;
}

.dark .metamask_prof_pic_icon_vip {
  background: #000;
  border: solid 1px #f0b400;
  border-right: 0;
}

.dark .disconnect_button_div {
  background: #000;

  box-shadow: 0px 0px 10px #000000;

  border: solid 1px #313136;
}

.dark .logout-btn {
  box-shadow: 0px 5px 13px #000000;
}

.dark .disconnect_button_div:before {
  border-bottom: 10px solid #000;
}

.dark .filter_table_area_2 {
  border: solid 1px #38383d;
  background: #232326;
  box-shadow: 0px 2px 6px #000000;
}

.dark .filter_table_btn1 {
  color: #ffffff;
}

.dark .filter_table_btn1_active {
  background: #ffffff;

  color: #000;
}

.dark .asset_list_body_head {
  color: white;
  background: #fff;
  border: solid 1px #38383d;
  background: #232326;
}

.dark .asset_list_body_body_cont_1 {
  background: #232326;
  border: solid 1px #38383d;
  box-shadow: 0px 0px 4px #000000;
  color: #fff;
}

.dark .asset_list_div {
  border: solid 1px #38383d;
}

.dark .Asset_Originator_Details_cont_body_txt span {
  background: #232326 !important;
  color: #fff !important;
}

.dark .asset_detail_modal_div_conts {
  background: #000;
  box-shadow: 0px 0px 20px #000;
}

.dark .asset_detail_heading {
  background: #232326;
  border: solid 1px #38383d;
  color: #fff;
}

.dark .asset_status_details_div1 {
  border: solid 1px #38383d;
  background: #232326;
  color: #fff;
}

.dark .transactionData_body {
  background: #232326;
  border: solid 1px #38383d;
  color: #fff;
}

.dark .transaction_id_link {
  color: #fff;
}

.dark a {
  color: #ffffff;
}

.dark .recent_transaction_body {
  border: solid 1px #38383d;
  color: #fff;
}

.dark .see_more_btn {
  background: #ffffff;
  color: #232326;
  box-shadow: 0px 5px 10px #000000;
}

.dark .user_details_body1_body_cont_area1 {
  background: #232326;
  border: solid 1px #38383d;
  color: #fff;
}

.dark .userdAshboard_head {
  background: #232326;
  border: solid 1px #38383d;
  color: #fff;
}

.dark .metamask_prof_pic {
  filter: drop-shadow(2px 2px 5px #000);
}

.dark .connected_txt {
  color: #e1e1e1;
}

.dark .reward_btn:disabled {
  background: #8fb9a1;
  box-shadow: 0px 10px 10px #232326;
  color: #232326;
}

.dark .pool_detail_heading_area2 span {
  color: #ffffff !important;
}

.dark .reward_btn {
  background: #ffffff;
  color: #232326;
  box-shadow: 0px 5px 10px #000000;
}

.dark .asset_list_modal_container {
  background: #000;
  box-shadow: 0px 0px 15px #000000;
  color: #fff;
}

.dark .asset_list_txt {
  color: #fff;
}

.dark .asset_list_body_head2 {
  border: solid 1px #38383d;
  background: #232326;
  color: #fff;
}

.dark .back_modal_cont {
  background: #232326;
  box-shadow: 0px 0px 20px #000;
  border: solid 1px #38383d;
  color: #fff;
}

.dark .back_modal_input {
  border: solid 1px #39433d;
  background: #26362c;
  box-shadow: 0px 3px 5px #000000;
  color: #fff;
}

.dark .back_modal_input_amnt_head {
  color: #e3e3e3;
}

.dark .amount_earned_mnthly {
  border: solid 1px #2f4a3a;
  background: #26362c;
  box-shadow: 0px 0px 6px #212f26;
  color: #bcbcbc;
}

.dark .amount_earned_mnthly_value {
  color: #fff;
}

.dark .back_loan_btn {
  background: #ffffff;
  color: #232326;
}

.dark .assets-header1 {
  border-color: #82b597;
  background: #232326;
  color: #fff;
}

.dark .assets-header3 {
  border-color: #73a888;
  background: #232326;
  color: #fff;
}

.dark .assets-header3::placeholder {
  color: #d1d1d1;
}

.dark .openVault_heading {
  color: #fff;
}

.dark .vault_prices1 {
  box-shadow: 0px 0px 15px #000000;
  background: #232326;
  border: solid 1px #38383d;
  color: #fff;
}

.dark .vault_tbd {
  color: #ffffff;
}

.dark .vault_amount_withdraw {
  background: #232326;
  box-shadow: 0px 0px 15px #000000;

  color: #fff;
  border: solid 1px #38383d;
}

.dark .amount_withdraw_cont1_txt2 {
  color: #ffffff;
}

.dark .next {
  color: white;
}

.dark .vault_prices1txt1 {
  color: #aeffce;
}

.dark .vault_prices1txt1aa {
  color: #aeffce;
}

.dark .amount_withdraw_cont1_txt1 {
  color: #aeffce;
}

.dark .open_vault_area2ss {
  background: #232326;
  box-shadow: 0px 0px 15px #000000;
  border: solid 1px #38383d;
}

.dark .card {
  background-color: #232326;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.dark .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #000 !important;
  color: #ffffff !important;
  border-bottom: solid 2px #ffffff !important;
}

.dark .nav-tabs {
  border-bottom: 1px solid #232326 !important;
}

.dark .card-header {
  background-color: #232326 !important;
}

.dark .vault_input0 {
  color: #fff;
}

.dark span.vault_input1 {
  color: #aeffce;
}

.dark input.vault_input_vaulta {
  border-color: #597865;
  background: #000;
  box-shadow: 0px 0px 10px #000;
  color: #fff;
}

.dark .vault_pay_back_btn {
  background: #ffffff;
  color: #232326;
  box-shadow: 0px 5px 13px #28392f;
}

.dark input.vault_input_vault {
  border-color: #597865;
  background: #000;
  box-shadow: 0px 0px 10px #000;
  color: #fff;
}

.dark p.configure {
  color: rgb(255, 255, 255);
}

.dark .open_vault_area2a {
  color: #e5e5e5;
}

.dark .open_vault_input_btn {
  background: #ffffff;
  color: #232326;
  box-shadow: 0px 5px 13px #28392f;
}

.dark h3.valid_div_inner_div_heading {
  color: #fff;
}

.dark .valid_div_inner_div_cont {
  color: #ffffff;
}

.dark .valid_div_inner_div_cont2 {
  color: #aeffce;
}

.dark .branch_name_title::before {
  color: #dfdfdf !important;
}

.dark .branch_Lending_Capacity::before {
  color: #ffffff !important;
}

.dark .branch_loan_status::before {
  color: #ffffff !important;
}

.dark .branch_pool_value::before {
  color: #ffffff !important;
}

.dark .branch_pool_value_progress::before {
  color: #ffffff !important;
}

.dark td.assets-category-data::before {
  color: #fff;
}

.dark .assets-category-data1::before {
  color: #fff;
}

.dark .ratio-content::before {
  color: #fff;
}

@media screen and (max-width: 991px) {
  .dark .list-item-active {
    color: #ffffff !important;
    background: none;
  }

  .dark .small_list-item-active {
    color: #fff;
    background: none;
    box-shadow: none;
  }

  .dark .sidebar {
    box-shadow: 0px 0px 5px #18201b;
  }
}

/* =================================== */
/* =================================== */
/* =================================== */
/* =================================== */
/* =================================== */
/* =================================== */
/* =================================== */
/* =================================== */
/* =================================== */
/* html {
  height: 100%;
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
  overflow: hidden;
} */
