.governance_details_page {
  flex: 4;
  padding: 4em 2em;
  width: 100%;
  background: #f6fff9;
  height: 100%;
  position: relative;
}

.governance_details_section {
  padding-top: 7em;
  padding-left: 14em;
  position: relative;
}

.governance_details_area {
  display: flex;
  justify-content: space-between;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  padding: 0em 2em;
}

.governance_details1 {
  width: 100%;
}

.governance_details2 {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.governance_details_title {
  font-size: 56px;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.governance_details_title_img {
  width: 100px;
  margin-right: 20px;
}

.governance_details_sub_head {
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  margin-top: 35px;
  color: #000000;
}
.governance_details {
  background: #e7e7e7;
  height: 8px;
  margin: 1em 0em;
  margin-top: 0;
  border-radius: 50px;
  position: relative;
  width: 80%;
}

.governance_details::after {
  content: "";
  background: #239e54;
  position: absolute;
  width: 80%;
  height: 100%;
  border-radius: 50px;
}

.governance_details_btns {
  display: flex;
  /* align-items: center; */
  justify-content: flex-start;
  margin-top: 25 px;
}

.swap {
  border: solid;
  border-width: 1px;
  background: #ffff;
  padding: 0.5em 2em;
  border-radius: 50px;
  border-color: #239e54;
}
.loan {
  border: none;
  background: #239e54;
  padding: 0.5em 2em;
  border-radius: 8px;
  color: #fff;
  width: 30%;
  margin-top: 15px;
  box-shadow: 0px 4px 10px #31a35e;
}

.details_dots {
  position: absolute;

  position: absolute;
  right: 0;
  width: 100%;
  bottom: -63px;
}

.key_metrics_area {
  padding: 2em;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0px 0px 20px #d5e5db;
  display: flex;
  flex-direction: column;
  width: 90%;
}

.last {
  border: none;
}

.key_metrics_heading {
  font-size: 24px;
  font-weight: 600;
}
.key_metrics_columns {
  display: flex;
  flex-direction: column;
}

.key_metrics_column1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5em 1em;
  border-bottom: solid;
  border-width: 1px;
  border-color: #e3e3e3;
}

.key_metrics_column1_cont {
  display: flex;
  width: 50%;
  justify-content: flex-start;
  font-size: 14px;
  font-weight: 600;
}

.thick {
  font-weight: 600;
}

.governance_details_link_cont {
  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
}

.governance_details_links {
  display: grid;
  grid-row-gap: 10px;
  grid-template-columns: auto auto auto;
  margin-top: 25px;
}

.website-svg {
  background: #ffffff;
  padding: 0.5em;
  border-radius: 100%;
  margin-right: 10px;
  width: 35px;
  box-shadow: 0px 0px 10px #b7d1c0;
}

.governance_details_link1 {
  color: #000;
  width: 100%;
  display: flex;
}
.governance_details_link1:hover {
  color: #239e54;
}

.project_overview_section {
  padding-top: 7em;
  padding-left: 14em;
  position: relative;
}
.project_overview_area {
  display: flex;
  justify-content: space-between;
  padding: 0em 2em;
}
.overview_nav {
  display: flex;
  flex-direction: column;
  grid-row-gap: 20px;
  width: 30%;
}

.overview_txts {
  display: flex;
  flex-direction: column;
  width: 70%;
  grid-row-gap: 35px;
  height: 439px;
  overflow-y: scroll;
  transition: 3s ease-in-out;
  scroll-behavior: smooth;
}
.overview_txts::-webkit-scrollbar {
  display: none;
}

.overview_nav1 {
  font-weight: 600;
  font-size: 14px;
  color: #000;
}
.overview_nav1:hover {
  font-weight: 600;
  font-size: 14px;
  color: #239e54;
}
.side_nav_active {
  font-weight: 600;
  font-size: 14px;
  color: #239e54;
}

.overview_txt1 {
  transition: 3s ease-in-out;
}
.overview_txt1_heading {
  font-size: 30px;
  font-weight: 600;
}

.overview_txt_img {
  width: 100%;
}
.governance_details_slider_amounts {
  display: flex;
  justify-content: space-between;
  width: 80%;
}

.slider_amounts1 {
  font-size: 12px;
}

.slider_percent1 {
  font-weight: 600;
  color: #239e54;
}

.list_item {
  list-style-type: disc;
}

@media screen and (max-width: 1220px) {
  .governance_details_section {
    padding-left: 0em;
  }

  .project_overview_section {
    padding-left: 0em;
  }
}
@media screen and (max-width: 1080px) {
  .governance_details_page {
    padding: 4em 0em;
  }

  .key_metrics_area {
    width: 100%;
  }
}
@media screen and (max-width: 899px) {
  .governance_details_page {
    padding: 4em 0em;
  }

  .key_metrics_area {
    width: 100%;
  }

  .governance_details_area {
    flex-direction: column;
    grid-row-gap: 40px;
  }

  .project_overview_area {
    flex-direction: column;
    grid-row-gap: 50px;
  }

  .overview_nav {
    width: 100%;
  }

  .overview_txts {
    width: 100%;
  }
  .governance_details {
    width: 100%;
  }

  .governance_details_slider_amounts {
    width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .governance_details_area {
    padding: 0em 0em;
  }

  .project_overview_area {
    padding: 0em;
  }
  .governance_details_section {
    padding-top: 4em;
  }
  .project_overview_section {
    padding-top: 4em;
  }
  .governance_details_links {
    grid-template-columns: auto auto;
  }
  .key_metrics_area {
    padding: 0.5em;
  }
}
@media screen and (max-width: 376px) {
  .key_metrics_column1 {
    padding: 0em 0.5em;
  }
}
