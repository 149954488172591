.other2 {
  flex: 4 1;
  padding: 4em 0em;
  width: 100svw;
  /* background: #000; */
  padding-left: 0em;
  padding-top: 1em;
  height: 100svh;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
}

.no-bg {
  background: none !important;
  padding: 2em;
}
.no-bg .container {
  max-width: 1450px;
}
