/* .exploreLoanSection{
    padding:0px 20px;
} */
.governance_section {
  padding: 2em 0em;
  padding-top: 8em;
  padding-left: 14em;
  width: 100%;
}

.coins_list_area {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-row-gap: 30px;
}

.coinsBg_area {
  width: 100%;
  height: 200px;
  background: #efefef;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: relative;
  overflow: hidden;
}

.coin-img {
  width: 100%;
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  box-shadow: 0px 0px 15px #cfcfcf;
  border-radius: 100%;
  margin: auto;
}

.coins_card {
  width: 90%;
  background: #ffff;
  border-radius: 10px;
  box-shadow: 0px 0px 15px #aecbba;
  display: flex;
  flex-direction: column;
  grid-row-gap: 20px;
  cursor: pointer;
  color: rgb(38, 38, 38);
  transition: 0.5s ease-in-out;
}

.coins_card:hover {
  transform: scale(1.1);
  transition: 0.5s ease-in-out;
  color: rgb(38, 38, 38);
}

.coinName {
  margin: 0;
  padding: 0em 1em;
  font-weight: 600;
  font-size: 18px;
  border-bottom: solid;
  border-width: 1px;
  border-color: #ebebeb;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.coinSymbol {
  margin: 0;
  padding: 0em 1em;
  padding-bottom: 0.5em;
  border-bottom: solid;
  border-width: 1px;
  border-color: #ebebeb;
  font-weight: 600;
  font-size: 24px;
  display: flex;
  align-items: center;
}

.coin_amount {
  margin: 0;
  padding: 0em 1em;
  display: flex;
  flex-direction: column;
  grid-row-gap: 5px;
}

.coin_slider {
  background: #e7e7e7;
  height: 8px;
  margin: 1em 0em;
  margin-top: 0;
  border-radius: 50px;
  position: relative;
}

.coin_slider::after {
  content: "";
  background: #239e54;
  position: absolute;
  width: 80%;
  height: 100%;
  border-radius: 50px;
}

.governance_page {
  flex: 4 1;
  width: 100svh;
  background: #f6fff9;
}
/* ================================================= */
/* ================================================= */
/* ================================================= */
/* ================================================= */
/* ================================================= */
/* ================================================= */

.proposals_area {
  display: flex;
  flex-direction: column;
}
.proposals_area_2 {
  display: flex;
  flex-direction: column;
  margin-top: 2em;
}
.proposals_area_2_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #232326;
  padding: 1em;
  border-radius: 10px;
  color: #fff;
}
.proposals_area_2_head_txt {
  font-size: 32px;
  font-weight: 500;
}
.proposals_area_2_head_stats_div {
  display: flex;
  align-items: center;
}
.proposals_area_2_head_stats_div1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 2em;
  border-right: solid 1px #333338;
  padding-right: 2em;
}
.proposals_area_2_head_stats_div1_title {
  font-size: 16px;
  color: #8e8e95;
}
.proposals_area_2_head_stats_div1_txt {
  font-size: 20px;
}
.proposals_area_2_body {
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.proposals_area_2_body_drop_div {
  position: relative;
  display: flex;
  /* flex-direction: column; */
  width: 100%;
  justify-content: flex-end;
}

.proposals_area_2_body_drop_div_button {
  display: flex;
  align-items: center;
  width: 70px;
  min-width: fit-content;
  padding: 5px 10px;
  border-radius: 10px;
  border: solid 1px #2d2d31;
  color: #fff;
  justify-content: space-between;
  font-weight: 500;
  font-size: 16px;
  background: #232326;
}
.proposals_area_2_body_drop_div_button:hover {
  background: #3d3d41;
}
.proposals_area_2_body_head_tabs {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  width: 100%;
  background: #232326;
  color: #74747a;
  font-size: 15px;
  padding: 5px 5px;
  border-radius: 7px;
  width: -moz-fit-content;
  width: 100%;
  max-width: 220px;
  position: absolute;
  top: 110%;
  flex-direction: column;
  box-shadow: 0px 7px 20px 9px #00000061;
  border: solid 1px #2e2e31;
}
.proposals_area_2_body_head_tabs_1_active {
  background: linear-gradient(to right, #58ad74, #4c8f62, #5fa7d6);
  border-radius: 7px;
  color: #fff;
  width: 100%;
  font-size: 14px;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  padding: 10px 10px;
}
.proposals_area_2_body_head_tabs_1 {
  border-radius: 5px;
  width: 100%;
  font-size: 14px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  padding: 10px 10px;
}
.proposals_area_2_body_area {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  width: 100%;
}
.proposals_area_2_body_area_cont1 {
  display: flex;
  flex-direction: column;
  padding: 1em;
  background: #232326;
  margin-bottom: 1em;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
}
.proposals_area_2_body_area_cont1_area1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px #38383c;
  padding-bottom: 1em;
}
.proposals_area_2_body_area_cont1_area1_title {
  font-size: 24px;
  font-weight: 600;
}
.proposals_area_2_body_area_cont1_area1_status {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 10px; */
}
.proposals_area_2_body_area_cont1_area1_status_btn {
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 6px;
  border: 0;
  background: #55555d;
  color: #fff;
}
.proposals_area_2_body_area_cont1_area2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1em;
}
canvas.blockies_icon2 {
  width: 20px !important;
  height: 20px !important;
  border-radius: 50%;
  margin-right: 10px;
}
.proposals_area_2_body_area_cont1_area2_div1 {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.proposals_area_2_body_area_cont1_area2_div1_span {
  font-size: 12px;
  margin-right: 5px;
  color: #94949f;
}
.proposals_area_2_body_area_cont1_area2_div2 {
  font-size: 14px;
  color: #94949f;
}
/* ============================
===================== */
/* ================================================= */
/* ================================================= */
/* ================================================= */
/* ================================================= */
/* ================================================= */
/* ================================================= */
/* ================================================= */
/* ================================================= */
/* ================================================= */
@media screen and (max-width: 1455px) {
  .coins_list_area {
    grid-template-columns: auto auto auto;
  }
  .coins_card {
    width: 80%;
    margin: auto;
  }
}
@media screen and (max-width: 1320px) {
  .coins_card {
    width: 90%;
    margin: auto;
  }
}
@media screen and (max-width: 1220px) {
  .coins_card {
    width: 90%;
    margin: auto;
  }
  .coins_list_area {
    grid-template-columns: auto auto auto;
  }
  .governance_section {
    padding-left: 2em;
  }
}
@media screen and (max-width: 1080px) {
  .governance_section {
    padding-left: 0em;
  }
}
@media screen and (max-width: 930px) {
  .coins_card {
    width: 90%;
    margin: auto;
  }
}
@media screen and (max-width: 920px) {
  .coins_card {
    width: 85%;
    margin: auto;
  }
  .coin-img {
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    margin: auto;
  }
}
@media screen and (max-width: 902px) {
  .coins_card {
    width: 85%;
    margin: auto;
  }
  .coins_list_area {
    grid-template-columns: auto auto;
  }
  .coin-img {
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    margin: auto;
  }
}
@media screen and (max-width: 767px) {
  .coin-img {
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    margin: auto;
  }
  .proposals_area_2_body_head_tabs {
    width: 100%;
  }
  .proposals_area_2_head {
    align-items: flex-start;
    flex-direction: column;
  }
  .proposals_area_2_head_stats_div {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 5px;
    /* justify-content: space-between; */
  }
  .proposals_area_2_body_area_cont1_area2_div1 {
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    flex-direction: column;
  }
  .proposals_area_2_body_area_cont1_area1_title {
    font-size: 21px;
    font-weight: 600;
  }
  .proposals_area_2_body_head_tabs_1 {
    font-size: 12px;
  }
  /* .proposals_area_2_body_head_tabs_1_active {
    font-size: 12px;
  } */
  .proposals_area_2_head_stats_div1_title {
    font-size: 12px;
    color: #8e8e95;
  }
  .proposals_area_2_head_stats_div1_txt {
    font-size: 16px;
  }
  .proposals_area_2_head_stats_div1 {
    margin-right: 1em;
    padding-right: 1em;
  }
  .proposals_area_2_head_txt {
    font-size: 24px;
    font-weight: 500;
  }
}
@media screen and (max-width: 660px) {
  .coin-img {
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    margin: auto;
  }
  .coins_card {
    width: 80%;
    margin: auto;
  }
}
@media screen and (max-width: 652px) {
  .coin-img {
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    margin: auto;
  }
  .coins_card {
    width: 50%;
    margin: auto;
  }
  .coins_list_area {
    grid-template-columns: auto;
  }
}
@media screen and (max-width: 600px) {
  .coins_card {
    width: 65%;
    margin: auto;
  }
}
@media screen and (max-width: 450px) {
  .coins_card {
    width: 95%;
    margin: auto;
  }
}
