.stars_div {
  position: absolute;
  z-index: 1;
  top: 0;
}
#stars {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 783px 2860px #fff, 211px 554px #fff, 1595px 1358px #fff,
    911px 250px #fff, 1566px 329px #fff, 361px 1628px #fff, 475px 1232px #fff,
    989px 668px #fff, 1583px 597px #fff, 629px 727px #fff, 1284px 936px #fff,
    170px 868px #fff, 1095px 1358px #fff, 1720px 1601px #fff, 464px 877px #fff,
    1121px 321px #fff, 93px 148px #fff, 1109px 297px #fff, 1040px 1973px #fff,
    1966px 1475px #fff, 619px 1199px #fff, 207px 583px #fff, 1528px 1151px #fff,
    1387px 612px #fff, 634px 1451px #fff, 534px 1403px #fff, 738px 479px #fff,
    756px 304px #fff, 353px 966px #fff, 931px 1153px #fff, 372px 367px #fff,
    425px 1740px #fff, 986px 906px #fff, 1989px 411px #fff, 136px 555px #fff,
    419px 983px #fff, 1412px 1404px #fff, 843px 1224px #fff, 1393px 1944px #fff,
    395px 1338px #fff, 689px 1009px #fff, 701px 48px #fff, 597px 1055px #fff,
    969px 757px #fff, 58px 686px #fff, 1017px 681px #fff, 1492px 23px #fff,
    1071px 1012px #fff, 358px 1726px #fff, 1946px 1478px #fff, 158px 1837px #fff,
    1390px 578px #fff, 142px 1637px #fff, 489px 1918px #fff, 1495px 1360px #fff,
    289px 1909px #fff, 1311px 440px #fff, 882px 1245px #fff, 469px 1196px #fff,
    351px 1472px #fff, 1888px 1189px #fff, 1294px 1239px #fff, 355px 1791px #fff,
    817px 1364px #fff, 175px 1020px #fff, 1482px 1248px #fff, 1167px 747px #fff,
    709px 910px #fff, 613px 1475px #fff, 1872px 1854px #fff, 126px 956px #fff,
    724px 552px #fff, 1940px 1695px #fff, 1600px 1011px #fff, 1556px 1943px #fff,
    1857px 321px #fff, 1526px 822px #fff, 1357px 809px #fff, 1252px 884px #fff,
    1729px 1630px #fff, 648px 1418px #fff, 1818px 1143px #fff, 900px 1513px #fff,
    601px 898px #fff, 1392px 1621px #fff, 1456px 1087px #fff, 157px 1650px #fff,
    674px 1302px #fff, 335px 1023px #fff, 54px 957px #fff, 1021px 870px #fff,
    1199px 1730px #fff, 566px 1690px #fff, 379px 1315px #fff, 110px 1693px #fff,
    1522px 937px #fff, 1971px 1219px #fff, 625px 759px #fff, 1102px 374px #fff,
    1894px 1782px #fff, 1851px 626px #fff, 427px 82px #fff, 581px 422px #fff,
    1380px 878px #fff, 1553px 529px #fff, 1960px 106px #fff, 1452px 1227px #fff,
    1507px 1244px #fff, 1964px 1169px #fff, 1886px 190px #fff, 700px 847px #fff,
    1527px 977px #fff, 181px 1674px #fff, 1201px 831px #fff, 1471px 1611px #fff,
    858px 1278px #fff, 175px 1137px #fff, 1352px 1054px #fff, 265px 324px #fff,
    859px 216px #fff, 1908px 162px #fff, 595px 1304px #fff, 1766px 125px #fff,
    923px 699px #fff, 516px 1788px #fff, 989px 1920px #fff, 1156px 1677px #fff,
    1143px 647px #fff, 762px 584px #fff, 1845px 1758px #fff, 17px 584px #fff,
    1706px 595px #fff, 698px 1837px #fff, 1206px 577px #fff, 1013px 1039px #fff,
    964px 312px #fff, 1401px 921px #fff, 1423px 1403px #fff, 1267px 1584px #fff,
    1655px 1237px #fff, 1380px 493px #fff, 609px 446px #fff, 208px 448px #fff,
    432px 135px #fff, 33px 250px #fff, 251px 1324px #fff, 1170px 1791px #fff,
    184px 902px #fff, 870px 694px #fff, 1788px 421px #fff, 1913px 1658px #fff,
    76px 1983px #fff, 1982px 1749px #fff, 315px 686px #fff, 1205px 1741px #fff,
    1416px 95px #fff, 1107px 868px #fff, 9px 1660px #fff, 1994px 1678px #fff,
    1290px 670px #fff, 27px 284px #fff, 278px 527px #fff, 1775px 1199px #fff,
    822px 1853px #fff, 334px 689px #fff, 839px 1279px #fff, 1871px 1662px #fff,
    945px 879px #fff, 288px 440px #fff, 1313px 1639px #fff, 585px 1953px #fff,
    1983px 815px #fff, 1318px 629px #fff, 1035px 422px #fff, 1889px 821px #fff,
    1665px 1940px #fff, 590px 1104px #fff, 1317px 789px #fff, 1288px 1726px #fff,
    278px 1839px #fff, 30px 627px #fff, 1835px 41px #fff, 440px 1789px #fff,
    1050px 1171px #fff, 177px 321px #fff, 467px 1030px #fff, 1796px 25px #fff,
    1003px 460px #fff, 50px 274px #fff, 1374px 1397px #fff, 662px 684px #fff,
    1349px 358px #fff, 847px 744px #fff, 45px 1155px #fff, 1039px 1222px #fff,
    1515px 1811px #fff, 443px 209px #fff, 807px 1108px #fff, 1097px 873px #fff,
    104px 1243px #fff, 1346px 1225px #fff, 1164px 1778px #fff,
    1831px 1027px #fff, 1329px 892px #fff, 182px 1752px #fff, 905px 1155px #fff,
    206px 1447px #fff, 1197px 1293px #fff, 1914px 683px #fff, 222px 960px #fff,
    1275px 109px #fff, 1371px 1981px #fff, 1132px 1288px #fff, 547px 1034px #fff,
    1079px 186px #fff, 737px 1620px #fff, 177px 1860px #fff, 81px 117px #fff,
    610px 1163px #fff, 537px 1016px #fff, 294px 943px #fff, 369px 287px #fff,
    910px 780px #fff, 1724px 1082px #fff, 1879px 256px #fff, 1313px 1517px #fff,
    1485px 1855px #fff, 414px 659px #fff, 1935px 1821px #fff, 1095px 481px #fff,
    494px 1960px #fff, 92px 36px #fff, 1169px 545px #fff, 1776px 422px #fff,
    1633px 809px #fff, 1424px 591px #fff, 1272px 552px #fff, 352px 1057px #fff,
    548px 1077px #fff, 1930px 1543px #fff, 465px 1819px #fff, 1032px 997px #fff,
    950px 388px #fff, 1742px 1649px #fff, 1480px 296px #fff, 1386px 1829px #fff,
    853px 646px #fff, 1164px 1926px #fff, 1675px 1332px #fff, 398px 1525px #fff,
    1814px 1461px #fff, 1946px 185px #fff, 427px 716px #fff, 1349px 1131px #fff,
    1890px 660px #fff, 345px 25px #fff, 151px 715px #fff, 1384px 1698px #fff,
    362px 1959px #fff, 369px 1314px #fff, 1349px 1246px #fff, 787px 996px #fff,
    1843px 1961px #fff, 635px 563px #fff, 921px 1843px #fff, 660px 1065px #fff,
    1970px 1583px #fff, 1390px 1632px #fff, 1552px 1911px #fff,
    1563px 1498px #fff, 502px 158px #fff, 657px 1219px #fff, 1308px 109px #fff,
    340px 1846px #fff, 150px 1025px #fff, 110px 1237px #fff, 1806px 240px #fff,
    1159px 107px #fff, 1039px 1762px #fff, 907px 114px #fff, 886px 576px #fff,
    872px 478px #fff, 1583px 1214px #fff, 1994px 1507px #fff, 1896px 1039px #fff,
    1726px 1660px #fff, 613px 601px #fff, 96px 202px #fff, 181px 117px #fff,
    726px 18px #fff, 1330px 478px #fff, 1344px 1648px #fff, 72px 1822px #fff,
    1085px 1473px #fff, 1696px 1186px #fff, 121px 1230px #fff, 1228px 578px #fff,
    55px 1554px #fff, 1169px 1666px #fff, 1629px 656px #fff, 1163px 231px #fff,
    844px 1137px #fff, 618px 190px #fff, 774px 135px #fff, 1401px 1842px #fff,
    235px 506px #fff, 1920px 1570px #fff, 248px 1980px #fff, 1557px 1706px #fff,
    1885px 759px #fff, 652px 1379px #fff, 1314px 1541px #fff, 1524px 317px #fff,
    158px 518px #fff, 379px 798px #fff, 1639px 1231px #fff, 1885px 1491px #fff,
    405px 1928px #fff, 1387px 442px #fff, 1336px 547px #fff, 577px 128px #fff,
    368px 1579px #fff, 1517px 1932px #fff, 752px 1963px #fff, 1558px 1601px #fff,
    41px 938px #fff, 2000px 877px #fff, 645px 585px #fff, 1779px 748px #fff,
    1757px 273px #fff, 796px 1096px #fff, 265px 335px #fff, 1891px 1843px #fff,
    1921px 682px #fff, 264px 446px #fff, 455px 459px #fff, 276px 365px #fff,
    292px 1751px #fff, 181px 84px #fff, 837px 181px #fff, 1989px 909px #fff,
    499px 74px #fff, 534px 528px #fff, 1664px 1660px #fff, 475px 404px #fff,
    1886px 882px #fff, 338px 143px #fff, 1689px 1815px #fff, 1159px 281px #fff,
    1351px 1942px #fff, 893px 1647px #fff, 1644px 1284px #fff, 1121px 394px #fff,
    970px 923px #fff, 402px 1194px #fff, 318px 797px #fff, 1084px 112px #fff,
    1392px 263px #fff, 144px 241px #fff, 182px 1432px #fff, 1729px 595px #fff,
    18px 1024px #fff, 808px 1337px #fff, 1384px 1177px #fff, 1248px 843px #fff,
    857px 57px #fff, 1891px 1585px #fff, 737px 932px #fff, 769px 865px #fff,
    880px 234px #fff, 276px 619px #fff, 710px 272px #fff, 1187px 180px #fff,
    189px 186px #fff, 336px 1843px #fff, 1281px 63px #fff, 785px 1323px #fff,
    1723px 1219px #fff, 1862px 1070px #fff, 346px 1823px #fff, 1330px 254px #fff,
    161px 833px #fff, 727px 1095px #fff, 1041px 431px #fff, 957px 600px #fff,
    967px 93px #fff, 538px 1768px #fff, 1061px 1414px #fff, 788px 1866px #fff,
    587px 1041px #fff, 902px 1333px #fff, 1147px 1993px #fff, 1983px 1683px #fff,
    977px 1788px #fff, 760px 1698px #fff, 1450px 1835px #fff, 1823px 1191px #fff,
    1872px 1571px #fff, 941px 1168px #fff, 932px 597px #fff, 1309px 15px #fff,
    963px 719px #fff, 339px 623px #fff, 1589px 1384px #fff, 52px 379px #fff,
    1035px 674px #fff, 4px 810px #fff, 1950px 1704px #fff, 307px 282px #fff,
    1363px 864px #fff, 988px 934px #fff, 354px 1714px #fff, 1784px 783px #fff,
    1451px 1159px #fff, 1557px 1867px #fff, 693px 816px #fff, 1039px 1960px #fff,
    1373px 294px #fff, 1752px 1635px #fff, 492px 79px #fff, 1033px 1739px #fff,
    488px 143px #fff, 1641px 924px #fff, 8px 1553px #fff, 861px 1486px #fff,
    683px 248px #fff, 1185px 1160px #fff, 405px 57px #fff, 1891px 1632px #fff,
    1351px 1126px #fff, 241px 899px #fff, 657px 541px #fff, 936px 235px #fff,
    1928px 1675px #fff, 628px 1110px #fff, 904px 109px #fff, 1690px 1340px #fff,
    1156px 774px #fff, 1951px 1327px #fff, 642px 361px #fff, 138px 119px #fff,
    1444px 736px #fff, 729px 1927px #fff, 1996px 1107px #fff, 1045px 1229px #fff,
    1910px 1753px #fff, 1344px 1285px #fff, 686px 868px #fff, 24px 122px #fff,
    915px 1793px #fff, 945px 504px #fff, 371px 539px #fff, 151px 1897px #fff,
    1927px 195px #fff, 1289px 1179px #fff, 934px 1038px #fff, 1628px 1963px #fff,
    1614px 231px #fff, 1723px 1168px #fff, 1168px 1296px #fff, 1815px 525px #fff,
    1798px 572px #fff, 812px 131px #fff, 209px 1445px #fff, 482px 102px #fff,
    201px 1871px #fff, 883px 1318px #fff, 643px 1372px #fff, 405px 35px #fff,
    294px 1790px #fff, 190px 1482px #fff, 1017px 82px #fff, 1062px 1596px #fff,
    874px 1477px #fff, 1707px 94px #fff, 1825px 1814px #fff, 627px 812px #fff,
    405px 1055px #fff, 843px 1258px #fff, 1330px 560px #fff, 1323px 1433px #fff,
    362px 1736px #fff, 1934px 2000px #fff, 996px 1269px #fff, 1244px 1141px #fff,
    791px 1901px #fff, 1001px 1231px #fff, 295px 406px #fff, 1296px 1401px #fff,
    450px 1377px #fff, 103px 940px #fff, 1277px 1749px #fff, 57px 1435px #fff,
    535px 1135px #fff, 1067px 705px #fff, 1554px 498px #fff, 1383px 1162px #fff,
    1632px 1124px #fff, 1910px 310px #fff, 1502px 659px #fff, 1863px 98px #fff,
    295px 545px #fff, 1702px 1340px #fff, 1349px 1812px #fff, 1765px 782px #fff,
    1692px 1352px #fff, 1565px 900px #fff, 1114px 986px #fff, 664px 80px #fff,
    965px 1975px #fff, 418px 465px #fff, 1986px 840px #fff, 1800px 1653px #fff,
    1431px 41px #fff, 1167px 89px #fff, 351px 1700px #fff, 973px 945px #fff,
    330px 540px #fff, 334px 1799px #fff, 1104px 897px #fff, 1259px 805px #fff,
    240px 469px #fff, 1730px 1844px #fff, 1949px 1744px #fff, 915px 108px #fff,
    1964px 1932px #fff, 1641px 472px #fff, 1674px 429px #fff, 1131px 996px #fff,
    902px 478px #fff, 326px 726px #fff, 322px 706px #fff, 1769px 1046px #fff,
    605px 564px #fff, 1023px 1411px #fff, 1204px 615px #fff, 1883px 729px #fff,
    645px 362px #fff, 902px 3px #fff, 427px 1196px #fff, 491px 58px #fff,
    1670px 1812px #fff, 57px 526px #fff, 1709px 1587px #fff, 1133px 1255px #fff,
    1204px 489px #fff, 1735px 116px #fff, 133px 164px #fff, 1292px 1983px #fff,
    1777px 108px #fff, 1475px 92px #fff, 1721px 492px #fff, 994px 327px #fff,
    1727px 1757px #fff, 1148px 135px #fff, 1502px 1433px #fff, 1316px 603px #fff,
    1241px 1074px #fff, 6px 95px #fff, 246px 98px #fff, 1614px 1246px #fff,
    198px 349px #fff, 92px 1337px #fff, 1366px 926px #fff, 6px 1140px #fff,
    581px 418px #fff, 1852px 1097px #fff, 1372px 1432px #fff, 1771px 991px #fff,
    1975px 693px #fff, 450px 1616px #fff, 1724px 889px #fff, 334px 483px #fff,
    849px 1589px #fff, 1491px 170px #fff, 777px 1090px #fff, 893px 36px #fff,
    256px 668px #fff, 1083px 1304px #fff, 548px 1999px #fff, 1059px 913px #fff,
    1410px 406px #fff, 1010px 685px #fff, 1002px 135px #fff, 660px 1409px #fff,
    1174px 680px #fff, 219px 73px #fff, 1429px 226px #fff, 1758px 1277px #fff,
    1213px 1881px #fff, 1211px 785px #fff, 1584px 1787px #fff, 1590px 498px #fff,
    1928px 1382px #fff, 1571px 1989px #fff, 84px 85px #fff, 1831px 1021px #fff,
    1682px 683px #fff, 1603px 668px #fff, 870px 1175px #fff, 66px 481px #fff,
    371px 615px #fff, 1275px 569px #fff, 1506px 380px #fff, 523px 636px #fff,
    1007px 1323px #fff, 791px 1267px #fff, 1744px 1744px #fff, 664px 1100px #fff,
    947px 411px #fff, 530px 105px #fff, 913px 28px #fff, 1676px 1435px #fff,
    1495px 976px #fff, 754px 1428px #fff, 1336px 955px #fff, 699px 765px #fff,
    1784px 1866px #fff, 1059px 772px #fff, 896px 398px #fff, 131px 708px #fff,
    1707px 179px #fff, 346px 216px #fff, 1750px 1601px #fff, 593px 1167px #fff,
    1315px 738px #fff, 490px 1698px #fff, 347px 1035px #fff, 1706px 1889px #fff,
    552px 1856px #fff, 389px 1090px #fff, 1325px 296px #fff, 858px 1809px #fff,
    1831px 352px #fff, 1876px 1432px #fff, 1503px 1280px #fff,
    1827px 1106px #fff, 33px 422px #fff, 20px 1702px #fff, 1987px 539px #fff,
    691px 1276px #fff, 73px 643px #fff, 1378px 82px #fff, 1517px 1799px #fff,
    1028px 469px #fff, 1072px 687px #fff, 352px 281px #fff, 1871px 1067px #fff,
    666px 690px #fff, 75px 348px #fff, 1432px 70px #fff, 1895px 1402px #fff,
    1154px 860px #fff, 760px 154px #fff, 724px 417px #fff, 1180px 1815px #fff,
    101px 1007px #fff, 1470px 1313px #fff, 1153px 424px #fff, 953px 1647px #fff,
    285px 1364px #fff, 1040px 1202px #fff, 1391px 1049px #fff, 506px 1294px #fff,
    336px 1552px #fff, 501px 298px #fff, 1636px 334px #fff, 1156px 142px #fff,
    1316px 1342px #fff, 356px 1760px #fff, 1362px 202px #fff, 1376px 1474px #fff,
    27px 93px #fff, 71px 1045px #fff, 252px 543px #fff, 1908px 988px #fff,
    1078px 1026px #fff, 692px 1867px #fff, 1894px 1548px #fff, 496px 349px #fff,
    931px 1508px #fff, 270px 1341px #fff, 1248px 650px #fff, 645px 1510px #fff,
    750px 1867px #fff, 761px 187px #fff, 648px 767px #fff, 1561px 651px #fff,
    1417px 1718px #fff, 347px 1944px #fff, 1588px 461px #fff, 863px 1501px #fff,
    492px 451px #fff, 135px 981px #fff, 1448px 1573px #fff, 999px 1243px #fff,
    835px 352px #fff, 881px 1089px #fff, 1180px 617px #fff, 332px 961px #fff;
  animation: animStar 50s linear infinite;
}
#stars:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 783px 2860px #fff, 211px 554px #fff, 1595px 1358px #fff,
    911px 250px #fff, 1566px 329px #fff, 361px 1628px #fff, 475px 1232px #fff,
    989px 668px #fff, 1583px 597px #fff, 629px 727px #fff, 1284px 936px #fff,
    170px 868px #fff, 1095px 1358px #fff, 1720px 1601px #fff, 464px 877px #fff,
    1121px 321px #fff, 93px 148px #fff, 1109px 297px #fff, 1040px 1973px #fff,
    1966px 1475px #fff, 619px 1199px #fff, 207px 583px #fff, 1528px 1151px #fff,
    1387px 612px #fff, 634px 1451px #fff, 534px 1403px #fff, 738px 479px #fff,
    756px 304px #fff, 353px 966px #fff, 931px 1153px #fff, 372px 367px #fff,
    425px 1740px #fff, 986px 906px #fff, 1989px 411px #fff, 136px 555px #fff,
    419px 983px #fff, 1412px 1404px #fff, 843px 1224px #fff, 1393px 1944px #fff,
    395px 1338px #fff, 689px 1009px #fff, 701px 48px #fff, 597px 1055px #fff,
    969px 757px #fff, 58px 686px #fff, 1017px 681px #fff, 1492px 23px #fff,
    1071px 1012px #fff, 358px 1726px #fff, 1946px 1478px #fff, 158px 1837px #fff,
    1390px 578px #fff, 142px 1637px #fff, 489px 1918px #fff, 1495px 1360px #fff,
    289px 1909px #fff, 1311px 440px #fff, 882px 1245px #fff, 469px 1196px #fff,
    351px 1472px #fff, 1888px 1189px #fff, 1294px 1239px #fff, 355px 1791px #fff,
    817px 1364px #fff, 175px 1020px #fff, 1482px 1248px #fff, 1167px 747px #fff,
    709px 910px #fff, 613px 1475px #fff, 1872px 1854px #fff, 126px 956px #fff,
    724px 552px #fff, 1940px 1695px #fff, 1600px 1011px #fff, 1556px 1943px #fff,
    1857px 321px #fff, 1526px 822px #fff, 1357px 809px #fff, 1252px 884px #fff,
    1729px 1630px #fff, 648px 1418px #fff, 1818px 1143px #fff, 900px 1513px #fff,
    601px 898px #fff, 1392px 1621px #fff, 1456px 1087px #fff, 157px 1650px #fff,
    674px 1302px #fff, 335px 1023px #fff, 54px 957px #fff, 1021px 870px #fff,
    1199px 1730px #fff, 566px 1690px #fff, 379px 1315px #fff, 110px 1693px #fff,
    1522px 937px #fff, 1971px 1219px #fff, 625px 759px #fff, 1102px 374px #fff,
    1894px 1782px #fff, 1851px 626px #fff, 427px 82px #fff, 581px 422px #fff,
    1380px 878px #fff, 1553px 529px #fff, 1960px 106px #fff, 1452px 1227px #fff,
    1507px 1244px #fff, 1964px 1169px #fff, 1886px 190px #fff, 700px 847px #fff,
    1527px 977px #fff, 181px 1674px #fff, 1201px 831px #fff, 1471px 1611px #fff,
    858px 1278px #fff, 175px 1137px #fff, 1352px 1054px #fff, 265px 324px #fff,
    859px 216px #fff, 1908px 162px #fff, 595px 1304px #fff, 1766px 125px #fff,
    923px 699px #fff, 516px 1788px #fff, 989px 1920px #fff, 1156px 1677px #fff,
    1143px 647px #fff, 762px 584px #fff, 1845px 1758px #fff, 17px 584px #fff,
    1706px 595px #fff, 698px 1837px #fff, 1206px 577px #fff, 1013px 1039px #fff,
    964px 312px #fff, 1401px 921px #fff, 1423px 1403px #fff, 1267px 1584px #fff,
    1655px 1237px #fff, 1380px 493px #fff, 609px 446px #fff, 208px 448px #fff,
    432px 135px #fff, 33px 250px #fff, 251px 1324px #fff, 1170px 1791px #fff,
    184px 902px #fff, 870px 694px #fff, 1788px 421px #fff, 1913px 1658px #fff,
    76px 1983px #fff, 1982px 1749px #fff, 315px 686px #fff, 1205px 1741px #fff,
    1416px 95px #fff, 1107px 868px #fff, 9px 1660px #fff, 1994px 1678px #fff,
    1290px 670px #fff, 27px 284px #fff, 278px 527px #fff, 1775px 1199px #fff,
    822px 1853px #fff, 334px 689px #fff, 839px 1279px #fff, 1871px 1662px #fff,
    945px 879px #fff, 288px 440px #fff, 1313px 1639px #fff, 585px 1953px #fff,
    1983px 815px #fff, 1318px 629px #fff, 1035px 422px #fff, 1889px 821px #fff,
    1665px 1940px #fff, 590px 1104px #fff, 1317px 789px #fff, 1288px 1726px #fff,
    278px 1839px #fff, 30px 627px #fff, 1835px 41px #fff, 440px 1789px #fff,
    1050px 1171px #fff, 177px 321px #fff, 467px 1030px #fff, 1796px 25px #fff,
    1003px 460px #fff, 50px 274px #fff, 1374px 1397px #fff, 662px 684px #fff,
    1349px 358px #fff, 847px 744px #fff, 45px 1155px #fff, 1039px 1222px #fff,
    1515px 1811px #fff, 443px 209px #fff, 807px 1108px #fff, 1097px 873px #fff,
    104px 1243px #fff, 1346px 1225px #fff, 1164px 1778px #fff,
    1831px 1027px #fff, 1329px 892px #fff, 182px 1752px #fff, 905px 1155px #fff,
    206px 1447px #fff, 1197px 1293px #fff, 1914px 683px #fff, 222px 960px #fff,
    1275px 109px #fff, 1371px 1981px #fff, 1132px 1288px #fff, 547px 1034px #fff,
    1079px 186px #fff, 737px 1620px #fff, 177px 1860px #fff, 81px 117px #fff,
    610px 1163px #fff, 537px 1016px #fff, 294px 943px #fff, 369px 287px #fff,
    910px 780px #fff, 1724px 1082px #fff, 1879px 256px #fff, 1313px 1517px #fff,
    1485px 1855px #fff, 414px 659px #fff, 1935px 1821px #fff, 1095px 481px #fff,
    494px 1960px #fff, 92px 36px #fff, 1169px 545px #fff, 1776px 422px #fff,
    1633px 809px #fff, 1424px 591px #fff, 1272px 552px #fff, 352px 1057px #fff,
    548px 1077px #fff, 1930px 1543px #fff, 465px 1819px #fff, 1032px 997px #fff,
    950px 388px #fff, 1742px 1649px #fff, 1480px 296px #fff, 1386px 1829px #fff,
    853px 646px #fff, 1164px 1926px #fff, 1675px 1332px #fff, 398px 1525px #fff,
    1814px 1461px #fff, 1946px 185px #fff, 427px 716px #fff, 1349px 1131px #fff,
    1890px 660px #fff, 345px 25px #fff, 151px 715px #fff, 1384px 1698px #fff,
    362px 1959px #fff, 369px 1314px #fff, 1349px 1246px #fff, 787px 996px #fff,
    1843px 1961px #fff, 635px 563px #fff, 921px 1843px #fff, 660px 1065px #fff,
    1970px 1583px #fff, 1390px 1632px #fff, 1552px 1911px #fff,
    1563px 1498px #fff, 502px 158px #fff, 657px 1219px #fff, 1308px 109px #fff,
    340px 1846px #fff, 150px 1025px #fff, 110px 1237px #fff, 1806px 240px #fff,
    1159px 107px #fff, 1039px 1762px #fff, 907px 114px #fff, 886px 576px #fff,
    872px 478px #fff, 1583px 1214px #fff, 1994px 1507px #fff, 1896px 1039px #fff,
    1726px 1660px #fff, 613px 601px #fff, 96px 202px #fff, 181px 117px #fff,
    726px 18px #fff, 1330px 478px #fff, 1344px 1648px #fff, 72px 1822px #fff,
    1085px 1473px #fff, 1696px 1186px #fff, 121px 1230px #fff, 1228px 578px #fff,
    55px 1554px #fff, 1169px 1666px #fff, 1629px 656px #fff, 1163px 231px #fff,
    844px 1137px #fff, 618px 190px #fff, 774px 135px #fff, 1401px 1842px #fff,
    235px 506px #fff, 1920px 1570px #fff, 248px 1980px #fff, 1557px 1706px #fff,
    1885px 759px #fff, 652px 1379px #fff, 1314px 1541px #fff, 1524px 317px #fff,
    158px 518px #fff, 379px 798px #fff, 1639px 1231px #fff, 1885px 1491px #fff,
    405px 1928px #fff, 1387px 442px #fff, 1336px 547px #fff, 577px 128px #fff,
    368px 1579px #fff, 1517px 1932px #fff, 752px 1963px #fff, 1558px 1601px #fff,
    41px 938px #fff, 2000px 877px #fff, 645px 585px #fff, 1779px 748px #fff,
    1757px 273px #fff, 796px 1096px #fff, 265px 335px #fff, 1891px 1843px #fff,
    1921px 682px #fff, 264px 446px #fff, 455px 459px #fff, 276px 365px #fff,
    292px 1751px #fff, 181px 84px #fff, 837px 181px #fff, 1989px 909px #fff,
    499px 74px #fff, 534px 528px #fff, 1664px 1660px #fff, 475px 404px #fff,
    1886px 882px #fff, 338px 143px #fff, 1689px 1815px #fff, 1159px 281px #fff,
    1351px 1942px #fff, 893px 1647px #fff, 1644px 1284px #fff, 1121px 394px #fff,
    970px 923px #fff, 402px 1194px #fff, 318px 797px #fff, 1084px 112px #fff,
    1392px 263px #fff, 144px 241px #fff, 182px 1432px #fff, 1729px 595px #fff,
    18px 1024px #fff, 808px 1337px #fff, 1384px 1177px #fff, 1248px 843px #fff,
    857px 57px #fff, 1891px 1585px #fff, 737px 932px #fff, 769px 865px #fff,
    880px 234px #fff, 276px 619px #fff, 710px 272px #fff, 1187px 180px #fff,
    189px 186px #fff, 336px 1843px #fff, 1281px 63px #fff, 785px 1323px #fff,
    1723px 1219px #fff, 1862px 1070px #fff, 346px 1823px #fff, 1330px 254px #fff,
    161px 833px #fff, 727px 1095px #fff, 1041px 431px #fff, 957px 600px #fff,
    967px 93px #fff, 538px 1768px #fff, 1061px 1414px #fff, 788px 1866px #fff,
    587px 1041px #fff, 902px 1333px #fff, 1147px 1993px #fff, 1983px 1683px #fff,
    977px 1788px #fff, 760px 1698px #fff, 1450px 1835px #fff, 1823px 1191px #fff,
    1872px 1571px #fff, 941px 1168px #fff, 932px 597px #fff, 1309px 15px #fff,
    963px 719px #fff, 339px 623px #fff, 1589px 1384px #fff, 52px 379px #fff,
    1035px 674px #fff, 4px 810px #fff, 1950px 1704px #fff, 307px 282px #fff,
    1363px 864px #fff, 988px 934px #fff, 354px 1714px #fff, 1784px 783px #fff,
    1451px 1159px #fff, 1557px 1867px #fff, 693px 816px #fff, 1039px 1960px #fff,
    1373px 294px #fff, 1752px 1635px #fff, 492px 79px #fff, 1033px 1739px #fff,
    488px 143px #fff, 1641px 924px #fff, 8px 1553px #fff, 861px 1486px #fff,
    683px 248px #fff, 1185px 1160px #fff, 405px 57px #fff, 1891px 1632px #fff,
    1351px 1126px #fff, 241px 899px #fff, 657px 541px #fff, 936px 235px #fff,
    1928px 1675px #fff, 628px 1110px #fff, 904px 109px #fff, 1690px 1340px #fff,
    1156px 774px #fff, 1951px 1327px #fff, 642px 361px #fff, 138px 119px #fff,
    1444px 736px #fff, 729px 1927px #fff, 1996px 1107px #fff, 1045px 1229px #fff,
    1910px 1753px #fff, 1344px 1285px #fff, 686px 868px #fff, 24px 122px #fff,
    915px 1793px #fff, 945px 504px #fff, 371px 539px #fff, 151px 1897px #fff,
    1927px 195px #fff, 1289px 1179px #fff, 934px 1038px #fff, 1628px 1963px #fff,
    1614px 231px #fff, 1723px 1168px #fff, 1168px 1296px #fff, 1815px 525px #fff,
    1798px 572px #fff, 812px 131px #fff, 209px 1445px #fff, 482px 102px #fff,
    201px 1871px #fff, 883px 1318px #fff, 643px 1372px #fff, 405px 35px #fff,
    294px 1790px #fff, 190px 1482px #fff, 1017px 82px #fff, 1062px 1596px #fff,
    874px 1477px #fff, 1707px 94px #fff, 1825px 1814px #fff, 627px 812px #fff,
    405px 1055px #fff, 843px 1258px #fff, 1330px 560px #fff, 1323px 1433px #fff,
    362px 1736px #fff, 1934px 2000px #fff, 996px 1269px #fff, 1244px 1141px #fff,
    791px 1901px #fff, 1001px 1231px #fff, 295px 406px #fff, 1296px 1401px #fff,
    450px 1377px #fff, 103px 940px #fff, 1277px 1749px #fff, 57px 1435px #fff,
    535px 1135px #fff, 1067px 705px #fff, 1554px 498px #fff, 1383px 1162px #fff,
    1632px 1124px #fff, 1910px 310px #fff, 1502px 659px #fff, 1863px 98px #fff,
    295px 545px #fff, 1702px 1340px #fff, 1349px 1812px #fff, 1765px 782px #fff,
    1692px 1352px #fff, 1565px 900px #fff, 1114px 986px #fff, 664px 80px #fff,
    965px 1975px #fff, 418px 465px #fff, 1986px 840px #fff, 1800px 1653px #fff,
    1431px 41px #fff, 1167px 89px #fff, 351px 1700px #fff, 973px 945px #fff,
    330px 540px #fff, 334px 1799px #fff, 1104px 897px #fff, 1259px 805px #fff,
    240px 469px #fff, 1730px 1844px #fff, 1949px 1744px #fff, 915px 108px #fff,
    1964px 1932px #fff, 1641px 472px #fff, 1674px 429px #fff, 1131px 996px #fff,
    902px 478px #fff, 326px 726px #fff, 322px 706px #fff, 1769px 1046px #fff,
    605px 564px #fff, 1023px 1411px #fff, 1204px 615px #fff, 1883px 729px #fff,
    645px 362px #fff, 902px 3px #fff, 427px 1196px #fff, 491px 58px #fff,
    1670px 1812px #fff, 57px 526px #fff, 1709px 1587px #fff, 1133px 1255px #fff,
    1204px 489px #fff, 1735px 116px #fff, 133px 164px #fff, 1292px 1983px #fff,
    1777px 108px #fff, 1475px 92px #fff, 1721px 492px #fff, 994px 327px #fff,
    1727px 1757px #fff, 1148px 135px #fff, 1502px 1433px #fff, 1316px 603px #fff,
    1241px 1074px #fff, 6px 95px #fff, 246px 98px #fff, 1614px 1246px #fff,
    198px 349px #fff, 92px 1337px #fff, 1366px 926px #fff, 6px 1140px #fff,
    581px 418px #fff, 1852px 1097px #fff, 1372px 1432px #fff, 1771px 991px #fff,
    1975px 693px #fff, 450px 1616px #fff, 1724px 889px #fff, 334px 483px #fff,
    849px 1589px #fff, 1491px 170px #fff, 777px 1090px #fff, 893px 36px #fff,
    256px 668px #fff, 1083px 1304px #fff, 548px 1999px #fff, 1059px 913px #fff,
    1410px 406px #fff, 1010px 685px #fff, 1002px 135px #fff, 660px 1409px #fff,
    1174px 680px #fff, 219px 73px #fff, 1429px 226px #fff, 1758px 1277px #fff,
    1213px 1881px #fff, 1211px 785px #fff, 1584px 1787px #fff, 1590px 498px #fff,
    1928px 1382px #fff, 1571px 1989px #fff, 84px 85px #fff, 1831px 1021px #fff,
    1682px 683px #fff, 1603px 668px #fff, 870px 1175px #fff, 66px 481px #fff,
    371px 615px #fff, 1275px 569px #fff, 1506px 380px #fff, 523px 636px #fff,
    1007px 1323px #fff, 791px 1267px #fff, 1744px 1744px #fff, 664px 1100px #fff,
    947px 411px #fff, 530px 105px #fff, 913px 28px #fff, 1676px 1435px #fff,
    1495px 976px #fff, 754px 1428px #fff, 1336px 955px #fff, 699px 765px #fff,
    1784px 1866px #fff, 1059px 772px #fff, 896px 398px #fff, 131px 708px #fff,
    1707px 179px #fff, 346px 216px #fff, 1750px 1601px #fff, 593px 1167px #fff,
    1315px 738px #fff, 490px 1698px #fff, 347px 1035px #fff, 1706px 1889px #fff,
    552px 1856px #fff, 389px 1090px #fff, 1325px 296px #fff, 858px 1809px #fff,
    1831px 352px #fff, 1876px 1432px #fff, 1503px 1280px #fff,
    1827px 1106px #fff, 33px 422px #fff, 20px 1702px #fff, 1987px 539px #fff,
    691px 1276px #fff, 73px 643px #fff, 1378px 82px #fff, 1517px 1799px #fff,
    1028px 469px #fff, 1072px 687px #fff, 352px 281px #fff, 1871px 1067px #fff,
    666px 690px #fff, 75px 348px #fff, 1432px 70px #fff, 1895px 1402px #fff,
    1154px 860px #fff, 760px 154px #fff, 724px 417px #fff, 1180px 1815px #fff,
    101px 1007px #fff, 1470px 1313px #fff, 1153px 424px #fff, 953px 1647px #fff,
    285px 1364px #fff, 1040px 1202px #fff, 1391px 1049px #fff, 506px 1294px #fff,
    336px 1552px #fff, 501px 298px #fff, 1636px 334px #fff, 1156px 142px #fff,
    1316px 1342px #fff, 356px 1760px #fff, 1362px 202px #fff, 1376px 1474px #fff,
    27px 93px #fff, 71px 1045px #fff, 252px 543px #fff, 1908px 988px #fff,
    1078px 1026px #fff, 692px 1867px #fff, 1894px 1548px #fff, 496px 349px #fff,
    931px 1508px #fff, 270px 1341px #fff, 1248px 650px #fff, 645px 1510px #fff,
    750px 1867px #fff, 761px 187px #fff, 648px 767px #fff, 1561px 651px #fff,
    1417px 1718px #fff, 347px 1944px #fff, 1588px 461px #fff, 863px 1501px #fff,
    492px 451px #fff, 135px 981px #fff, 1448px 1573px #fff, 999px 1243px #fff,
    835px 352px #fff, 881px 1089px #fff, 1180px 617px #fff, 332px 961px #fff;
}

#stars2 {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 202px 6172px #fff, 1656px 759px #fff, 1575px 1229px #fff,
    1036px 1517px #fff, 1992px 623px #fff, 566px 1908px #fff, 585px 1286px #fff,
    541px 1827px #fff, 168px 828px #fff, 907px 1087px #fff, 1977px 832px #fff,
    1592px 17px #fff, 1188px 1754px #fff, 235px 322px #fff, 473px 471px #fff,
    1009px 1171px #fff, 1591px 127px #fff, 1109px 1610px #fff, 610px 1065px #fff,
    603px 1734px #fff, 878px 1674px #fff, 177px 483px #fff, 938px 1821px #fff,
    1544px 801px #fff, 1892px 909px #fff, 329px 281px #fff, 1798px 1973px #fff,
    1448px 1389px #fff, 246px 55px #fff, 298px 1946px #fff, 504px 669px #fff,
    251px 1155px #fff, 727px 1577px #fff, 89px 619px #fff, 547px 702px #fff,
    711px 857px #fff, 882px 1453px #fff, 227px 1397px #fff, 964px 1248px #fff,
    1360px 276px #fff, 1318px 450px #fff, 1788px 625px #fff, 507px 895px #fff,
    1679px 660px #fff, 178px 1298px #fff, 1681px 1054px #fff, 1966px 859px #fff,
    1264px 1887px #fff, 1221px 1541px #fff, 1338px 1771px #fff, 964px 547px #fff,
    1216px 1563px #fff, 493px 1610px #fff, 691px 1438px #fff, 76px 342px #fff,
    1722px 613px #fff, 1098px 1761px #fff, 1560px 1382px #fff, 486px 1251px #fff,
    612px 920px #fff, 1710px 1015px #fff, 9px 1044px #fff, 551px 300px #fff,
    1520px 529px #fff, 386px 1802px #fff, 1542px 926px #fff, 114px 309px #fff,
    1400px 1194px #fff, 96px 1707px #fff, 1870px 362px #fff, 344px 940px #fff,
    1269px 7px #fff, 1613px 377px #fff, 532px 604px #fff, 1398px 1912px #fff,
    1622px 775px #fff, 1737px 654px #fff, 770px 748px #fff, 1333px 1694px #fff,
    1107px 1649px #fff, 1237px 562px #fff, 1416px 631px #fff, 1117px 1914px #fff,
    1595px 1018px #fff, 278px 1632px #fff, 259px 1532px #fff, 140px 1218px #fff,
    483px 14px #fff, 531px 1446px #fff, 1701px 1668px #fff, 1118px 1822px #fff,
    686px 1885px #fff, 1277px 1628px #fff, 1094px 248px #fff, 307px 1983px #fff,
    90px 427px #fff, 1385px 647px #fff, 1261px 1814px #fff, 1786px 1036px #fff,
    673px 88px #fff, 757px 142px #fff, 555px 222px #fff, 1891px 1352px #fff,
    1223px 1497px #fff, 589px 1675px #fff, 1065px 519px #fff, 973px 792px #fff,
    153px 1525px #fff, 568px 373px #fff, 593px 192px #fff, 603px 1090px #fff,
    1705px 1224px #fff, 1901px 1519px #fff, 264px 544px #fff, 741px 1975px #fff,
    337px 866px #fff, 614px 1828px #fff, 1139px 183px #fff, 650px 242px #fff,
    1086px 1347px #fff, 1799px 1126px #fff, 747px 1282px #fff,
    1495px 1385px #fff, 674px 393px #fff, 738px 614px #fff, 1330px 978px #fff,
    984px 1166px #fff, 1732px 1496px #fff, 1630px 308px #fff, 323px 323px #fff,
    1222px 686px #fff, 285px 838px #fff, 1586px 880px #fff, 1570px 73px #fff,
    183px 1507px #fff, 458px 1925px #fff, 241px 1960px #fff, 1502px 1900px #fff,
    664px 575px #fff, 974px 706px #fff, 1752px 1440px #fff, 1100px 278px #fff,
    1314px 1544px #fff, 277px 1592px #fff, 1174px 1017px #fff, 727px 1704px #fff,
    1751px 1113px #fff, 995px 165px #fff, 287px 1672px #fff, 127px 1779px #fff,
    844px 1878px #fff, 1063px 954px #fff, 171px 352px #fff, 1135px 65px #fff,
    719px 277px #fff, 529px 1447px #fff, 1682px 270px #fff, 656px 691px #fff,
    1576px 297px #fff, 1000px 1232px #fff, 1139px 1067px #fff, 643px 451px #fff,
    364px 68px #fff, 432px 1847px #fff, 1803px 1727px #fff, 1771px 1343px #fff,
    630px 257px #fff, 460px 1721px #fff, 1660px 937px #fff, 1575px 1625px #fff,
    1110px 710px #fff, 159px 1610px #fff, 141px 920px #fff, 1780px 977px #fff,
    85px 1761px #fff, 678px 174px #fff, 1334px 606px #fff, 1101px 971px #fff,
    360px 1402px #fff, 1321px 659px #fff, 1987px 1783px #fff, 777px 740px #fff,
    755px 1148px #fff, 174px 440px #fff, 1917px 137px #fff, 1478px 1751px #fff,
    1117px 848px #fff, 992px 367px #fff, 1205px 576px #fff, 87px 18px #fff,
    355px 1068px #fff, 588px 1806px #fff, 1718px 701px #fff, 54px 1944px #fff,
    1513px 905px #fff, 276px 1685px #fff, 242px 1948px #fff, 1242px 1388px #fff,
    1461px 1120px #fff, 900px 931px #fff;
  animation: animStar 100s linear infinite;
}
#stars2:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 202px 6172px #fff, 1656px 759px #fff, 1575px 1229px #fff,
    1036px 1517px #fff, 1992px 623px #fff, 566px 1908px #fff, 585px 1286px #fff,
    541px 1827px #fff, 168px 828px #fff, 907px 1087px #fff, 1977px 832px #fff,
    1592px 17px #fff, 1188px 1754px #fff, 235px 322px #fff, 473px 471px #fff,
    1009px 1171px #fff, 1591px 127px #fff, 1109px 1610px #fff, 610px 1065px #fff,
    603px 1734px #fff, 878px 1674px #fff, 177px 483px #fff, 938px 1821px #fff,
    1544px 801px #fff, 1892px 909px #fff, 329px 281px #fff, 1798px 1973px #fff,
    1448px 1389px #fff, 246px 55px #fff, 298px 1946px #fff, 504px 669px #fff,
    251px 1155px #fff, 727px 1577px #fff, 89px 619px #fff, 547px 702px #fff,
    711px 857px #fff, 882px 1453px #fff, 227px 1397px #fff, 964px 1248px #fff,
    1360px 276px #fff, 1318px 450px #fff, 1788px 625px #fff, 507px 895px #fff,
    1679px 660px #fff, 178px 1298px #fff, 1681px 1054px #fff, 1966px 859px #fff,
    1264px 1887px #fff, 1221px 1541px #fff, 1338px 1771px #fff, 964px 547px #fff,
    1216px 1563px #fff, 493px 1610px #fff, 691px 1438px #fff, 76px 342px #fff,
    1722px 613px #fff, 1098px 1761px #fff, 1560px 1382px #fff, 486px 1251px #fff,
    612px 920px #fff, 1710px 1015px #fff, 9px 1044px #fff, 551px 300px #fff,
    1520px 529px #fff, 386px 1802px #fff, 1542px 926px #fff, 114px 309px #fff,
    1400px 1194px #fff, 96px 1707px #fff, 1870px 362px #fff, 344px 940px #fff,
    1269px 7px #fff, 1613px 377px #fff, 532px 604px #fff, 1398px 1912px #fff,
    1622px 775px #fff, 1737px 654px #fff, 770px 748px #fff, 1333px 1694px #fff,
    1107px 1649px #fff, 1237px 562px #fff, 1416px 631px #fff, 1117px 1914px #fff,
    1595px 1018px #fff, 278px 1632px #fff, 259px 1532px #fff, 140px 1218px #fff,
    483px 14px #fff, 531px 1446px #fff, 1701px 1668px #fff, 1118px 1822px #fff,
    686px 1885px #fff, 1277px 1628px #fff, 1094px 248px #fff, 307px 1983px #fff,
    90px 427px #fff, 1385px 647px #fff, 1261px 1814px #fff, 1786px 1036px #fff,
    673px 88px #fff, 757px 142px #fff, 555px 222px #fff, 1891px 1352px #fff,
    1223px 1497px #fff, 589px 1675px #fff, 1065px 519px #fff, 973px 792px #fff,
    153px 1525px #fff, 568px 373px #fff, 593px 192px #fff, 603px 1090px #fff,
    1705px 1224px #fff, 1901px 1519px #fff, 264px 544px #fff, 741px 1975px #fff,
    337px 866px #fff, 614px 1828px #fff, 1139px 183px #fff, 650px 242px #fff,
    1086px 1347px #fff, 1799px 1126px #fff, 747px 1282px #fff,
    1495px 1385px #fff, 674px 393px #fff, 738px 614px #fff, 1330px 978px #fff,
    984px 1166px #fff, 1732px 1496px #fff, 1630px 308px #fff, 323px 323px #fff,
    1222px 686px #fff, 285px 838px #fff, 1586px 880px #fff, 1570px 73px #fff,
    183px 1507px #fff, 458px 1925px #fff, 241px 1960px #fff, 1502px 1900px #fff,
    664px 575px #fff, 974px 706px #fff, 1752px 1440px #fff, 1100px 278px #fff,
    1314px 1544px #fff, 277px 1592px #fff, 1174px 1017px #fff, 727px 1704px #fff,
    1751px 1113px #fff, 995px 165px #fff, 287px 1672px #fff, 127px 1779px #fff,
    844px 1878px #fff, 1063px 954px #fff, 171px 352px #fff, 1135px 65px #fff,
    719px 277px #fff, 529px 1447px #fff, 1682px 270px #fff, 656px 691px #fff,
    1576px 297px #fff, 1000px 1232px #fff, 1139px 1067px #fff, 643px 451px #fff,
    364px 68px #fff, 432px 1847px #fff, 1803px 1727px #fff, 1771px 1343px #fff,
    630px 257px #fff, 460px 1721px #fff, 1660px 937px #fff, 1575px 1625px #fff,
    1110px 710px #fff, 159px 1610px #fff, 141px 920px #fff, 1780px 977px #fff,
    85px 1761px #fff, 678px 174px #fff, 1334px 606px #fff, 1101px 971px #fff,
    360px 1402px #fff, 1321px 659px #fff, 1987px 1783px #fff, 777px 740px #fff,
    755px 1148px #fff, 174px 440px #fff, 1917px 137px #fff, 1478px 1751px #fff,
    1117px 848px #fff, 992px 367px #fff, 1205px 576px #fff, 87px 18px #fff,
    355px 1068px #fff, 588px 1806px #fff, 1718px 701px #fff, 54px 1944px #fff,
    1513px 905px #fff, 276px 1685px #fff, 242px 1948px #fff, 1242px 1388px #fff,
    1461px 1120px #fff, 900px 931px #fff;
}

#stars3 {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 932px 5571px #fff, 855px 385px #fff, 502px 1638px #fff,
    1584px 60px #fff, 1952px 431px #fff, 1101px 1195px #fff, 642px 892px #fff,
    251px 111px #fff, 1987px 1018px #fff, 465px 1627px #fff, 1728px 1425px #fff,
    734px 959px #fff, 828px 1851px #fff, 1569px 801px #fff, 1589px 627px #fff,
    1330px 362px #fff, 1308px 279px #fff, 1393px 348px #fff, 1165px 760px #fff,
    162px 98px #fff, 222px 1708px #fff, 500px 1297px #fff, 940px 1470px #fff,
    1830px 445px #fff, 402px 156px #fff, 55px 1277px #fff, 1892px 1546px #fff,
    49px 398px #fff, 238px 1360px #fff, 1808px 928px #fff, 299px 905px #fff,
    1091px 95px #fff, 1173px 576px #fff, 1947px 1839px #fff, 1715px 866px #fff,
    735px 1766px #fff, 1879px 1394px #fff, 590px 1394px #fff, 1488px 1368px #fff,
    756px 1108px #fff, 1053px 1477px #fff, 921px 591px #fff, 1925px 1758px #fff,
    1810px 785px #fff, 653px 1378px #fff, 920px 498px #fff, 1163px 1729px #fff,
    881px 946px #fff, 1079px 1023px #fff, 1733px 1493px #fff, 925px 644px #fff,
    1414px 1967px #fff, 1620px 391px #fff, 699px 1779px #fff, 984px 1184px #fff,
    928px 1278px #fff, 1027px 967px #fff, 867px 276px #fff, 197px 1883px #fff,
    538px 1004px #fff, 1601px 1952px #fff, 1086px 780px #fff, 493px 1577px #fff,
    783px 43px #fff, 538px 1118px #fff, 189px 325px #fff, 622px 1817px #fff,
    299px 1412px #fff, 781px 1261px #fff, 333px 1064px #fff, 1183px 674px #fff,
    362px 1230px #fff, 458px 1881px #fff, 1777px 1360px #fff, 1857px 301px #fff,
    319px 1461px #fff, 1053px 90px #fff, 1029px 1622px #fff, 1694px 578px #fff,
    766px 1033px #fff, 57px 1842px #fff, 988px 828px #fff, 1160px 93px #fff,
    716px 1329px #fff, 1585px 1255px #fff, 1846px 44px #fff, 95px 661px #fff,
    1715px 889px #fff, 1185px 964px #fff, 875px 1901px #fff, 1815px 1052px #fff,
    1782px 963px #fff, 959px 1701px #fff, 581px 1635px #fff, 1107px 1453px #fff,
    135px 1828px #fff, 1826px 1959px #fff, 961px 1785px #fff, 1097px 362px #fff,
    1061px 1485px #fff;
  animation: animStar 150s linear infinite;
}
#stars3:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 932px 5571px #fff, 855px 385px #fff, 502px 1638px #fff,
    1584px 60px #fff, 1952px 431px #fff, 1101px 1195px #fff, 642px 892px #fff,
    251px 111px #fff, 1987px 1018px #fff, 465px 1627px #fff, 1728px 1425px #fff,
    734px 959px #fff, 828px 1851px #fff, 1569px 801px #fff, 1589px 627px #fff,
    1330px 362px #fff, 1308px 279px #fff, 1393px 348px #fff, 1165px 760px #fff,
    162px 98px #fff, 222px 1708px #fff, 500px 1297px #fff, 940px 1470px #fff,
    1830px 445px #fff, 402px 156px #fff, 55px 1277px #fff, 1892px 1546px #fff,
    49px 398px #fff, 238px 1360px #fff, 1808px 928px #fff, 299px 905px #fff,
    1091px 95px #fff, 1173px 576px #fff, 1947px 1839px #fff, 1715px 866px #fff,
    735px 1766px #fff, 1879px 1394px #fff, 590px 1394px #fff, 1488px 1368px #fff,
    756px 1108px #fff, 1053px 1477px #fff, 921px 591px #fff, 1925px 1758px #fff,
    1810px 785px #fff, 653px 1378px #fff, 920px 498px #fff, 1163px 1729px #fff,
    881px 946px #fff, 1079px 1023px #fff, 1733px 1493px #fff, 925px 644px #fff,
    1414px 1967px #fff, 1620px 391px #fff, 699px 1779px #fff, 984px 1184px #fff,
    928px 1278px #fff, 1027px 967px #fff, 867px 276px #fff, 197px 1883px #fff,
    538px 1004px #fff, 1601px 1952px #fff, 1086px 780px #fff, 493px 1577px #fff,
    783px 43px #fff, 538px 1118px #fff, 189px 325px #fff, 622px 1817px #fff,
    299px 1412px #fff, 781px 1261px #fff, 333px 1064px #fff, 1183px 674px #fff,
    362px 1230px #fff, 458px 1881px #fff, 1777px 1360px #fff, 1857px 301px #fff,
    319px 1461px #fff, 1053px 90px #fff, 1029px 1622px #fff, 1694px 578px #fff,
    766px 1033px #fff, 57px 1842px #fff, 988px 828px #fff, 1160px 93px #fff,
    716px 1329px #fff, 1585px 1255px #fff, 1846px 44px #fff, 95px 661px #fff,
    1715px 889px #fff, 1185px 964px #fff, 875px 1901px #fff, 1815px 1052px #fff,
    1782px 963px #fff, 959px 1701px #fff, 581px 1635px #fff, 1107px 1453px #fff,
    135px 1828px #fff, 1826px 1959px #fff, 961px 1785px #fff, 1097px 362px #fff,
    1061px 1485px #fff;
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
}
