* {
  margin: 0;
  padding: 0;
  font-family: "Omnes", sans-serif;

  /* font-family: "Proxima Nova", sans-serif !important; */
  text-decoration: none !important;
}

body {
  font-family: "Omnes", sans-serif;
}

hr {
  width: 100%;
}

html {
  scroll-behavior: smooth;
  transition: 2s ease-in;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

hr {
  color: #aaa6ba;
}

.dark hr {
  color: #7c7b81;
}

div::-webkit-scrollbar {
  display: none;
}
span::-webkit-scrollbar {
  display: none;
}
section::-webkit-scrollbar {
  display: none;
}

a {
  text-decoration: none !important;
  color: #22ad62;
}

li {
  list-style: none;
}

.dark {
  background: #212b25;
}

iframe {
  border: 0;
  position: relative !important;
  display: none;
}

.tradingview-widget-container {
  width: 100%;
  height: 550px;
  margin-right: 2em;
}

div#tradingview_12345 {
  height: 100%;
}

#tradingview_12345 div div iframe {
  display: block !important;
}

.btn-black.i {
  padding: 1em 2em;
  border: none;
  background: none;
  color: white;
}

.image-wrapper {
  position: relative;
  width: 150px;
  height: 150px;
  float: left;
  margin: 10px;
}

.image-wrapper button {
  position: absolute;
  border: none;
  background-color: rgb(218, 7, 7);
  color: rgb(99, 3, 3);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  line-height: 20px;
}

.heroBtn {
  outline: none;
  border: thin;
}

::selection {
  background: #238c4e;
  color: #fff;
}

.image-wrapper .image {
  width: 150px;
  height: 150px;
  position: absolute;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

button:focus {
  outline: unset !important;
}

.jss9 {
  justify-content: flex-end !important;
}

.jss8 {
  width: 100% !important;
}

.css-19kzrtu {
  padding: 0 !important;
  padding-top: 20px !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #22ad62 !important;
  background: #effff5 !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.css-1aquho2-MuiTabs-indicator {
  background: linear-gradient(to right, #84e4a3, #6cbb86, #5f7ad6) !important;
}

.custom_container {
  max-width: 1540px;
  margin: auto;
  width: 100%;
  padding: 0em 2em;
  min-width: 0px;
  height: 100%;
}

.col-md-8 {
  padding: 0 !important;
}

.uploadButton::-webkit-file-upload-button {
  background: linear-gradient(to right, #84e4a3, #6cbb86, #5f7ad6);
  color: #fff;
  border: none;
  padding: 0.5em 1em;
  border-radius: 6px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.uploadButton::-webkit-file-upload-button:hover {
  background-color: #e4a788;
  color: #fff;
  /* transform: translate3d(0, 5px, 0); */
  transition: 0.3s ease-in-out;
  box-shadow: 0px 4px 10px #d5d5d5;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 60% !important;
}

.MuiSelect-root {
  width: 60% !important;
}

@media (min-width: 768px) {
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 !important;
  }
}

.css-1kty9di-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #22ad62 !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #22ad62 !important;
}

/* 
.uploadButton::before {
  content: "Select some files";
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 600;
  font-size: 10pt;
}
.uploadButton:hover::before {
  border-color: black;
}
.uploadButton:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
} */

.for-des span {
  background: none !important;
  padding: 0.5em;
}

/* @import "~react-image-gallery/styles/css/image-gallery.css"; */

* {
  margin: 0;
  padding: 0;
  /* font-family: "Mulish", sans-serif; */
  /* font-family: "Proxima Nova", sans-serif !important; */
  text-decoration: none !important;
}

html {
  scroll-behavior: smooth;
  transition: 2s ease-in;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.custom_container {
  max-width: 1440px;
  margin: auto;
  width: 100%;
  padding: 0em 2em;
  min-width: 0px;
}

a {
  text-decoration: none !important;
  color: #22ad62;
}

li {
  list-style: none;
}

.connect {
  padding: 0 !important;
}

.btn-black.i {
  padding: 1em 2em;
  border: none;
  background: none;
  color: white;
}

.image-wrapper {
  position: relative;
  width: 150px;
  height: 150px;
  float: left;
  margin: 10px;
}

.image-wrapper button {
  position: absolute;
  border: none;
  background-color: rgb(218, 7, 7);
  color: rgb(99, 3, 3);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  line-height: 20px;
}

.heroBtn {
  outline: none;
  border: thin;
}

.image-wrapper .image {
  width: 150px;
  height: 150px;
  position: absolute;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

button:focus {
  outline: unset !important;
}

.jss9 {
  justify-content: flex-end !important;
}

.jss8 {
  width: 100% !important;
}

.css-19kzrtu {
  padding: 0 !important;
  padding-top: 20px !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #22ad62 !important;
  background: #effff5 !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.css-1aquho2-MuiTabs-indicator {
  background: linear-gradient(to right, #84e4a3, #6cbb86, #5f7ad6) !important;
}

.col-md-8 {
  padding: 0 !important;
}

.uploadButton::-webkit-file-upload-button {
  background: linear-gradient(to right, #84e4a3, #6cbb86, #5f7ad6);
  color: #fff;
  border: none;
  padding: 0.5em 1em;
  border-radius: 6px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.uploadButton::-webkit-file-upload-button:hover {
  background-color: #e4a788;
  color: #fff;
  /* transform: translate3d(0, 5px, 0); */
  transition: 0.3s ease-in-out;
  box-shadow: 0px 4px 10px #d5d5d5;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 60% !important;
}

.rdw-editor-main {
  border: 1px solid #f7e3e3 !important;
  border-radius: 5px !important;
  padding: 0 15px !important;
}

.MuiSelect-root {
  width: 60% !important;
}

@media screen and (max-width: 991px) {
}

@media (min-width: 768px) {
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 !important;
  }
}

.css-1kty9di-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #22ad62 !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #22ad62 !important;
}

.for-des span {
  background: none !important;
  padding: 0.5em;
}

html {
  scroll-behavior: smooth !important;
  transition: 2s ease-in;
}

input:focus-visible {
  outline: none;
  border: solid;
  border-color: #239e54;
  border-width: 1px;
  box-shadow: 0px 0px 1px 4px #222035a8;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

input:-internal-autofill-selected {
  background-color: #239e54 !important;
}

.featured_logos .react-multi-carousel-track {
  gap: 0px;
}

.eHUcDD {
  width: 64px;
  height: 64px;
  border-radius: 50% !important;
  box-shadow: rgb(36 36 36 / 10%) 0px 8px 16px 0px,
    rgb(36 36 36 / 4%) 0px 1px 4px 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background-color: rgb(23, 73, 77) !important;
}

.text-left .react-date-picker {
  display: unset !important;
}

.ReactTags__tag {
  border: 1px solid #ddd !important;
  background: #b3f2cc !important;
  color: #000;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 0 5px;
  border-radius: 2px;
}

.ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
  color: #000;
  margin-left: 4px;
}

.ReactTags__clearAll {
  cursor: pointer;
  padding: 10px;
  margin: 10px;
  background: #f88d8d;
  color: #fff;
  border: none;
}

.ReactTags__tagInputField {
  border-color: rgb(200 231 212);
  border-style: solid;
  border-width: 1px;
  padding: 12px;
  border-radius: 4px;
}

.laSVjx.laSVjx.laSVjx {
  position: relative;
  overflow: hidden;
  border-radius: 50% !important;
  width: 100%;
  height: 100%;
  background-color: #093f1f;
}

@media screen and (max-width: 1055px) {
  .alert_cont {
    position: fixed;
    top: 1%;
    left: 37.5%;
    right: 62%;
    width: 40%;
    z-index: 10000;
    animation: opacity 0.3s linear;
  }
}

@media screen and (max-width: 767px) {
  .alert_cont {
    position: fixed;
    top: 1%;
    left: 37.5%;
    right: 62%;
    width: 60%;
    z-index: 10000;
    animation: opacity 0.3s linear;
  }
}

@media screen and (max-width: 700px) {
  .featured_logos .react-multi-carousel-track {
    gap: 0px;
  }
}

@media screen and (max-width: 590px) {
  .featured_logos .react-multi-carousel-track {
    gap: 0px;
  }

  .alert_cont {
    position: fixed;
    top: 1%;
    left: 8%;
    right: 0%;
    width: 90%;
    z-index: 10000;
    margin: auto;
    animation: opacity 0.3s linear;
  }
}

@media screen and (max-width: 550px) {
  .custom_container {
    max-width: 1440px;
    margin: auto;
    width: 100%;
    padding: 0em 1em;
    min-width: 0px;
  }
}

.chart_svg {
  stroke: #238c4e;
  fill: #edfaf2;
  stop-color: green;
  stroke-width: 0.5px;
  /* width: 100%; */
  stroke-linecap: round;
  stroke-linejoin: round;
}

.sparkline--cursor {
  /* stroke: orange; */
  stroke-width: 0.5;
}

.sparkline--spot {
  /* fill: inherit; */
  /* stroke: red; */
  stroke-width: 0.5px;
}

rect.sparkline--interaction-layer {
  height: 100%;
  width: 100%;
}

.recharts-tooltip-wrapper {
  border-radius: 7px;
}

.recharts-default-tooltip {
  border-radius: 7px;
}

.dark .recharts-default-tooltip {
  background: #121213 !important;
  color: #fff !important;
  border: solid 1px #85a393 !important;
}

@media screen and (max-width: 767px) {
  .custom_container {
    padding: 0em 1em;
  }
}

@media screen and (max-width: 550px) {
  .custom_container {
    max-width: 1440px;
    margin: auto;
    width: 100%;
    padding: 0em 1em;
    min-width: 0px;
    height: 100%;
  }
}

.Modal2_div {
  position: fixed;
  top: 0%;
  width: 100svw;
  height: 100svh;
  background: #000000ab;
  left: 0;
  overflow: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-direction: column; */
  z-index: 1000;
  padding: 1em;

  backdrop-filter: blur(6px);
}

.Modal2_div_area {
  background: #fff;
  padding: 2em;
  width: 100%;
  max-width: 30em;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0px 0px 16px 0px #060606c7;
  animation: fadeShow 0.3s linear;
  position: relative;
  z-index: 1;
}

.dark .Modal2_div_area {
  background: #232326;
}

@keyframes fadeShow {
  0% {
    transform: scale(1.5);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.Modal2_div1_head {
  font-size: 24px;
  font-weight: 500;
  color: #000;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.dark .Modal2_div1_head {
  color: #fff;
}

.Modal2_div2 {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  justify-content: flex-start;
  grid-gap: 20px;
  gap: 10px;
  margin-top: 10px;
}

.Modal2_div2_div1_area {
  width: 100%;
}

.Modal2_div2_div1_area_btn {
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: #e8e8e8;
  border: solid 1px #d0d0d0;
  flex-direction: row-reverse;
}

.Modal2_div2_div1_area_btn:disabled {
  cursor: not-allowed;
  opacity: 0.3;
  /* filter: grayscale(1); */
}

.dark .Modal2_div2_div1_area_btn {
  background: #39393c;
  border: solid 1px #4f4f55;
  color: #fff;
}

.Modal2_div2_div1_area_btn_area {
  display: flex;
  align-items: center;
}

.Modal2_div2_div1_area_btn_area_txt {
  margin-left: 4px;
}

.Modal2_div_area_span {
  margin-top: 1em;
  font-size: 12px;
  color: #747474;
  font-weight: 400;
}

.dark .Modal2_div_area_span {
  color: #85a393;
}

.Modal2_div1_head_icon {
  font-size: 33px !important;
  cursor: pointer;
}

.Modal2_div_closeDiv {
  position: absolute;
  width: 100%;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: none;
}
