.UpdatedSuccessModalDiv {
  position: fixed;
  top: 0;
  left: 0;
  width: 100svw;
  height: 100svh;
  background: #00000029;
  z-index: 100000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  backdrop-filter: blur(10px);
  overflow: scroll;
}

.UpdatedSuccessModalDiv_Cont {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2em;
  width: 100%;
  max-width: 450px;
  border-radius: 15px;
  border: solid 1px #bab9c8;
  box-shadow: 0px 0px 18px 2px #5c5c5c;
  color: #000;
  animation: fadeShow2 0.3s linear;
}

.dark .UpdatedSuccessModalDiv_Cont {
  background: #232326;
  border: solid 1px #313135;
  box-shadow: 0px 0px 18px 2px #000;
  color: #fff;
}

@keyframes fadeShow2 {
  0% {
    transform: scale(1.5);
    opacity: 0.5;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.UpdatedSuccessModalDiv_Cont_img_cont {
  width: 300px;
  margin-bottom: 2em;
}

.UpdatedSuccessModalDiv_Cont_img {
  width: 100%;
}

.UpdatedSuccessModalDiv_Cont_title {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 5px;
}

.UpdatedSuccessModalDiv_Cont_buttons {
  width: 100%;
  margin-top: 1em;
  display: flex;
  align-items: center;
}

.UpdatedSuccessModalDiv_Cont_btn {
  width: 100%;
  padding: 15px;
  border-radius: 16px;
  border: none;
  background: #3f8f4b;
  color: #fff;
  box-shadow: 0px 10px 10px #0000006b;
}

.UpdatedSuccessModalDiv_Cont_btn_warning {
  width: 100%;
  padding: 15px;
  border-radius: 16px;
  border: none;
  background: #e6bf51;
  color: #000;
  box-shadow: 0px 10px 10px #0000006b;
  margin: 0px 10px;
}

.UpdatedSuccessModalDiv_Cont_body {
  width: 100%;
  color: #9991b2;
}

/* .dark .UpdatedSuccessModalDiv_Cont_body {
  width: 100%;
  color: #b4cab7;
} */
.UpdatedErrorModalDiv_Cont_btn {
  width: 100%;
  padding: 15px;
  border-radius: 16px;
  border: none;
  background: #cf4d32;
  color: #fff;
  box-shadow: 0px 10px 10px #0000006b;
}

.UpdatedSuccessModalDiv_Cont_body_txnHash {
  border: solid 1px #89a794;
  padding: 10px 0px;
  width: 155px;
  font-size: 12px;
  border-radius: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}

.UpdatedSuccessModalDiv_Cont_body_txnHash_icon {
  font-size: 18px !important;
}
