/* mobile view code start */
/* first break point ========================================= */
@media screen and (max-width: 1245px) {
  /* footer  section start */
  .footerCard1,
  .footerCard2,
  .footerCard3,
  .footerCard4 {
    padding: 0 !important;
  }

  .footerCardTitle {
    font-size: 14px;
  }
  .c1link1 {
    font-size: 12px;
  }
  .c1linklast {
    font-size: 12px;
  }

  /* footer  section end */
}

@media screen and (max-width: 869px) {
  /* footer section start */

  .footerArea {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    align-items: center;
  }
  .footerCard2 {
    display: none;
  }
  .footerCard3 {
    display: block !important;
    width: 100%;
  }
  .footerLinks {
    justify-items: self-start;
    text-decoration: underline;
  }
  .FooterPageLinks {
    display: none;
  }
  .footerCard1 {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: row;
    margin-bottom: 23px;
  }
  .egr2-logo {
    width: 135px;
  }
  .egr2-logo2 {
    width: 135px;
  }
  /* .footerIcons {
    margin-top: 20px;
} */

  /* footer section end */
}
@media screen and (max-width: 645px) {
  /* footer section start */
  .footerSection {
    padding: 1em 0em !important;
  }

  .twitter_icon {
    font-size: 48px !important;
  }
  /* footer section end */
}
